<template>
  <div class="toAudit">
    <div class="toAudit-search">
      <div class="toAudit-search-1">
        签名:
        <a-input v-model="sign" placeholder="请输入签名" allowClear />
      </div>
      <div class="date-time">
        创建时间:
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          v-model="startTime"
          placeholder="开始日期"
          @openChange="handleGmtCreatedStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="endTime"
          :open="gmtEndOpen"
          @openChange="handleGmtCreatedEndOpenChange"
        />
      </div>
    </div>
    <div class="toAudit-search">
      <div class="toAudit-search-1">
        状态:
        <a-select
          v-model="status"
          placeholder="状态"
          class="select-tag"
          :filterOption="false"
          allowClear
        >
          <a-select-option
            v-for="i in statusList"
            :key="i.key"
            :value="i.key"
            >{{ i.value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="toAudit-search-1">
        运营商：
        <a-select
          v-model="phoneOperator"
          placeholder="请选择运营商"
          class="select-tag"
          allowClear
        >
          <a-select-option
            v-for="i in phoneOperatorList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="toAudit-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
    </div>
    <!-- 待审核列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(auditColumns)"
      :columns="auditColumns"
      :dataSource="auditTableData"
      :pagination="auditPagination"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
      }"
    >
      <span slot="channelSmsAccountName" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ text }}({{ record.channelSmsAccountAccessKey }})
          </template>
          {{ text }}({{ record.channelSmsAccountAccessKey }})
        </a-tooltip>
      </span>
      <span slot="example" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span v-html="text"> </span>
          </template>
          <span v-html="text"> </span>
        </a-tooltip>
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="switchOnChange(record)"
        />
      </span>
      <span slot="status" slot-scope="text" style="width: 100%">
        <a-tag v-if="text === 'AUTO_PASSED'" color="green"> 自动通过 </a-tag>
        <a-tag v-if="text === 'AUTO_FAILURE'" color="red"> 自动失败 </a-tag>
        <a-tag v-if="text === 'AUTO_BLOCKED'" color="orange"> 待审核 </a-tag>
        <a-tag v-if="text === 'REBALANCING'" color="green"> 分配路由中 </a-tag>
      </span>
      <span slot="count" slot-scope="text, record">
        <a href="javascript:;" @click="numClick(record)">{{ text }}</a>
      </span>
      <span slot="dynamicReplaceConfigName" slot-scope="text, record">
        <template v-if="record.dynamicReplaceConfigId !== -1 && text">
          {{ text }}
        </template>
        <template v-else> ---- </template>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-button
          type="link"
          @click="examine(record)"
          :disabled="record.status == 'REBALANCING'"
          style="padding: 0"
          >审核</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          title="确认要执行重新路由操作吗?"
          okText="确定"
          cancelText="取消"
          @confirm="rebalance(record)"
        >
          <a-button
            type="link"
            :disabled="record.status == 'REBALANCING'"
            style="padding: 0"
            >重新路由</a-button
          >
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-button
          type="link"
          @click="deleteClick(record)"
          :style="{
            padding: 0,
            color: record.status == 'REBALANCING' ? 'grey' : 'red',
          }"
          :disabled="record.status == 'REBALANCING'"
          >删除</a-button
        >
      </span>
    </a-table>
    <a-button
      type="primary"
      @click="examine()"
      :disabled="selectedRowKeys.length == 0"
      >批量审核</a-button
    >
    <!-- 条数 -->
    <a-modal
      title="条数"
      v-model="numShow"
      :maskClosable="false"
      class="actionSub-class"
      width="1200px"
    >
      <div style="margin: 10px 0">
        客户编号:
        <a-input
          v-model="customerCode"
          placeholder="请输入客户编号"
          allowClear
          @keyup.enter="getMsgAuditList()"
          style="width: 240px; margin-right: 10px"
        />
        <a-button type="primary" @click="getMsgAuditList()">查询</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(numColumns)"
        :columns="numColumns"
        :dataSource="numTableData"
        :pagination="numPagination"
        :loading="loadingNumTable"
        size="small"
      >
        <span slot="content" slot-scope="text">
          <a-tooltip placement="topLeft">
            <template slot="title">
              {{ text }}
            </template>
            {{ text }}
          </a-tooltip>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="numShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 审核 -->
    <a-modal
      :title="auditTitle"
      v-model="auditShow"
      :maskClosable="false"
      class="actionSub-class"
      width="1000px"
    >
      <a-form v-if="batchExamine">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="状态"
        >
          <a-radio-group v-model="statusModal" :options="options" />
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
          <span slot="label"
            >发送条数限制
            <a-tooltip placement="topLeft">
              <template slot="title">
                发送达到指定条数，自动变更为待审核状态
              </template>
              <a-icon type="question-circle" style="color: #3d91f7" />
            </a-tooltip>
          </span>
          <a-switch v-model="countShow" />
          <i style="display: inline-block; margin-right: 20px"></i>
          <a-input
            v-model="count"
            v-if="countShow"
            placeholder="请输入条数限制"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="延迟审核"
        >
          <a-checkbox @change="checkChange" v-model="checkbox"> </a-checkbox>
          <a-date-picker
            v-if="checkbox"
            v-model="effectTime"
            style="margin-left: 10px"
            show-time
            placeholder="请选择时间"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
          <span slot="label">
            速度控制
            <a-tooltip placement="topLeft">
              <template slot="title">
                控制提交审核的速度,例如:5秒提交8条数据
              </template>
              <a-icon type="question-circle" style="color: #3d91f7" />
            </a-tooltip>
          </span>
          <a-switch v-model="speedControl" />
          <template v-if="speedControl">
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-input v-model="speedSecond" style="width: 60px" />
            <span> 秒</span>
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-input v-model="speedCount" style="width: 60px" />
            <span> 条</span>
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-button
              type="link"
              style="padding: 0"
              :disabled="!(speedCount && speedSecond)"
              v-if="
                !(
                  watchSpeedSecond == speedSecond &&
                  watchSpeedCount == speedCount
                )
              "
              @click="batchControlSpeed"
              >更新速度</a-button
            >
          </template>
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
          <span slot="label"
            >自动删除
            <a-tooltip placement="topLeft">
              <template slot="title"> 数据发完后自动删除该审核模板 </template>
              <a-icon type="question-circle" style="color: #3d91f7" />
            </a-tooltip>
          </span>
          <a-checkbox v-model="deleteCheck"> </a-checkbox>
        </a-form-item>
      </a-form>
      <a-tabs v-model="activeKey" v-else>
        <a-tab-pane key="1" tab="状态审核">
          <a-form>
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 15 }"
              label="状态"
            >
              <a-radio-group v-model="statusModal" :options="options" />
            </a-form-item>
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
              <span slot="label"
                >发送条数限制
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    发送达到指定条数，自动变更为待审核状态
                  </template>
                  <a-icon type="question-circle" style="color: #3d91f7" />
                </a-tooltip>
              </span>
              <a-switch v-model="countShow" />
              <i style="display: inline-block; margin-right: 20px"></i>
              <a-input
                v-model="count"
                v-if="countShow"
                placeholder="请输入条数限制"
                style="width: 200px"
              />
            </a-form-item>
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 15 }"
              label="延迟审核"
            >
              <a-checkbox @change="checkChange" v-model="checkbox">
              </a-checkbox>
              <a-date-picker
                v-if="checkbox"
                v-model="effectTime"
                style="margin-left: 10px"
                show-time
                placeholder="请选择时间"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-item>
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
              <span slot="label">
                速度控制
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    控制提交审核的速度,例如:5秒提交8条数据
                  </template>
                  <a-icon type="question-circle" style="color: #3d91f7" />
                </a-tooltip>
              </span>
              <a-switch v-model="speedControl" />
              <template v-if="speedControl">
                <i style="display: inline-block; margin-right: 20px"></i>
                <a-input v-model="speedSecond" style="width: 60px" />
                <span> 秒</span>
                <i style="display: inline-block; margin-right: 20px"></i>
                <a-input v-model="speedCount" style="width: 60px" />
                <span> 条</span>
                <i style="display: inline-block; margin-right: 20px"></i>
                <a-button
                  type="link"
                  style="padding: 0"
                  :disabled="!(speedCount && speedSecond)"
                  v-if="
                    !(
                      watchSpeedSecond == speedSecond &&
                      watchSpeedCount == speedCount
                    )
                  "
                  @click="controlSpeed"
                  >更新速度</a-button
                >
              </template>
            </a-form-item>
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
              <span slot="label"
                >自动删除
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    数据发完后自动删除该审核模板
                  </template>
                  <a-icon type="question-circle" style="color: #3d91f7" />
                </a-tooltip>
              </span>
              <a-checkbox v-model="deleteCheck"> </a-checkbox>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" tab="审核日志" force-render>
          <a-tabs @change="callback" v-model="activeKey1">
            <a-tab-pane key="1" tab="未执行">
              <a-table
                bordered
                :rowClassName="$common.rowClassColor"
                :components="$common.getTitle(tableColumns)"
                :columns="tableColumns"
                :dataSource="tableData"
                :pagination="tablePagination"
                :loading="loadingTable"
                size="small"
              >
                <span slot="status" slot-scope="text" style="width: 100%">
                  <a-tag v-if="text === 'AUTO_PASSED'" color="green">
                    自动通过
                  </a-tag>
                  <a-tag v-if="text === 'AUTO_FAILURE'" color="red">
                    自动失败
                  </a-tag>
                  <a-tag v-if="text === 'AUTO_BLOCKED'" color="orange">
                    待审核
                  </a-tag>
                </span>
                <span slot="expired" slot-scope="text">
                  <a-tag v-if="text" color="green"> 已执行 </a-tag>
                  <a-tag v-if="!text" color="orange"> 待执行 </a-tag>
                </span>
                <span
                  slot="action"
                  slot-scope="text, record"
                  style="width: 100%"
                >
                  <a
                    href="javascript:;"
                    @click="deleteTableClick(record)"
                    style="color: red"
                    >删除</a
                  >
                </span>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="已执行">
              <a-table
                bordered
                :rowClassName="$common.rowClassColor"
                :components="$common.getTitle(tableColumns)"
                :columns="tableColumns"
                :dataSource="tableData"
                :pagination="tablePagination"
                :loading="loadingTable"
                size="small"
              >
                <span slot="status" slot-scope="text" style="width: 100%">
                  <a-tag v-if="text === 'AUTO_PASSED'" color="green">
                    自动通过
                  </a-tag>
                  <a-tag v-if="text === 'AUTO_FAILURE'" color="red">
                    自动失败
                  </a-tag>
                  <a-tag v-if="text === 'AUTO_BLOCKED'" color="orange">
                    待审核
                  </a-tag>
                </span>
                <span slot="expired" slot-scope="text">
                  <a-tag v-if="text" color="green"> 已执行 </a-tag>
                  <a-tag v-if="!text" color="orange"> 待执行 </a-tag>
                </span>
                <span
                  slot="action"
                  slot-scope="text, record"
                  style="width: 100%"
                >
                  <a
                    href="javascript:;"
                    @click="deleteTableClick(record)"
                    style="color: red"
                    >删除</a
                  >
                </span>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
      </a-tabs>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="auditShow = false">关闭</a-button>
          <i style="display: inline-block; margin-right: 20px"></i>
          <a-button
            type="primary"
            @click="batch_table_submit"
            v-if="batchExamine"
            >确定</a-button
          >
          <a-button type="primary" @click="table_submit(false)" v-else
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "toAudit",
  data() {
    return {
      sign: "",
      auditColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "日期",
          dataIndex: "date",
          ellipsis: true,
          width: 80,
        },
        {
          title: "accessKey",
          dataIndex: "accessKey",
          ellipsis: true,
          width: 85,
        },
        {
          title: "用户名称",
          dataIndex: "userSmsAccountLoginName",
          ellipsis: true,
          width: 80,
        },
        {
          title: "签名",
          dataIndex: "sign",
          ellipsis: true,
          width: 80,
        },
        {
          title: "运营商",
          dataIndex: "phoneOperator",
          ellipsis: true,
          width: 75,
        },
        {
          title: "通道",
          dataIndex: "channelSmsAccountName",
          scopedSlots: { customRender: "channelSmsAccountName" },
          ellipsis: true,
          width: 225,
        },
        {
          title: "内容",
          dataIndex: "example",
          scopedSlots: { customRender: "example" },
          ellipsis: true,
          width: 240,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          ellipsis: true,
          width: 80,
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          ellipsis: true,
          width: 90,
        },
        {
          title: "条数",
          dataIndex: "count",
          scopedSlots: { customRender: "count" },
          ellipsis: true,
          width: 80,
        },
        {
          title: "动态替换",
          dataIndex: "dynamicReplaceConfigName",
          ellipsis: true,
          scopedSlots: { customRender: "dynamicReplaceConfigName" },
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 180,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      auditTableData: [],
      auditPagination: {},
      tablePagination: {},
      loadingTable: false,
      id: "",
      numShow: false,
      numColumns: [
        {
          title: "客户编号",
          dataIndex: "customerCode",
        },
        {
          title: "内容",
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "运营商",
          dataIndex: "phoneOperator",
        },
        {
          title: "区域",
          dataIndex: "province",
        },
        {
          title: "提交时间",
          dataIndex: "submitTime",
        },
      ],
      numTableData: [],
      numPagination: {},
      loadingNumTable: false,
      auditTemplateId: "",
      numPage: 1,
      gmtEndOpen: false,
      startTime: "",
      endTime: "",
      status: undefined,
      statusList: [
        { key: "AUTO_PASSED", value: "自动通过" },
        { key: "AUTO_FAILURE", value: "自动失败" },
        { key: "AUTO_BLOCKED", value: "待审核" },
        { key: "REBALANCING", value: "分配路由中" },
      ],
      phoneOperator: undefined,
      phoneOperatorList: [
        {
          value: "中国移动",
          label: "中国移动",
        },
        {
          value: "中国联通",
          label: "中国联通",
        },
        {
          value: "中国电信",
          label: "中国电信",
        },
      ],
      options: [
        { label: "待审核", value: "AUTO_BLOCKED" },
        { label: "自动通过", value: "AUTO_PASSED" },
        { label: "自动失败", value: "AUTO_FAILURE" },
      ],
      auditShow: false,
      activeKey: "1",
      activeKey1: "1",
      auditTitle: "",
      statusModal: "AUTO_BLOCKED",
      effectTime: null,
      tablePage: 1,
      currentPage: 1,
      pageSize: 10,
      checkbox: false,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "审核状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "执行时间",
          dataIndex: "effectTime",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "执行状态",
          dataIndex: "expired",
          scopedSlots: { customRender: "expired" },
        },
        {
          title: "操作人",
          dataIndex: "operator",
        },
        {
          title: "操作",
          key: "action",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      expired: false,
      timer: null,
      countShow: false,
      count: 100,
      deleteCheck: true,
      customerCode: "",
      speedControl: false,
      speedSecond: "",
      speedCount: "",
      watchSpeedSecond: "",
      watchSpeedCount: "",
      selectedRowKeys: [],
      batchExamine: false,
    };
  },
  mounted() {
    this.inquire(1);
    this.timer = setInterval(() => {
      this.inquire();
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭定时器
    }
  },
  methods: {
    moment, // 引用moment方法
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        sign: this.sign,
        gmtCreatedStart: this.startTime,
        gmtCreatedEnd: this.endTime,
        status: this.status,
        phoneOperator: this.phoneOperator,
      };
      if (data.gmtCreatedStart) {
        data.gmtCreatedStart = data.gmtCreatedStart + " 00:00:00";
      }
      if (data.gmtCreatedEnd) {
        data.gmtCreatedEnd = data.gmtCreatedEnd + " 23:59:59";
      }

      this.loadingTable = true;
      this.$http
        .get("/auditTemplate/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.auditTableData = list;
            this.auditPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.inquire(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    rebalance(val) {
      this.$http
        .json_post("/auditTemplate/rebalance", { id: val.id })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("操作成功");
            this.inquire(1);
          }
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 审核
    examine(val) {
      this.activeKey = "1";
      this.activeKey1 = "1";
      this.effectTime = null;
      this.checkbox = false;
      this.expired = false;

      if (val) {
        this.id = val.id;
        this.auditTitle = "审核 - " + this.id;
        this.statusModal = val.status;
        this.speedControl = val.speedControl;
        this.speedSecond = val.speedSecond;
        this.speedCount = val.speedCount;
        this.watchSpeedSecond = val.speedSecond;
        this.watchSpeedCount = val.speedCount;
        this.deleteCheck = val.autoDelete;
        if (val.sendCountLimit !== -1) {
          this.countShow = true;
          this.count = val.sendCountLimit;
        }
        this.getTableList(1);
        this.batchExamine = false;
      } else {
        this.auditTitle = "批量审核";
        this.statusModal = "AUTO_BLOCKED";
        this.speedControl = false;
        this.speedSecond = "";
        this.speedCount = "";
        this.deleteCheck = true;
        this.watchSpeedSecond = "";
        this.watchSpeedCount = "";
        this.countShow = false;
        this.count = "";
        this.batchExamine = true;
      }
      this.auditShow = true;
    },
    checkChange() {
      if (this.checkbox === false) {
        this.effectTime = null;
      }
    },
    batchControlSpeed() {
      this.selectedRowKeys.forEach((item) => {
        this.controlSpeed(item);
      });
      this.$message.success("操作成功");
      this.watchSpeedSecond = this.speedSecond;
      this.watchSpeedCount = this.speedCount;
    },
    controlSpeed(id) {
      let data = {
        id: this.id,
        speedControl: this.speedControl,
        speedSecond: this.speedSecond,
        speedCount: this.speedCount,
      };
      if (id) {
        data.id = id;
      }
      this.$http.json_post("/auditTemplate/changeSpeed", data).then((res) => {
        if (res.result === 200) {
          if (!this.batchExamine) {
            this.$message.success("操作成功");
            this.watchSpeedSecond = this.speedSecond;
            this.watchSpeedCount = this.speedCount;
          }
        }
      });
    },
    batch_table_submit() {
      this.selectedRowKeys.forEach((item) => {
        this.table_submit(false, item);
      });
      this.$message.success("操作成功");
      this.selectedRowKeys.splice(0);
      this.auditShow = false;
    },
    // 前往表格
    table_submit(ignoreRepeatCheck, id) {
      let data = {
        id: this.id,
        status: this.statusModal,
        effectTime: this.effectTime,
        autoDelete: this.deleteCheck,
        ignoreRepeatCheck,
        speedControl: this.speedControl,
        speedSecond: this.speedSecond,
        speedCount: this.speedCount,
      };
      if (id) {
        data.id = id;
      }
      if (this.countShow) {
        data.sendCountLimit = this.count;
      }
      this.$http.json_post("/auditTemplate/changeStatus", data).then((res) => {
        if (res.result === 200) {
          if (!this.batchExamine) {
            if (!this.checkbox) {
              this.$message.success("操作成功");
              this.auditShow = false;
              this.inquire(1);
            } else {
              this.activeKey = "2";
              this.getTableList(1);
            }
          }
        } else if (res.result === 402) {
          this.$confirm({
            title: res.message,
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.table_submit(true, id);
            },
            onCancel() {},
          });
        }
      });
    },
    // 获取审核弹框表格
    getTableList(index) {
      if (index) {
        this.tablePage = index;
      }
      let data = {
        pageNo: this.tablePage,
        pageSize: 10,
        id: this.id,
        expired: this.expired,
      };
      this.$http.get("/auditTemplate/changeLog/page", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.tableChangePage(current),
          };
        }
      });
    },
    tableChangePage(index) {
      this.getTableList(index);
    },
    deleteTableClick(val) {
      this.$confirm({
        title: "确认删除吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .get("/auditTemplate/changeLog/delete", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.getTableList(1);
              }
            });
        },
        onCancel() {},
      });
    },
    // tabs切换
    callback(key) {
      if (key == 1) {
        this.expired = false;
      } else {
        this.expired = true;
      }
      this.getTableList(1);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/auditTemplate/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
    // 点击条数
    numClick(val) {
      this.numShow = true;
      this.auditTemplateId = val.id;
      this.customerCode = "";
      this.getMsgAuditList();
    },
    getMsgAuditList(index) {
      if (index) {
        this.numPage = index;
      }
      let data = {
        pageNo: this.numPage,
        pageSize: 10,
        auditTemplateId: this.auditTemplateId,
        customerCode: this.customerCode,
      };
      this.loadingNumTable = true;
      this.$http
        .get("/msgAuditDetail/list", data)
        .then((res) => {
          this.loadingNumTable = false;
          if (res.result === 200) {
            this.numTableData = res.data.records;
            this.numPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.numChangePage(current),
            };
          }
        })
        .catch(() => {
          this.loadingNumTable = false;
        });
    },
    numChangePage(index) {
      this.getMsgAuditList(index);
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChange(open) {
      if (!open) {
        this.gmtEndOpen = true;
      }
    },
    handleGmtCreatedEndOpenChange(open) {
      this.gmtEndOpen = open;
    },
    // 启用状态
    switchOnChange(record) {
      let data = {
        id: record.id,
        enabled: !record.enabled,
      };
      this.$http
        .json_post("/auditTemplate/switchEnableStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.inquire();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.toAudit {
  .toAudit-search {
    position: relative;
    margin-bottom: 20px;
    .date-time {
      display: inline-block;
    }

    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .toAudit-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .toAudit-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
