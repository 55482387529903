<template>
  <div class="messagePush">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        登录名称:
        <a-input
          v-model="userLoginNameSearch"
          placeholder="请输入登录名称"
          allowClear
        />
      </div>
      <div class="smsStatusInfo-search-1">
        表名:
        <a-select
          v-model="tableNum"
          placeholder="请选择表名"
          class="select-tag"
        >
          <a-select-option
            v-for="i in tableNumList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="smsStatusInfo-search-1">
        推送状态:
        <a-select
          v-model="sended"
          placeholder="请选择推送状态"
          class="select-tag"
        >
          <a-select-option
            v-for="(i, index) in sendedList"
            :key="index"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="smsStatusInfo-search-1">
        批次编号:
        <a-input v-model="planIdAdd" placeholder="请输入批次编号" allowClear />
      </div>
    </div>
    <div class="smsStatusInfo-search">
      <div class="date-time">
        发送时间:
        <a-date-picker
          :disabledDate="disabledGmtCreatedStartDate"
          format="YYYY-MM-DD"
          v-model="startTime"
          placeholder="开始日期"
          @openChange="handleGmtCreatedStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledGmtCreatedEndDate"
          format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="endTime"
          :open="gmtEndOpen"
          @openChange="handleGmtCreatedEndOpenChange"
        />
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="batchPush">批量推送</a-button>
      <a v-if="pushLength != 0" href="javascript:;" @click="pushClick"
        >当前重推任务数：{{ pushLength }}</a
      >
    </div>
    <!-- 用户列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(userColumns)"
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="sended" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 已推送 </a-tag>
        <a-tag v-else color="red"> 未推送 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          placement="right"
          okText="确定"
          cancelText="取消"
          @confirm="aginPush(record)"
          :disabled="!record.sended"
        >
          <template slot="title">确定要重新推送吗</template>
          <a href="javascript:;" :disabled="!record.sended">重新推送</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 批量推送 -->
    <a-modal
      title="批量推送"
      v-model="pushShow"
      :maskClosable="false"
      :afterClose="pushClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="登陆名称"
          required
        >
          <a-input
            style="width: 370px"
            v-model="userLoginName"
            placeholder="请输入登陆名称"
            allowClear
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="批次编号"
        >
          <a-input
            style="width: 370px"
            v-model="planId"
            placeholder="请输入批次编号"
            allowClear
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="日期"
          required
        >
          <a-range-picker
            style="width: 370px"
            :disabledDate="disabledDate"
            :defaultValue="[moment().startOf('day'), moment().endOf('day')]"
            :show-time="{
              hideDisabledOptions: true,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss'),
              ],
            }"
            :ranges="{
              今天: [moment(), moment()],
              本月: [moment().startOf('month'), moment().endOf('day')],
            }"
            format="YYYY-MM-DD HH:mm:ss"
            @change="onChangeTime"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="pushShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="push_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 重推任务数 -->
    <a-modal
      title="重推任务"
      v-model="pushInfoShow"
      :maskClosable="false"
      :afterClose="pushInfoClose"
      class="actionSub-class"
      width="1000px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(pushColumns)"
        :columns="pushColumns"
        :dataSource="pushData"
        :pagination="false"
        size="small"
      >
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="deleteClick(record)" style="color: red"
            >停止</a
          >
        </span>
      </a-table>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="pushInfoShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "messagePush",
  data() {
    return {
      userColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "登录名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 100,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 120,
        },
        {
          title: "消息编号",
          ellipsis: true,
          dataIndex: "msgId",
          width: 200,
        },
        {
          title: "回调状态",
          ellipsis: true,
          dataIndex: "sendStatus",
          width: 100,
        },
        {
          title: "推送状态",
          ellipsis: true,
          dataIndex: "sended",
          scopedSlots: { customRender: "sended" },
          width: 100,
        },
        {
          title: "计划推送时间",
          ellipsis: true,
          dataIndex: "planSendTime",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      gmtEndOpen: false,
      startTime: null,
      endTime: null,
      pushShow: false,
      userLoginName: "",
      planId: "",
      userLoginNameSearch: "",
      searchDate: [
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      ],
      pushLength: 0,
      pushInfoShow: false,
      pushColumns: [
        {
          title: "登录名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 200,
        },
        {
          title: "开始时间",
          ellipsis: true,
          dataIndex: "start",
          width: 170,
        },
        {
          title: "结束时间",
          ellipsis: true,
          dataIndex: "end",
          width: 170,
        },
        {
          title: "开始推送时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "已推数量",
          ellipsis: true,
          dataIndex: "count",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      pushData: [],
      tableNum: "0",
      tableNumList: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
      ],
      sendedList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "已推送",
          value: "true",
        },
        {
          label: "未推送",
          value: "false",
        },
      ],
      sended: "",
      planIdAdd: "",
    };
  },
  mounted() {
    let startTim = new Date();
    startTim.setHours(0);
    startTim.setMinutes(0);
    startTim.setSeconds(0);
    startTim.setMilliseconds(0);
    let endTim = new Date();
    endTim.setHours(23);
    endTim.setMinutes(59);
    endTim.setSeconds(59);
    endTim.setMilliseconds(59);
    let effectTime = new Date();
    effectTime.setHours(12);
    effectTime.setMinutes(30);
    effectTime.setSeconds(30);
    effectTime.setMilliseconds(0);
    this.startTime = moment(startTim, "YYYY-MM-DD HH:mm:ss");
    this.endTime = moment(endTim, "YYYY-MM-DD HH:mm:ss");
    this.inquire(1);
  },
  methods: {
    moment, // 引用moment方法
    // 点击查询
    inquire(index) {
      let startTime = "";
      let endTime = "";
      if (this.startTime) {
        startTime =
          this.$common.transformTime(this.startTime).substr(0, 10) +
          " 00:00:00";
      }
      if (this.endTime) {
        endTime =
          this.$common.transformTime(this.endTime).substr(0, 10) + " 23:59:59";
      }
      if (index) {
        this.currentPage = index;
      }
      let data = {
        startTime: startTime,
        endTime: endTime,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.userLoginNameSearch,
        tableNum: this.tableNum,
        planId: this.planIdAdd,
      };
      if (this.sended !== "") {
        if (this.sended == "true") {
          data.sended = true;
        } else {
          data.sended = false;
        }
      }
      this.getUserList(data);
      this.getPushInfo();
    },
    // 查询推送记录
    getUserList(data) {
      this.loadingTable = true;
      this.$http
        .get("/userSmsSendReport/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 重新推送
    aginPush(val) {
      let data = {
        id: val.id,
        planId: val.planId,
        apiMsgId: val.apiMsgId,
      };
      this.$http.json_post("/userSmsSendReport/reReport", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重新推送成功");
          this.inquire();
        }
      });
    },
    // 不可选的发送日期
    disabledGmtCreatedStartDate(startValue) {
      const endValue = this.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledGmtCreatedEndDate(endValue) {
      const startValue = this.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChange(open) {
      if (!open) {
        this.gmtEndOpen = true;
      }
    },
    handleGmtCreatedEndOpenChange(open) {
      this.gmtEndOpen = open;
    },
    // 批量推送
    batchPush() {
      this.pushShow = true;
    },
    pushClose() {
      this.userLoginName = "";
      this.planId = "";
    },
    push_submit() {
      let data = {
        userLoginName: this.userLoginName,
        planId: this.planId,
        startTime: this.searchDate[0],
        endTime: this.searchDate[1],
      };
      this.$http
        .json_post("/userSmsSendReport/batchReReport", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("批量推送成功");
            this.inquire();
            this.pushShow = false;
          }
        });
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 选择时间
    onChangeTime(dates, dateStrings) {
      console.log(dateStrings);
      this.searchDate = [dateStrings[0], dateStrings[1]];
    },
    // 查询重推
    getPushInfo() {
      this.$http.get("/userSmsSendReport/pushInfo").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item, index) => {
            item.key = index + 1;
          });
          this.pushData = list;
          this.pushLength = res.data.length;
        }
      });
    },
    pushInfoClose() {},
    // 点击重推任务数
    pushClick() {
      this.pushInfoShow = true;
    },
    // 点击停止
    deleteClick(val) {
      this.$confirm({
        title: "确认停止吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            userLoginName: val.userLoginName,
          };
          this.$http
            .json_post("/userSmsSendReport/stopPush", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("停止成功");
                this.getPushInfo();
              }
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.messagePush {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    .date-time {
      display: inline-block;
    }

    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }

  // .ant-calendar-picker {
  //   width: 100% !important;
  // }
}
</style>
