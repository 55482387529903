<template>
  <div class="free">
    <div class="btn-cao">
      <div class="cx-cao">
        <div class="cx-cao-item">
          短链名称：
          <a-input
            placeholder="请输入短链名称"
            v-model="urlNameTitle"
            @keyup.enter="inquire(1, 'free')"
          />
        </div>
        <div class="cx-cao-item">
          添加时间：
          <a-range-picker
            :disabledDate="disabledDate"
            :defaultValue="[moment().startOf('month'), moment().endOf('day')]"
            :ranges="{
              今天: [moment(), moment()],
              本月: [moment().startOf('month'), moment().endOf('day')],
            }"
            format="YYYY-MM-DD"
            @change="onChangeFreeTime"
          />
        </div>
      </div>
      <div class="cx-btn-cao">
        <a-button type="primary" @click="inquire(1)">查询</a-button>
        <i style="display: inline-block; margin-right: 20px"></i>
        <a-button type="primary" @click="shortLinkClick()">添加短链</a-button>
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columnsFree)"
      :columns="columnsFree"
      :dataSource="dataFree"
      :pagination="paginationFree"
      :loading="loadingTable"
      size="small"
    >
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.longUrl }}</template>
          <a :href="record.longUrl" target="_blank" class="content">
            {{ text }}
          </a>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.longUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.longUrlCopyShow"
          >复制</a
        >
      </span>
      <span slot="shortUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.shortUrl }}</template>
          <a :href="record.shortUrl" target="_blank" class="content">{{
            text
          }}</a>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.shortUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.shortUrlCopyShow"
          >复制</a
        >
      </span>
    </a-table>
    <a-modal
      :title="shortModalTitle"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="短链名称:"
          required
        >
          <div class="s-url-input">
            <a-input v-model="name" placeholder="请输入短链名称" />
          </div>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="长网址链接:"
          required
        >
          <a-input
            v-model="longUrl"
            placeholder="请输入长网址链接"
            @blur="compareToLongUrl"
          />
          <a-tooltip placement="topLeft">
            <template slot="title"> 长链校验 </template>
            <a-checkbox
              v-model="needLongUrlCheck"
              style="position: absolute; left: 325px; top: -10px"
            />
          </a-tooltip>
          <span
            class="dropbox-ts"
            :style="{ color: longUrlExistYesOrNo ? '#52c41a' : 'red' }"
            v-if="urlFormat"
          >
            {{
              longUrlExistYesOrNo ? "校验通过：" + templateName : urlFormatText
            }}
          </span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="选择域名:"
          required
        >
          <a-select v-model="selectDomain" placeholder="请选择域名">
            <a-select-option
              v-for="i in selectDomainList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="shortLink_submit"
            :disabled="shortLinkDisabled || (!longUrlExistYesOrNo && urlFormat)"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "free",
  data() {
    return {
      freeTime: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      shortLinkDisabled: false,
      selectDomain: undefined,
      selectDomainList: [],
      shortModalTitle: "添加短链接",
      urlFormat: false,
      urlFormatText: "",
      longUrlExistYesOrNo: false,
      needLongUrlCheck: true,
      templateName: "",
      shortLinkShow: false,
      paginationFree: {},
      loadingTable: false,
      columnsFree: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "短链名称",
          ellipsis: true,
          dataIndex: "urlName",
          width: 150,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          scopedSlots: { customRender: "longUrl" },
          customCell: this.mouseoverLongUrlFree,
          width: 450,
        },
        {
          title: "短链",
          ellipsis: true,
          dataIndex: "shortUrl",
          scopedSlots: { customRender: "shortUrl" },
          customCell: this.mouseoverShortUrlFree,
          width: 250,
        },
        {
          title: "PV",
          ellipsis: true,
          dataIndex: "clickCount",
          width: 80,
        },
        {
          title: "UV",
          ellipsis: true,
          dataIndex: "uniqueClickCount",
          width: 80,
        },
        {
          title: "添加时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      dataFree: [],
      urlNameTitle: "",
      name: "",
      longUrl: "",
      currentPage: 1,
      pageSize: 10,
    };
  },
  watch: {
    needLongUrlCheck: {
      handler(newVal) {
        this.urlFormat = false;
      },
    },
  },
  created() {
    this.inquire(1);
  },
  methods: {
    moment, // 引用moment方法
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        gmtCreatedStart: "",
        gmtCreatedEnd: "",
        urlName: this.urlNameTitle,
      };
      if (this.freeTime[0]) {
        data.gmtCreatedStart = this.freeTime[0] + " 00:00:00";
        data.gmtCreatedEnd = this.freeTime[1] + " 23:59:59";
      }
      this.getSingleShortUrlList(data);
    },
    getSingleShortUrlList(data) {
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/singleShortUrlList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              if (item.gmtCreated) {
                item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              } else {
                item.gmtCreated = "";
              }
              item.longUrlCopyShow = false;
              item.shortUrlCopyShow = false;
            });
            this.paginationFree = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: this.pageSize,
              current: this.currentPage,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_free(current),
            };
            this.dataFree = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    currentPage_free(index) {
      this.inquire(index);
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 选择时间
    onChangeFreeTime(dates, dateStrings) {
      this.freeTime = [dateStrings[0], dateStrings[1]];
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    compareToLongUrl() {
      if (this.longUrl) {
        let httpIndex = this.longUrl.indexOf("http://");
        let httpsIndex = this.longUrl.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormat = false;
          if (this.needLongUrlCheck) {
            this.$http
              .get("/shortUrl/checkLongUrl", { longUrl: this.longUrl })
              .then((res) => {
                if (res.result == 200) {
                  this.urlFormat = true;
                  this.urlFormatText = "长链未匹配到任何模板";
                  this.longUrlExistYesOrNo = res.data.pass;
                  this.templateName = res.data.templateName;
                }
              });
          }
        } else {
          this.urlFormatText = "长网址链接格式不正确";
          this.urlFormat = true;
          this.longUrlExistYesOrNo = false;
        }
      }
    },
    // 点击新增短链接
    shortLinkClick() {
      this.shortModalTitle = "添加短链接";
      this.getDomainList();
      this.shortLinkShow = true;
      this.longUrl = "https://www.baidu.com/";
    },
    // 确定新增短链接
    shortLink_submit() {
      if (!this.name) {
        this.$message.warning("请输入短链名称");
        return;
      }
      if (this.name.length > 128) {
        this.$message.warning("短链名称不能大于128个字符");
        return;
      }
      if (!this.longUrl) {
        this.$message.warning("请输入长网址链接");
        return;
      }
      if (!this.selectDomain) {
        this.$message.warning("请选择域名");
        return;
      }
      if (!this.urlFormat) {
        if (this.needLongUrlCheck) {
          this.compareToLongUrl();
          return;
        }
      } else if (!this.longUrlExistYesOrNo) {
        this.$message.warning("请修改长链格式");
        return;
      }
      this.shortLinkDisabled = true;
      let data = {
        urlName: this.name,
        longUrl: this.longUrl,
        domain: this.selectDomain,
      };
      this.$http
        .json_post("/shortUrl/addSingleShortUrl", data)
        .then((res) => {
          this.shortLinkDisabled = false;
          if (res.result === 200) {
            this.shortLinkShow = false;
            this.$message.success("新增成功！");
            this.inquire(1);
          }
        })
        .catch((err) => {
          this.shortLinkDisabled = false;
        });
    },
    // 关闭新增短链接
    shortLinkClose() {
      this.name = "";
      this.selectDomain = undefined;
      this.longUrl = "https://www.baidu.com/";
      this.urlFormat = false;
      this.needLongUrlCheck = true;
      this.shortLinkDisabled = false;
    },
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 鼠标移入长链列--普通短链
    mouseoverLongUrlFree(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "longUrlCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "longUrlCopyShow", false);
              }
            });
          },
        },
      };
    },
    mouseoverShortUrlFree(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "shortUrlCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "shortUrlCopyShow", false);
              }
            });
          },
        },
      };
    },
  },
};
</script>

<style>
.ant-tooltip {
  max-width: 1000px !important;
  font-size: 12px !important;
}
a[disabled="disabled"] {
  color: rgba(0, 0, 0, 0.25) !important;
}
</style>
<style scoped lang="scss">
::v-deep .ant-table {
  border: none !important;
  table {
    border: 1px solid #e8e8e8 !important;
    .ant-table-tbody {
      td {
        border: none !important;
      }
    }
  }
}
::v-deep .resize-table-th {
  position: relative;
  .table-draggable-handle {
    transform: none !important;
    position: absolute;
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
.modify-long-click {
  color: #f56c6c;
}
.textarea-content {
  .content-item {
    display: flex;
    justify-content: space-between;
  }
}
.shortLinks-button {
  margin-bottom: 10px;
  .pv-search {
    display: inline-block;
    input {
      width: 115px;
      margin-right: 10px;
    }
    span {
      width: 115px;
      margin-right: 15px;
    }
  }
  button {
    margin-right: 15px;
  }
}
.icon-loading {
  margin: 0px 5px 0px 10px;
}
.number-pool {
  width: 800px;
  height: 100px;
  background: #f8f9f9;
  border: 1px solid #f2f2f2;
  padding: 16px;
  margin: 0px auto 5px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  position: relative;
  .number-pool-item {
    color: #848a9f;
    .number {
      font-size: 22px;
      line-height: 50px;
    }
  }
}
.contentLR {
  display: flex;
}
.left {
  width: 660px;
}
.right {
  margin-top: 115px;
  margin-left: 50px;
  width: 240px;
  height: 450px;
  background: url("../assets/phone.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px;
  .bubble {
    padding: 10px;
    width: 200px;
    // height: 100px;
    border-radius: 10px;
    background-color: #f0f0f0;
    position: relative;
    // margin-left: 20px;
    // font-size: 16px;
    overflow-wrap: break-word;
  }
  .bubble::before {
    position: absolute;
    top: 40%;
    left: -20px;
    content: "";
    width: 0;
    height: 0;
  }
}
.operatorList-checkbox {
  margin-left: 15px;
}
.shortLinks {
  .btn-cao {
    margin-bottom: 20px;
    .cx-cao {
      margin-bottom: 20px;
      .cx-cao-item {
        display: inline-block;
        margin-right: 20px;
        span {
          width: 250px;
        }
        input {
          width: 250px;
        }
      }
    }
  }
}
.actionSub-class {
  .s-url-input {
    position: relative;
    .s-url-a {
      position: absolute;
      left: 325px;
      top: 0px;
      width: 60px;
    }
  }
  .select-tag {
    width: 250px;
  }
  .template-sign {
    position: absolute;
    bottom: -4px;
    left: 0px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .template {
    position: absolute;
    bottom: -4px;
    left: 70px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template2 {
    position: absolute;
    bottom: -4px;
    left: 120px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template3 {
    position: absolute;
    bottom: -4px;
    left: 170px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .word {
    position: relative;
    display: block;
    width: 100%;
    line-height: 40px;
    text-align: right;
    font-style: normal;
    font-size: 12px;
  }
  .word i {
    color: red;
    font-style: normal;
  }
  .dropbox-ts {
    font-size: 12px;
    color: #999;
    display: block;
    line-height: 16px;
  }
}
</style>
