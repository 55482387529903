<template>
  <div class="simulateConfig">
    <div class="simulateConfig-search">
      <div class="simulateConfig-search-1">
        名称：
        <a-input v-model="name" placeholder="请输入名称" allowClear />
      </div>
    </div>
    <div class="simulateConfig-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="display: inline-block; margin-right: 20px"></i>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <!-- 模拟配置列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(codeColumns)"
      :columns="codeColumns"
      :dataSource="codeTableData"
      :pagination="codePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="enabled" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 已启用 </a-tag>
        <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="detailClick(record)">策略详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 添加模拟配置 -->
    <a-modal
      :title="shortModalTitle"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="名称:"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="启用:"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="shortLink_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 详情弹框 -->
    <a-modal
      :title="detailTitle"
      v-model="detailShow"
      :maskClosable="false"
      class="actionSub-class"
      width="1000px"
    >
      <a-button type="primary" @click="addDetail">新增</a-button>
      <!-- 模拟配置详情列表 -->
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(detailColumns)"
        :columns="detailColumns"
        :dataSource="detailTableData"
        size="small"
      >
        <span slot="ratePercentStr" slot-scope="text"> {{ text + "%" }} </span>
        <span slot="enabled" slot-scope="text" style="width: 100%">
          <a-tag v-if="text" color="green"> 已启用 </a-tag>
          <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="detailModifyClick(record)">修改</a>
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="detailDeleteClick(record)"
            style="color: red"
            >删除</a
          >
        </span>
      </a-table>
      <div>汇总：{{ allRatePercentStr / 10 }} %</div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 添加模拟配置详情 -->
    <a-modal
      :title="configModalTitle"
      v-model="configShow"
      :maskClosable="false"
      :afterClose="configClose"
      class="actionSub-class"
    >
      <a-form>
        <!-- <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="模拟配置:"
        >
          <a-select
            v-model="strategyIdAdd"
            placeholder="请选择模拟配置"
            allowClear
          >
            <a-select-option
              v-for="i in strategyNameList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item> -->
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="状态码:"
        >
          <a-input v-model="stat" placeholder="请输入状态码" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="百分比:"
        >
          <a-input-number :min="0" v-model="ratePercentStr" :max="100" />
          %
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="时间配置:"
        >
          <a-input v-model="responseTimeConfig" placeholder="请输入时间配置" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="启用:"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabledConfig"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="configShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="config_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "simulateConfig",
  data() {
    return {
      codeColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 200,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      codeTableData: [],
      codePagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      name: "",
      shortModalTitle: "",
      shortLinkShow: false,
      nameAdd: "",
      updataId: "",
      enabled: true,
      detailId: "",
      detailTitle: "",
      detailShow: false,
      detailColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "状态码",
          ellipsis: true,
          dataIndex: "stat",
          width: 80,
        },
        {
          title: "占比",
          ellipsis: true,
          dataIndex: "ratePercentStr",
          scopedSlots: { customRender: "ratePercentStr" },
          width: 60,
        },
        {
          title: "时间配置",
          ellipsis: true,
          dataIndex: "responseTimeConfig",
          width: 320,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      detailTableData: [],
      configModalTitle: "",
      configShow: false,
      strategyIdAdd: undefined,
      stat: "",
      ratePercentStr: "",
      responseTimeConfig: "",
      enabledConfig: true,
      detailUpdataId: "",
      allRatePercentStr: 0,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        name: this.name,
      };
      this.getConfigPage(data);
    },
    // 查询模拟配置列表
    getConfigPage(data) {
      this.loadingTable = true;
      this.$http
        .get("/statusMock/strategyPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.keyIndex = index + 1;
            });
            this.codeTableData = list;
            this.codePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.codeChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    codeChangePage(index) {
      this.inquire(index);
    },
    add() {
      this.shortLinkShow = true;
      this.shortModalTitle = "新增";
    },
    // 点击修改
    modifyClick(val) {
      this.shortLinkShow = true;
      this.shortModalTitle = "修改";
      this.nameAdd = val.name;
      this.enabled = val.enabled;
      this.updataId = val.id;
    },
    shortLinkClose() {
      this.nameAdd = "";
      this.enabled = true;
    },
    shortLink_submit() {
      let data = {
        name: this.nameAdd.trim(),
        enabled: this.enabled,
      };
      if (this.shortModalTitle === "新增") {
        this.$http.json_post("/statusMock/addStrategy", data).then((res) => {
          if (res.result === 200) {
            this.shortLinkShow = false;
            this.$message.success("新增成功！");
            this.inquire(1);
          }
        });
      } else if (this.shortModalTitle === "修改") {
        data.id = this.updataId;
        this.$http.json_post("/statusMock/editStrategy", data).then((res) => {
          if (res.result === 200) {
            this.shortLinkShow = false;
            this.$message.success("修改成功！");
            this.inquire(1);
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除这条数据吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/statusMock/deleteStrategy", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire();
            }
          });
        },
        onCancel() {},
      });
    },
    // 点击详情
    detailClick(val) {
      this.detailId = val.id;
      this.detailShow = true;
      this.detailTitle = "【" + val.name + "】详情";
      this.getdetailList();
    },
    getdetailList() {
      this.allRatePercentStr = 0;
      let data = {
        pageNo: this.currentPage,
        pageSize: 100,
        strategyId: this.detailId,
      };
      this.$http
        .get("/statusMock/strategyDetailPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              this.allRatePercentStr += item.ratePercent;
            });
            this.detailTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    addDetail() {
      this.configShow = true;
      this.configModalTitle = "新增";
    },
    // 点击修改
    detailModifyClick(val) {
      this.configShow = true;
      this.configModalTitle = "修改";
      this.strategyIdAdd = val.strategyId;
      this.stat = val.stat;
      this.ratePercentStr = val.ratePercentStr;
      this.responseTimeConfig = val.responseTimeConfig;
      this.enabledConfig = val.enabled;
      this.detailUpdataId = val.id;
    },
    configClose() {
      this.strategyIdAdd = undefined;
      this.stat = "";
      this.ratePercentStr = "";
      this.responseTimeConfig = "";
      this.enabledConfig = true;
    },
    config_submit() {
      let data = {
        strategyId: this.detailId,
        stat: this.stat,
        ratePercentStr: this.ratePercentStr,
        responseTimeConfig: this.responseTimeConfig,
        enabled: this.enabledConfig,
      };
      if (this.configModalTitle === "新增") {
        this.$http
          .json_post("/statusMock/addStrategyDetail", data)
          .then((res) => {
            if (res.result === 200) {
              this.configShow = false;
              this.$message.success("新增成功！");
              this.getdetailList();
            }
          });
      } else if (this.configModalTitle === "修改") {
        data.id = this.detailUpdataId;
        this.$http
          .json_post("/statusMock/editStrategyDetail", data)
          .then((res) => {
            if (res.result === 200) {
              this.configShow = false;
              this.$message.success("修改成功！");
              this.getdetailList();
            }
          });
      }
    },
    // 点击删除
    detailDeleteClick(val) {
      this.$confirm({
        title: "确认删除这条数据吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .get("/statusMock/deleteStrategyDetail", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.getdetailList();
              }
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.simulateConfig {
  .simulateConfig-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .simulateConfig-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .simulateConfig-search-button {
    margin-bottom: 20px;
  }
}
</style>
