<template>
  <div class="batchCostAnalysis">
    <div class="reminder">每小时会自动同步一次近7天批次成本</div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        发送名称：
        <a-input
          v-model="activityCode"
          @keyup.enter="query(1)"
          placeholder="请输入发送名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        业务类型：
        <a-select v-model="category" placeholder="请选择类型" allowClear>
          <a-select-option
            v-for="i in categoryList"
            :key="i.value"
            :value="i.value"
          >
            {{ i.key }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        时间范围：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="syncData">手动同步</a-button>
        <a-button type="primary" @click="exportExcel">导出</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.planId"
    >
      <span slot="totalCost" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 批次详细成本 </span>
          <a-button type="link" @click="detail(record)" size="small">{{
            text
          }}</a-button>
        </a-tooltip>
      </span>
    </a-table>

    <a-modal
      title="统计数据"
      v-model="syncDataShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="日期:">
          <a-range-picker
            v-model="syncDataTime"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="syncDataShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="syncDataSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批次详细成本"
      v-model="detailShow"
      :maskClosable="false"
      class="action-class"
      width="900px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(detailTableColumns)"
        :columns="detailTableColumns"
        :dataSource="detailTableData"
        :pagination="false"
        :loading="detailTableLoading"
        size="small"
        :rowKey="(record) => record.channelSmsAccountId"
      >
        <span slot="totalCost" slot-scope="text, record">
          <a-tooltip placement="left">
            <span slot="title"> 通道历史成本 </span>
            <a-button type="link" @click="channelHistoryPrice(record)">
              {{ text }}
            </a-button>
          </a-tooltip>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="channelHistoryTitle"
      v-model="channelHistoryShow"
      :maskClosable="false"
      class="action-class"
      width="1100px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(channelHistoryTableColumns)"
        :columns="channelHistoryTableColumns"
        :dataSource="channelHistoryTableData"
        :pagination="channelHistoryTablePagination"
        :loading="channelHistoryTableLoading"
        size="small"
        :rowKey="(record) => record.id"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="channelHistoryShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "batchCostAnalysis",
  data() {
    return {
      activityCode: "",
      category: undefined,
      categoryList: [],
      time: [
        moment().subtract(6, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "任务编号",
          dataIndex: "planId",
          ellipsis: true,
          width: 100,
          align: "center",
        },
        {
          title: "发送名称",
          dataIndex: "activityCode",
          ellipsis: true,
          width: 200,
        },
        {
          title: "业务类型",
          dataIndex: "category",
          ellipsis: true,
          width: 100,
        },
        {
          title: "移动成功数",
          dataIndex: "cmccSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "联通成功数",
          dataIndex: "cmcuSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "电信成功数",
          dataIndex: "cmctSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "其他成功数",
          dataIndex: "otherSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "总成本",
          dataIndex: "totalCost",
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: "totalCost" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      syncDataTime: [],
      syncDataShow: false,
      syncLoading: null,
      planId: "",
      channelId: "",
      detailTableData: [],
      detailTableColumns: [
        {
          title: "编号",
          dataIndex: "priceChangeLogId",
          ellipsis: true,
          width: 100,
          align: "center",
        },
        {
          title: "通道名称",
          dataIndex: "channelName",
          ellipsis: true,
          width: 200,
        },
        {
          title: "移动成功数",
          dataIndex: "cmccSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "联通成功数",
          dataIndex: "cmcuSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "电信成功数",
          dataIndex: "cmctSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "其他成功数",
          dataIndex: "otherSuccessCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "总成本",
          dataIndex: "totalCost",
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: "totalCost" },
        },
      ],
      detailTableLoading: false,
      detailShow: false,
      channelHistoryPageNo: 1,
      channelHistoryTableData: [],
      channelHistoryTableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "id" },
        },
        {
          title: "移动成本",
          dataIndex: "cmccCost",
          ellipsis: true,
          width: 80,
        },
        {
          title: "联通成本",
          dataIndex: "cmcuCost",
          ellipsis: true,
          width: 80,
        },
        {
          title: "电信成本",
          dataIndex: "cmctCost",
          ellipsis: true,
          width: 80,
        },
        {
          title: "其他成本",
          dataIndex: "otherCost",
          ellipsis: true,
          width: 80,
        },
        {
          title: "开始时间",
          dataIndex: "startDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "结束时间",
          dataIndex: "endDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "修改时间",
          dataIndex: "changeDate",
          ellipsis: true,
          width: 170,
        },
      ],
      channelHistoryTablePagination: {},
      channelHistoryTableLoading: false,
      channelHistoryShow: false,
      channelHistoryTitle: "",
    };
  },
  mounted() {
    this.getCategoryList();
    this.query(1);
  },
  methods: {
    // 获取业务类型
    getCategoryList() {
      this.$http.get("/shortUrl/getCategoryList").then((res) => {
        if (res.result === 200) {
          this.categoryList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        activityCode: this.activityCode,
        category: this.category,
        startDate: this.time[0],
        endDate: this.time[1],
      };
      this.loadingTable = true;
      this.$http
        .get("/channelSmsAccountCostLog/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    syncData() {
      this.syncDataTime = [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      this.syncDataShow = true;
    },
    syncDataSubmit() {
      if (!this.syncDataTime[0] || !this.syncDataTime[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      this.syncLoading = this.$syncLoading({
        message: "数据同步中...",
      });
      let data = {
        startDate: this.syncDataTime[0],
        endDate: this.syncDataTime[1],
      };
      this.$http
        .json_post("/channelSmsAccountCostLog/syncData", data)
        .then((res) => {
          this.syncDataShow = false;
          if (res.result == 200) {
            this.getSyncDataStatus(res.data);
          }
        })
        .catch((err) => {
          this.syncDataShow = false;
        });
    },
    getSyncDataStatus(uuid) {
      this.$http
        .get("/channelSmsAccountCostLog/getSyncDataStatus", { uuid })
        .then((res) => {
          if (res.result === 200) {
            if (res.data) {
              if (!this.timer) {
                this.timer = setInterval(() => {
                  this.getSyncDataStatus(uuid);
                }, 1000);
              }
            } else {
              clearInterval(this.timer);
              this.timer = null;
              this.syncLoading.close();
              this.$message.success("同步成功");
              this.query(1);
            }
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    exportExcel() {
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        activityCode: this.activityCode,
        category: this.category,
        startDate: this.time[0],
        endDate: this.time[1],
      };

      let params = "";
      for (let i in data) {
        if (data[i]) {
          params += i + "=" + data[i] + "&";
        }
      }

      location.href =
        this.$path + "/channelSmsAccountCostLog/list/export?" + params;
      this.$message.success("导出成功");
    },
    detail(record) {
      this.detailTableLoading = true;
      if (record) {
        this.planId = record.planId;
      }
      let data = {
        pageNo: 1,
        pageSize: 1000,
        planId: this.planId,
      };
      this.$http
        .get("/channelSmsAccountCostLog/detailByPlanId", data)
        .then((res) => {
          this.detailTableLoading = false;
          if (res.result === 200) {
            this.detailShow = true;
            this.detailTableData = res.data.records;
          }
        });
    },
    channelHistoryPrice(record, index) {
      this.channelHistoryTableLoading = true;
      if (index) {
        this.channelHistoryPageNo = index;
      }
      if (record) {
        this.channelId = record.channelSmsAccountId;
        this.channelHistoryTitle = record.channelName + " 历史成本";
        this.priceChangeLogId = record.priceChangeLogId;
      }
      let data = {
        pageNo: this.channelHistoryPageNo,
        pageSize: 10,
        planId: this.planId,
        channelId: this.channelId,
        priceChangeLogId: this.priceChangeLogId,
      };
      this.$http
        .get("/channelSmsAccountCostLog/pageHistoryPrice", data)
        .then((res) => {
          this.channelHistoryTableLoading = false;
          if (res.result === 200) {
            this.channelHistoryShow = true;
            this.channelHistoryTableData = res.data.records;
            this.channelHistoryTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changeChannelHistoryPageItem(current),
            };
          }
        });
    },
    changeChannelHistoryPageItem(index) {
      this.channelHistoryPrice(null, index);
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
