<template>
  <div class="channelQualityAnalysis">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="通道AB测试分析">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="空号检测列表">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "channelQualityAnalysis",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/channelQualityAnalysis/randomRouteDataAnalysis") {
          this.active = "1";
        } else if (newVal == "/channelQualityAnalysis/blankNumberPage") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (
            this.$route.path ==
            "/channelQualityAnalysis/randomRouteDataAnalysis"
          )
            return;
          this.$router.replace(
            "/channelQualityAnalysis/randomRouteDataAnalysis"
          );
        }
        if (newVal === "2") {
          if (this.$route.path == "/channelQualityAnalysis/blankNumberPage")
            return;
          this.$router.replace("/channelQualityAnalysis/blankNumberPage");
        }
      },
    },
  },
};
</script>