<template>
  <div class="lastSevenDaysData">
     <a-row class="pie-card">
      <a-col :span="16">
        <a-button type="primary" @click="periodTotalCountInfo" class="btn-class">刷新</a-button>
        <h4>近七日发送分析</h4>
        <div id="sevenContainer"></div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import G2 from "@antv/g2";
import { DataSet } from "@antv/data-set";
export default {
  name: "lastSevenDaysData",
  data() {
    return {
      chart: null,
      dv: []
    }
  },
  mounted() {
    let dateSignList = [];
    let list = [];
    for (let i = 0; i < 7; i++) {
      let date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24 * 1);
      date.setTime(date.getTime() - 3600 * 1000 * 24 * i);
      date = this.$common.transformTime(date).substring(0, 10);
      dateSignList.push(date);
    }
    dateSignList.reverse();
    dateSignList.forEach(item => {
      let obj = {
        dateSign: item,
        successSendCount: 0, 
        failureSendCount: 0, 
        waitCallbackCount: 0, 
        total: 0
      }
      list.push(obj)
    })
    this.dv = this.dataTransformation(list);
    this.chartStart();
    this.periodTotalCountInfo();
  },
  methods: {
    // 查询近七日数据
    periodTotalCountInfo() {
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      start = this.$common.transformTime(start).substring(0, 10);
      end = this.$common.transformTime(end).substring(0, 10);
      let data = {
        start: start,
        end: end
      }
      this.$http.get("/msgSendCount/periodTotalCountInfo",data).then(res => {
        if (res.result === 200) {
          let chartData = res.data;
          this.$message.success("查询成功")
          if (!chartData || chartData.length < 1) {
            return
          }
          chartData.forEach(item => {
            item.total = item.successSendCount+item.failureSendCount+item.waitCallbackCount;
          })
          let dv = this.dataTransformation(chartData);
          this.chart.changeData(dv);
        }
      })
    },
    // 绘制近七日图
    chartStart() {
      this.chart = new G2.Chart({
        container: "sevenContainer",
        forceFit: true,
        height: 400,
      });
      this.chart.source(this.dv, {
        dateSign: {
          type: 'cat',
          tickCount: 7
        }
      });
      this.chart.tooltip({
        
      })
      this.chart
        .intervalStack()
        .position("dateSign*temperature")
        .tooltip('temperature*count*total', function (temperature, count, total) {
          let rate = '0%';
          if (total) {
            rate = (temperature / total * 100).toFixed(2)+"%";
          }
          return {
            name: count,//itemTpl:{name}
            value: temperature+ ' | ' + rate// itemTpl:{value}
          }
        })
        .color("count",[ '#67C23A', '#E6A23C', '#F56C6C' ]);
      this.chart.render();
    },
    // 柱状图数据转换
    dataTransformation(chartData) {
      let list = [];
      chartData.forEach(item => {
        let obj = {
          dateSign: item.dateSign,
          发送成功: item.successSendCount, 
          发送失败: item.failureSendCount, 
          等待回执: item.waitCallbackCount, 
          total: item.total
        }
        list.push(obj)
      })
      let ds = new DataSet();
      let dv = ds.createView().source(list);
      dv.transform({
        type: "fold",
        fields: [ "发送成功", "等待回执", "发送失败" ], // 展开字段集
        key: "count", // key字段
        value: "temperature" // value字段
      });
      return dv
    }
  },
}
</script>
<style lang="scss" scoped>
  .btn-class {
    margin-bottom: 20px;
  }
</style>