<template>
  <div class="provinceAnalysis">
    <div class="searchCondition">
      <div class="searchConditionItem">
        批次编号：
        <a-input
          v-model="activityCode"
          @keyup.enter="inquire(1)"
          placeholder="请输入批次编号"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        />
      </div>
      <div class="searchConditionItem">
        类型：
        <a-select
          v-model="sendStatusSelect"
          placeholder="请选择类型"
          allowClear
        >
          <a-select-option
            v-for="i in sendStatusList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input
          v-model="searchLongUrl"
          @keyup.enter="inquire(1)"
          placeholder="请输入长链"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        省份：
        <a-input
          v-model="province"
          @keyup.enter="inquire(1)"
          placeholder="请输入省份"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        通道名称：
        <a-input
          v-model="channelName"
          @keyup.enter="inquire(1)"
          placeholder="请输入通道名称"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
      </div>
    </div>
    <!-- 长链发送列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(urlReportColumns)"
      :columns="urlReportColumns"
      :dataSource="urlReportTableData"
      :pagination="false"
      :loading="loading"
      size="small"
    >
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.longUrl }}</template>
          <span class="content">{{ record.longUrlLabel }}</span>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.longUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.isCopyShow"
          >复制</a
        >
      </span>
      <span slot="clickRate">
        点击率
        <a-tooltip>
          <template slot="title"> uv/发送成功数 </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="number" slot-scope="text, record" style="width: 100%">
        <div>
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                <div>总条数:{{ record.totalCount }}</div>
                <div>
                  发送条数:{{ record.totalCount }} | {{ record.totalCountRate }}
                </div>
              </div>
            </template>
            <a href="javascript:;" class="send-color-total">{{
              record.totalCount
            }}</a>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                发送成功条数:{{ record.successCount }} |
                {{ record.successCountRate }}
              </div>
            </template>
            <a href="javascript:;" class="send-color-success">{{
              record.successCount
            }}</a>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                等待回执条数:{{ record.waitCallbackCount }} |
                {{ record.waitCallbackCountRate }}
              </div>
            </template>
            <a href="javascript:;" class="send-color-wait">{{
              record.waitCallbackCount
            }}</a>
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                发送失败条数:{{ record.failureCount }} |
                {{ record.failureCountRate }}
              </div>
            </template>
            <a href="javascript:;" class="send-color-fail">{{
              record.failureCount
            }}</a>
          </a-tooltip>
        </div>
      </span>
      <span slot="footer">
        <div class="pagination">
          <my-pagination
            :tableList="urlReportTableData"
            :total="total"
            :pageNo="urlReportTablePageNo"
            :pageSize="urlReportTablePageSize"
            summaryShow
            @changePage="urlReportChangePage"
          ></my-pagination>
        </div>
      </span>
    </a-table>
    <!-- 修改单价 -->
  </div>
</template>
<script>
import moment from "moment";
import myPagination from "../tool/myPagination.vue";

export default {
  components: {
    myPagination,
  },
  name: "provinceAnalysis",
  data() {
    return {
      time: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      gmtEndOpen: false,
      // yc多选下载
      selectedRowKeys: [],
      selectedRows: [], //多选选中的数据
      tableData: "",
      downloadId: "", //下载id
      downloadTimer: "", //刷新下载状态定时器
      // 排序
      clickRateOrder: "",
      registerRateOrder: "",
      desc: "",
      sort: "",
      msgUnitPrice: "",
      voiceUnitPrice: "",
      resourceUnitPrice: "",
      urlReportId: "",
      sendStatusSelect: undefined,
      sendStatusList: [
        {
          value: "BATCH_SEND",
          label: "群发",
        },
        {
          value: "API_MESSAGE",
          label: "挂短",
        },
      ],
      loading: false,
      urlReportColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>合计</span>,
                attrs: {
                  colSpan: 7,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "发送日期",
          ellipsis: true,
          dataIndex: "date",
          width: 100,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "activityCode",
          width: 100,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "通道名称",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          width: 180,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          width: 60,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          customCell: this.mouseoverLongUrl,
          scopedSlots: { customRender: "longUrl" },
          width: 300,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeLabel",
          width: 60,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "条数",
          ellipsis: true,
          dataIndex: "totalCount",
          align: "center",
          scopedSlots: { customRender: "number" },
          width: 300,
        },
        {
          title: "点击uv",
          ellipsis: true,
          dataIndex: "clickUv",
          width: 70,
        },
        {
          ellipsis: true,
          dataIndex: "clickRate",
          slots: { title: "clickRate" },
          width: 80,
        },
        {
          title: "发送成功率",
          ellipsis: true,
          dataIndex: "successCountRate",
          width: 90,
        },
      ],
      urlReportTableData: [],
      total: 0,
      urlReportTablePageNo: 1,
      urlReportTablePageSize: 10,
      activityCode: "",
      searchLongUrl: "",
      province: "",
      channelName: "",
      syncLoading: null,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 鼠标移入长链列--智能短链
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  true
                );
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  false
                );
              }
            });
          },
        },
      };
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 点击查询
    inquire(pageNo, pageSize) {
      if (pageNo) {
        this.urlReportTablePageNo = pageNo;
      }
      if (pageSize) {
        this.urlReportTablePageSize = pageSize;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        activityCode: this.activityCode,
        longUrl: this.searchLongUrl,
        province: this.province,
        channelName: this.channelName,
        startDate: this.time[0],
        endDate: this.time[1],
        type: this.sendStatusSelect,
        clickRateOrder: this.desc !== "" ? this.desc : undefined,
        registerRateOrder: this.sort !== "" ? this.sort : undefined,
      };
      this.urlReportTableData = [];
      this.getUrlReportList(data);
    },
    getUrlReportList(data) {
      this.loading = true;
      this.$http
        .get("/batchNumberReport/provinceBatchNumberReportList", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.tableData = "";
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.typeLabel = "";
              item.longUrlLabel = item.longUrl;
              if (item.longUrl.length > 50) {
                item.longUrlLabel = item.longUrl.substring(0, 50) + "...";
              }
              item.totalCountRate = "0%";
              item.successCountRate = "0%";
              item.failureCountRate = "0%";
              item.waitCallbackCountRate = "0%";
              if (item.totalCount !== 0) {
                item.totalCountRate =
                  ((item.totalCount / item.totalCount) * 100).toFixed(2) + "%";
                item.successCountRate =
                  ((item.successCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.failureCountRate =
                  ((item.failureCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.waitCallbackCountRate =
                  ((item.waitCallbackCount / item.totalCount) * 100).toFixed(
                    2
                  ) + "%";
              }
              if (item.successCount !== 0) {
                item.clickRate =
                  ((item.clickUv / item.successCount) * 100).toFixed(2) + "%";
              } else {
                item.clickRate = "0.00%";
              }
              item.isCopyShow = false;
              switch (item.type) {
                case "BATCH_SEND":
                  item.typeLabel = "群发";
                  break;
                case "API_MESSAGE":
                  item.typeLabel = "挂短";
                  break;
              }
            });
            this.urlReportTableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 翻页
    urlReportChangePage(pageNo, pageSize) {
      this.inquire(pageNo, pageSize);
    },
    // 每页
    onShowSizeChange(pageSize) {
      this.urlReportTablePageSize = pageSize;
      this.inquire(1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
