import Vue from "vue";
import { message } from "ant-design-vue";

//封装公共代码
const common = {};

common.dayFormat = (day) => {
  let date = new Date(day).toLocaleDateString().replace(/\//g, "-");
  date = date.split("-");
  date.forEach((element, index) => {
    if (element * 1 < 10) {
      date[index] = 0 + element;
    }
  });
  date = date.join("-");
  return date;
};

// 复制数据库信息
common.copyInfo = (parameter, dbName) => {
  let headBorder = "";
  let content = ""; // copy内容
  let paramsConent = []; // 内容长度
  let heatLengt = []; // 标题长度
  for (let item in parameter) {
    let str = parameter[item].toString();
    paramsConent.push(str.length);
    heatLengt.push(item.length);
  }
  paramsConent = paramsConent.sort((a, b) => b - a);
  heatLengt = heatLengt.sort((a, b) => b - a);
  // 表头绘制
  if (dbName.length < paramsConent[0] + heatLengt[0]) {
    let dValue = paramsConent[0] + heatLengt[0] + 2 - dbName.length;
    for (let j = 0; j < dValue; j++) {
      let blankSpace = " ";
      dbName += blankSpace;
    }
    // 边框绘制
    for (let y = 0; y < paramsConent[0] + heatLengt[0] + 4; y++) {
      let HLine = "-";
      headBorder += HLine;
    }
    headBorder = "+" + headBorder + "+";
  }
  // 表格内容绘制
  for (let i in parameter) {
    let heat = i;
    parameter[i] = parameter[i].toString();
    if (parameter[i].length < paramsConent[0]) {
      let dValue = paramsConent[0] - parameter[i].length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        parameter[i] += blankSpace;
      }
    }
    // 绘制标题
    if (i.length < heatLengt[0]) {
      let dValue = heatLengt[0] - i.length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        heat += blankSpace;
      }
    }
    if (parameter[i].trim()) {
      content += "| " + heat + ": " + parameter[i] + " |" + "\n";
    }
  }
  return (
    headBorder +
    "\n" +
    "| " +
    dbName +
    " |" +
    "\n" +
    headBorder +
    "\n" +
    content +
    headBorder
  );
};

//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

// 插入到光标位置
common.insertInputTxt = (insertText, id, textarea) => {
  let text = insertText;
  let areaField = document.getElementById(id); // 拿到目标标签
  // IE浏览器
  if (document.selection) {
    areaField.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  } else if (areaField.selectionStart || areaField.selectionStart === 0) {
    // 谷歌 Firefox 等
    let startPos = areaField.selectionStart;
    let endPos = areaField.selectionEnd;
    let restoreTop = areaField.scrollTop; // 获取滚动条高度
    //  textarea 是v-model的值
    // text 是 选择的要插入的值
    textarea =
      textarea.substring(0, startPos) +
      text +
      textarea.substring(endPos, textarea.length);
    if (restoreTop > 0) {
      areaField.scrollTop = restoreTop;
    }
    areaField.focus();
    areaField.selectionStart = startPos + text.length;
    areaField.selectionEnd = startPos + text.length;
  } else {
    textarea += text;
    areaField.focus();
  }
  return textarea;
};

//Es6数组去重
common.unique = function (arr) {
  var x = new Set(arr);
  return [...x];
};

// 随机生成数字+大小写字母
common.randomStr = function (randomFlag, min, max) {
  // randomFlag：是否任意长度
  // min：任意长度最小位[固定位数]
  // max：任意长度最大位
  //生成3-32位随机串：randomStr(true, 3, 32)
  //生成43位随机串：randomStr(false, 43)
  let str = "",
    range = min,
    index = null,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

  if (randomFlag) {
    // 随机产生
    range = Math.round(Math.random() * (max - min)) + min;
  }

  for (let i = 0; i < range; i++) {
    index = Math.round(Math.random() * (arr.length - 1));
    str += arr[index];
  }
  return str;
};

//input只能输入数字和一个小数点
common.numberCheck = (num) => {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换，保留数字和小数点
  str = str.replace(/[^\d^\.]+/g, "");
  //如果需要保留小数点后两位，则用下面公式
  // str = str.replace(/\.\d\d$/,'')
  return str;
};

// 创建cookie
common.setCookie = (c_name, value, expiredays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
};

// 读取cookie
common.getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    //先查询cookie是否为空，为空就return ""
    let c_start = document.cookie.indexOf(c_name + "="); //通过String对象的indexOf()来检查这个cookie是否存在，不存在就为 -1
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1; //最后这个+1其实就是表示"="号啦，这样就获取到了cookie值的开始位置
      let c_end = document.cookie.indexOf(";", c_start); //其实我刚看见indexOf()第二个参数的时候猛然有点晕，后来想起来表示指定的开始索引的位置...这句是为了得到值的结束位置。因为需要考虑是否是最后一项，所以通过";"号是否存在来判断
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end)); //通过substring()得到了值。想了解unescape()得先知道escape()是做什么的，都是很重要的基础，想了解的可以搜索下，在文章结尾处也会进行讲解cookie编码细节
    }
  }
  return "";
};

// 清除cookie
common.delCookie = (c_name) => {
  common.setCookie(c_name, "", -1);
};

// 校验手机号
common.checkMobile = (testNumber) => {
  if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(testNumber)) {
    message.error("请输入正确手机号");
    return false;
  }
  return true;
};

// 保存号码cookie
common.numberSetCookie = (testNumber, key) => {
  let testNumberList = [];
  let numberStr = common.getCookie(key + "Number");
  if (numberStr) {
    let numberArr = JSON.parse(numberStr);
    let newNumberArr = [];
    numberArr.push({
      value: testNumber,
    });
    let friendsValue = Array.from(numberArr, ({ value }) => value);
    friendsValue = common.unique(friendsValue);
    for (let i in friendsValue) {
      newNumberArr.push({
        value: friendsValue[i],
      });
    }
    if (newNumberArr.length > 10) {
      newNumberArr.splice(0, 1);
    }
    testNumberList = newNumberArr;
  } else {
    if (testNumber) {
      testNumberList.push({
        value: testNumber,
      });
    }
  }
  common.setCookie(key + "Number", JSON.stringify(testNumberList), 30);
};

//防止多次点击
common.promiseDownload = (href) => {
  let p = new Promise((resolve, rejects) => {
    let a = false;
    window.location.href = href;
    resolve(a);
  });
  return p;
};

// 测试
common.a = function () {
  console.log("a测试测试");
  return 888;
};

common.getTitle = (columns) => {
  const draggingMap = {};
  columns.forEach((col) => {
    const k = col.dataIndex || col.key;
    draggingMap[k] = col.width;
  });
  const draggingState = Vue.observable(draggingMap);
  const resizeableTitle = (h, props, children) => {
    let thDom = null;
    const { key, ...restProps } = props;
    let col;
    if (key == "selection-column") {
      return (
        <th {...restProps} class="resize-table-th">
          {children}
        </th>
      );
    } else {
      col = columns.find((item) => {
        const k = item.dataIndex || item.key;
        return k === key;
      });
    }
    if (col) {
      if (!col.width) {
        return <th {...restProps}>{children}</th>;
      }
      const onDrag = (x) => {
        draggingState[key] = 0;
        col.width = Math.max(x, 1);
      };
      const onDragstop = () => {
        draggingState[key] = thDom.getBoundingClientRect().width;
      };
      return (
        <th
          {...restProps}
          v-ant-ref={(r) => {
            thDom = r;
          }}
          width={draggingState[key]}
          class="resize-table-th"
        >
          {children}
          <vue-draggable-resizable
            key={col.dataIndex || col.key}
            class="table-draggable-handle"
            w={10}
            x={col.width || draggingState[key]}
            z={1}
            axis="x"
            draggable={true}
            resizable={false}
            onDragging={onDrag}
            onDragstop={onDragstop}
          ></vue-draggable-resizable>
        </th>
      );
    }
  };
  let components = {
    header: {
      cell: resizeableTitle,
    },
  };
  return components;
};

// 表格隔行变色
common.rowClassColor = (record, index) => {
  let className = "light-row";
  if (index % 2 === 1) className = "dark-row";
  return className;
};
export default common;
