<template>
  <div class="blankNumberPage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        发送名称：
        <a-input
          v-model="activityCode"
          placeholder="请输入发送名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        需要确认：
        <a-checkbox
          v-model="needConfirm"
          @change="inquire(1)"
          style="margin-top: 5px"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire()">查询</a-button>
        <a-button type="primary" @click="synchronization()">同步</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="count" slot-scope="text, record">
        <a href="javascript:;" @click="detail(record)">{{ text }}</a>
      </span>
      <span slot="percent" slot-scope="text, record">
        {{ text }}
        <a-tooltip
          placement="topLeft"
          style="margin-left: 5px"
          v-if="record.remark"
        >
          <template slot="title">
            {{ record.remark }} | {{ record.userLoginName }}
          </template>
          <a-icon type="info-circle" style="color: #fa8c16" />
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="editRemark(record)">备注</a>
      </span>
    </a-table>

    <a-modal
      title="详情"
      v-model="detailVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
      :afterClose="detailClose"
    >
      <div style="margin-bottom: 10px">
        客户编号:
        <a-input
          v-model="customerCode"
          style="width: 240px; margin-right: 10px"
        />
        未通过:
        <a-checkbox
          v-model="unPass"
          @change="detail(null, 1)"
          style="margin: 5px 10px 0 0"
        />
        <a-button type="primary" @click="detail(null, 1)">查询</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(detailColumns)"
        :columns="detailColumns"
        :dataSource="detailTableData"
        :pagination="detailPagination"
        size="small"
      >
        <span slot="customerCode" slot-scope="text, record">
          {{ text }}
          <a-tooltip
            placement="topLeft"
            style="margin-left: 5px"
            v-if="record.remark"
          >
            <template slot="title">
              {{ record.remark }} | {{ record.userLoginName }}
            </template>
            <a-icon type="info-circle" style="color: #fa8c16" />
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="editDetailRemark(record)">备注</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="removeBlankNumber(record)"
          >
            <template slot="title">是否确认移除{{ record.name }}</template>
            <a href="javascript:;" style="color: #ff4d4f">移除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改备注"
      v-model="editRemarkVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editRemarkVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editDetailRemark_submit"
            v-if="editDetailRemarkVisible"
            >确定</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editRemark_submit"
            v-else
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "blankNumberPage",
  data() {
    return {
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "发送日期",
          ellipsis: true,
          dataIndex: "submitDate",
          width: 120,
        },
        {
          title: "发送名称",
          ellipsis: true,
          dataIndex: "activityCode",
          width: 200,
        },
        {
          title: "通道名称",
          ellipsis: true,
          dataIndex: "channelName",
          width: 200,
        },
        {
          title: () => {
            return (
              <span>
                投放空号数
                <a-tooltip placement="topLeft" style="margin-left:5px">
                  <template slot="title">
                    随机选择若干空号,插入到发送任务一起发送
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </span>
            );
          },
          ellipsis: true,
          dataIndex: "count",
          scopedSlots: { customRender: "count" },
          width: 120,
        },
        {
          title: () => {
            return (
              <span>
                检测通过率
                <a-tooltip placement="topLeft" style="margin-left:5px">
                  <template slot="title">
                    空号如果发送成功，则检测不通过
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </span>
            );
          },
          ellipsis: true,
          dataIndex: "percent",
          scopedSlots: { customRender: "percent" },
          width: 120,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      pagination: {},
      loadingTable: false,
      activityCode: "",
      pageNo: 1,
      detailColumns: [
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          scopedSlots: { customRender: "customerCode" },
          width: 200,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          width: 150,
        },
        {
          title: "空号来源",
          ellipsis: true,
          dataIndex: "sourcePlatformName",
          width: 150,
        },
        {
          title: "空号状态",
          ellipsis: true,
          dataIndex: "sourceRealStatus",
          width: 150,
        },
        {
          title: "来源发送时间",
          ellipsis: true,
          dataIndex: "sourceSubmitTime",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      detailTableData: [],
      detailPagination: {},
      detailPageNo: 1,
      detailVisible: false,
      editRemarkVisible: false,
      remark: "",
      summaryId: "",
      needConfirm: false,
      customerCode: "",
      unPass: true,
      editDetailRemarkVisible: false,
      id: "",
    };
  },
  mounted() {
    let needConfirm = this.$route.query.needConfirm;
    if (needConfirm) {
      this.needConfirm = true;
    }
    this.inquire();
  },
  methods: {
    editRemark(val) {
      this.summaryId = val.id;
      this.remark = val.remark;
      this.editDetailRemarkVisible = false;
      this.editRemarkVisible = true;
    },
    editRemark_submit() {
      let data = {
        id: this.summaryId,
        remark: this.remark,
      };
      this.$http.json_post("/blankNumber/editRemark", data).then((res) => {
        if (res.result == 200) {
          this.editRemarkVisible = false;
          this.$message.success("修改成功");
          this.inquire(1);
        }
      });
    },
    editDetailRemark(val) {
      this.id = val.id;
      this.remark = val.remark;
      this.editDetailRemarkVisible = true;
      this.editRemarkVisible = true;
    },
    editDetailRemark_submit() {
      let data = {
        id: this.id,
        summaryId: this.summaryId,
        remark: this.remark,
      };
      this.$http
        .json_post("/blankNumber/editDetailRemark", data)
        .then((res) => {
          if (res.result == 200) {
            this.editRemarkVisible = false;
            this.$message.success("修改成功");
            this.detail();
          }
        });
    },
    removeBlankNumber(val) {
      let data = {
        id: val.id,
        summaryId: this.summaryId,
      };
      this.$http
        .json_post("/blankNumber/removeBlankNumber", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("移除成功");
            this.detail();
          }
        });
    },
    synchronization() {
      this.$http.get("/blankNumber/syn").then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.inquire();
        }
      });
    },
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        activityCode: this.activityCode,
        needConfirm: this.needConfirm,
      };
      this.loadingTable = true;
      this.$http
        .get("/blankNumber/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    changePageItem(index) {
      this.inquire(index);
    },
    detailClose() {
      this.customerCode = "";
      this.unPass = true;
      this.detailPageNo = 1;
      this.inquire(1);
    },
    detail(val, index) {
      if (val) {
        this.summaryId = val.id;
      }
      if (index) {
        this.detailPageNo = index;
      }
      let data = {
        pageNo: this.detailPageNo,
        pageSize: 10,
        summaryId: this.summaryId,
        customerCode: this.customerCode,
        unPass: this.unPass,
      };
      this.$http.get("/blankNumber/detailPage", data).then((res) => {
        if (res.result === 200) {
          this.detailTableData = res.data.records;
          this.detailPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changeDetailPageItem(current),
          };
          this.detailVisible = true;
        }
      });
    },
    changeDetailPageItem(index) {
      this.detail(null, index);
    },
  },
};
</script>
