<template>
  <div class="help">
    <a-tabs v-model="active" @change="callback">
      <a-tab-pane key="1" tab="状态列表">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="API接口">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="基本配置">
        <router-view v-if="active == '3'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "help",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/help/statusCode") {
          this.active = "1";
        } else if (newVal == "/help/apiPort") {
          this.active = "2";
        } else if (newVal == "/help/system") {
          this.active = "3";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/help/statusCode") return;
          this.$router.replace("/help/statusCode");
        }
        if (newVal === "2") {
          if (this.$route.path == "/help/apiPort") return;
          this.$router.replace("/help/apiPort");
        }
        if (newVal === "3") {
          if (this.$route.path == "/help/system") return;
          this.$router.replace("/help/system");
        }
      },
    },
  },
};
</script>
