<template>
  <div class="simulateData">
    <div class="simulateData-search">
      <div class="simulateData-search-1">
        任务编号：
        <a-input v-model="planId" placeholder="请输入任务编号" allowClear />
      </div>
      <div class="simulateData-search-1">
        消息id：
        <a-input v-model="msgId" placeholder="请输入消息id" allowClear />
      </div>
      <div class="simulateData-search-1">
        主状态：
        <a-input v-model="stat" placeholder="请输入主状态" allowClear />
      </div>
    </div>
    <div class="simulateData-search">
      <div class="simulateData-search-1">
        是否推送：
        <a-select
          v-model="pushed"
          placeholder="请选择是否推送"
          class="select-tag"
          allowClear
        >
          <a-select-option
            v-for="i in pushedList"
            :key="i.key"
            :value="i.key"
            >{{ i.value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="simulateData-search-1">
        推送时间起止：
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['开始时间', '结束时间']"
          @change="onChange"
        />
      </div>
    </div>
    <div class="simulateData-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="display: inline-block; margin-right: 20px"></i>
      <a-button type="primary" @click="exportClick">导出</a-button>
    </div>
    <!-- 模拟数据详情列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(codeColumns)"
      :columns="codeColumns"
      :dataSource="codeTableData"
      :pagination="codePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="pushed" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 推送 </a-tag>
        <a-tag v-if="!text" color="red"> 未推送 </a-tag>
      </span>
    </a-table>
  </div>
</template>
<script>
export default {
  name: "simulateData",
  data() {
    return {
      codeColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "任务编号",
          ellipsis: true,
          dataIndex: "planId",
          width: 100,
        },
        {
          title: "消息id",
          ellipsis: true,
          dataIndex: "msgId",
          width: 200,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "phone",
          width: 150,
        },
        {
          title: "主状态",
          ellipsis: true,
          dataIndex: "stat",
          width: 100,
        },
        {
          title: "是否推送",
          ellipsis: true,
          dataIndex: "pushed",
          scopedSlots: { customRender: "pushed" },
          width: 100,
        },
        {
          title: "推送时间",
          ellipsis: true,
          dataIndex: "pushDateTime",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      codeTableData: [],
      codePagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      pushedList: [
        {
          key: "true",
          value: "推送",
        },
        {
          key: "false",
          value: "未推送",
        },
      ],
      dateString: [],
      planId: "",
      msgId: "",
      stat: "",
      pushed: undefined,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        planId: this.planId,
        msgId: this.msgId,
        stat: this.stat,
        pushed: this.pushed,
        pushDateTimeStart: this.dateString[0],
        pushDateTimeEnd: this.dateString[1],
      };
      this.getConfigPage(data);
    },
    // 查询模拟数据详情列表
    getConfigPage(data) {
      this.loadingTable = true;
      this.$http
        .get("/statusMock/detailPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.keyIndex = index + 1;
            });
            this.codeTableData = list;
            this.codePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.codeChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    codeChangePage(index) {
      this.inquire(index);
    },
    onChange(value, dateString) {
      this.dateString = dateString;
    },
    // 点击导出
    exportClick() {
      let data = {
        planId: this.planId,
        msgId: this.msgId,
        stat: this.stat,
        pushed: this.pushed,
        pushDateTimeStart: this.dateString[0],
        pushDateTimeEnd: this.dateString[1],
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$path + "/statusMock/exportDetailData?" + url;
      window.location.href = href;
    },
  },
};
</script>
<style lang="scss" scoped>
.simulateData {
  .simulateData-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .simulateData-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .simulateData-search-button {
    margin-bottom: 20px;
  }
}
</style>
