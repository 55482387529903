<template>
  <div class="signatureManagement">
    <div class="signatureManagement-search">
      <div class="signatureManagement-search-1">
        用户名称:
        <a-input v-model="userName" placeholder="请输入用户名称" allowClear />
      </div>
      <div class="signatureManagement-search-1">
        sign:
        <a-input v-model="sign" placeholder="请输入sign" allowClear />
      </div>
      <div class="signatureManagement-search-1">
        accessKey:
        <a-input v-model="accessKey" placeholder="请输入accessKey" allowClear />
      </div>
    </div>
    <div class="signatureManagement-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="display: inline-block; margin-right: 20px"></i>
      <a-button type="primary" @click="addSign">新增</a-button>
    </div>
    <!-- 签名列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="state" slot-scope="text, record" style="width: 100%">
        <div class="switch-button">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="record.enabled"
            @change="signOnChange($event, record)"
          />
        </div>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a @click="deleteClick(record)" style="color: red">删除</a>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增签名"
      v-model="addSignShow"
      :maskClosable="false"
      :afterClose="addSignClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
          label="用户"
          required
        >
          <a-select
            v-model="userId"
            placeholder="请选择用户"
            class="select-tag"
            option-filter-prop="children"
            :filterOption="false"
            showSearch
            allowClear
            @search="getUserList"
          >
            <a-select-option
              v-for="i in userNameList"
              :key="i.userLoginName"
              :value="i.userLoginName"
              >{{ i.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
          label="账号"
          required
        >
          <a-select
            v-model="userSmsAccountId"
            placeholder="请选择账号"
            class="select-tag"
            :disabled="userId == undefined"
            showSearch
            @search="fetchUserSms"
            :filterOption="false"
            @focus="getUserSmsAccount"
          >
            <a-select-option
              v-for="i in userLoginNameList"
              :key="i.id"
              :value="i.id"
              >{{ i.alias }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
          label="签名"
          required
        >
          <a-select
            v-model="signName"
            placeholder="请选择签名"
            class="select-tag"
            showSearch
            :filterOption="false"
            @search="fetchUserSignName"
          >
            <a-select-option
              v-for="i in signNameList"
              :key="i.signId"
              :value="i.signId"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addSignShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addSign_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "userSignatureManagement",
  data() {
    return {
      userLoginNameSms: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userName",
          width: 300,
        },
        {
          title: "sign",
          ellipsis: true,
          dataIndex: "sign",
          width: 150,
        },
        {
          title: "账号名称",
          ellipsis: true,
          dataIndex: "userSmsAccountName",
          width: 150,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      addSignShow: false,
      signName: undefined,
      userId: undefined,
      userNameList: [],
      signNameList: [],
      platformList: [],
      platform: "",
      currentPage: 1,
      pageSize: 10,
      userName: "",
      sign: "",
      userSmsAccountId: undefined,
      userLoginNameList: [],
      accessKey: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.sign + "】吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/sms/signRelation/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
      });
    },
    // 签名启用or禁用
    signOnChange(e, val) {
      let title = "启用";
      if (e) {
        title = "启用";
      } else {
        title = "禁用";
      }
      this.$confirm({
        title: "提示",
        content: "是否" + title + "【" + val.sign + "】签名？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .json_post("/sms/signRelation/update/enable", {
                id: val.id,
                enabled: e,
              })
              .then((res) => {
                if (res.result === 200) {
                  if (e) {
                    this.$message.success("启用成功");
                  } else {
                    this.$message.success("禁用成功");
                  }
                  this.signInquire();
                  resolve();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          }).catch(() => {});
        },
        onCancel: () => {
          for (let i in this.signTableData) {
            if (this.signTableData[i].id === val.id) {
              this.$set(this.signTableData[i], "enabled", !e);
            }
          }
        },
      });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.userName,
        sign: this.sign,
        accessKey: this.accessKey,
      };
      this.getSignList(data);
    },
    // 点击新增
    addSign() {
      this.addSignShow = true;
      this.getUserList();
      // this.getPlatformList();
      // 查询签名
      this.getSignNameList();
    },
    // 确认新增
    addSign_submit() {
      let data = {
        userLoginName: this.userId,
        signId: this.signName,
        userSmsAccountId: this.userSmsAccountId,
      };
      if (!data.userLoginName) {
        this.$message.warning("请选择用户");
        return;
      }
      if (!data.signId) {
        this.$message.warning("请选择签名");
        return;
      }
      this.$http.json_post("/sms/assignSignToUser", data).then((res) => {
        if (res.result === 200) {
          this.addSignShow = false;
          this.inquire(1);
          this.$message.success("新增成功");
        }
      });
    },
    // 关闭新增
    addSignClose() {
      this.platformList = [];
      this.signName = undefined;
      // this.platformId = undefined;
      this.userSmsAccountId = undefined;
      this.userId = undefined;
      this.platform = "";
    },
    // 查询用户签名管理列表
    getSignList(data) {
      this.loadingTable = true;
      this.$http
        .post("/sms/signRelation/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.signChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 签名分页
    signChangePage(index) {
      this.inquire(index);
    },
    // 查询平台
    getPlatformList() {
      this.platformList = [];
      this.$http.get("/sms/platformListALL").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.platformList.push({
              label: item.name,
              value: item.accessKey,
              code: item.code,
              id: item.id,
            });
          });
        }
      });
    },
    // 账号下拉框
    // userSmsChange(val) {
    //   this.getSignNameList(val);
    // },
    fetchUserSignName(name) {
      this.getSignNameList(name);
    },
    // 查询账号对应签名
    getSignNameList(name) {
      this.signNameList = [];
      let data = {
        pageNo: 1,
        pageSize: 20,
        fuzzyName: name,
        // userSmsAccountId: val,
      };
      // this.$http.get("/sms/userSignInfo/list", data).then((res) => {
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            this.signNameList.push({
              sign: item.sign,
              signId: item.id,
            });
          });
        }
      });
    },
    // 查询用户列表
    getUserList(userName) {
      let data = {
        pageNo: 1,
        pageSize: 200,
        userName,
      };
      this.userNameList = [];
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.userNameList = list;
        }
      });
    },
    userIdChange(val) {
      this.userLoginNameSms = val;
      this.getUserSmsAccount();
      this.userSmsAccountId = undefined;
    },
    fetchUserSms(fuzzyName) {
      this.getUserSmsAccount(fuzzyName);
    },
    // 查询账号
    getUserSmsAccount(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userLoginName: this.userLoginNameSms,
        fuzzyName: fuzzyName,
      };
      this.$http
        .get("/userPlatformRelation/list", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.userLoginNameList = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.signatureManagement {
  .signatureManagement-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .signatureManagement-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .signatureManagement-search-button {
    margin-bottom: 20px;
  }
}
</style>
