<template>
  <div class="sendSignCheck">
    <div class="reminder">
      该功能主要针对 短信记录 ->提交发送 ->发送检查 复选框生效
      <br />
      A签名关联了BC签名，发过过BC的客户都会在发送A时 (过滤功能打开)被过滤掉
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        签名：
        <a-select
          v-model="searchCondition.sign"
          placeholder="请选择签名"
          allowClear
          showSearch
          :filterOption="false"
          @search="getSignSelectList"
          @blur="getSignSelectList()"
          @change="query(1)"
        >
          <a-select-option
            v-for="i in signSelectList"
            :key="i.id"
            :value="i.sign"
            >{{ i.sign }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        关联签名：
        <a-select
          v-model="searchCondition.relationSignList"
          placeholder="请选择关联签名"
          mode="multiple"
          allowClear
          showSearch
          :filterOption="false"
          @search="getSignSelectList"
          @blur="getSignSelectList()"
          @change="query(1)"
        >
          <a-select-option
            v-for="i in signSelectList"
            :key="i.id"
            :value="i.sign"
            >{{ i.sign }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          v-model="text"
          @change="enabledChange($event, record)"
        />
      </span>
      <span slot="relationSignList" slot-scope="text, record">
        <template v-for="item in record.relationSignList">
          <a-tag color="green" style="margin-top: 5px"> {{ item }} </a-tag>
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="签名:" required>
          <a-select
            v-model="updateData.sign"
            placeholder="请选择签名"
            showSearch
            :filterOption="false"
            @search="getSignSelectList"
            @blur="getSignSelectList()"
          >
            <a-select-option
              v-for="i in signSelectList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="关联签名:" required>
          <a-select
            v-model="updateData.relationSignList"
            placeholder="请选择关联签名"
            mode="multiple"
            showSearch
            :filterOption="false"
            @search="getSignSelectList"
            @blur="getSignSelectList()"
          >
            <a-select-option
              v-for="i in signSelectList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="updateData.enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "sendSignCheck",
  data() {
    return {
      title: "",
      searchCondition: {
        sign: undefined,
        relationSignList: [],
      },
      groupId: "",
      updateData: {},
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "签名",
          ellipsis: true,
          dataIndex: "sign",
          width: 150,
        },
        {
          title: "关联签名",
          ellipsis: true,
          dataIndex: "relationSignList",
          scopedSlots: { customRender: "relationSignList" },
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      signSelectList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getSignSelectList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ...this.searchCondition,
      };

      data.relationSigns = data.relationSignList.join(",");
      delete data.relationSignList;

      this.loadingTable = true;
      this.$http
        .get("/sendSignCheck/sendSignCheckPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.relationSignList = [];
              if (item.relationSigns) {
                item.relationSignList = item.relationSigns.split(",");
              }
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    getSignSelectList(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        fuzzyName,
      };
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        if (res.result == 200) {
          this.signSelectList = res.data.records;
        }
      });
    },
    enabledChange(enabled, record) {
      this.title = "修改";
      this.groupId = record.id;
      this.updateData = {
        sign: record.sign,
        relationSignList: record.relationSigns.split(","),
        enabled,
      };
      this.add_submit();
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.updateData = {
        sign: val.sign,
        relationSignList: val.relationSigns.split(","),
        enabled: val.enabled,
      };
      this.addKeyVisible = true;
    },
    // 新增
    add() {
      this.title = "新增";
      this.updateData = {
        sign: undefined,
        relationSignList: [],
        enabled: true,
      };
      this.addKeyVisible = true;
    },
    add_submit() {
      this.loading = true;
      let data = {
        ...this.updateData,
      };
      data.relationSigns = data.relationSignList.join(",");
      delete data.relationSignList;

      if (this.title === "新增") {
        this.$http
          .json_post("/sendSignCheck/addSendSignCheck", data)
          .then((res) => {
            if (res.result === 200) {
              this.loading = false;
              this.$message.success("添加成功");
              this.addKeyVisible = false;
              this.query(1);
            }
          });
      } else {
        data.id = this.groupId;
        this.$http
          .json_post("/sendSignCheck/editSendSignCheck", data)
          .then((res) => {
            if (res.result === 200) {
              this.loading = false;
              this.$message.success("修改成功");
              this.addKeyVisible = false;
              this.query();
            }
          });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/sendSignCheck/deleteSendSignCheck", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
  },
};
</script>
