<template>
  <div class="myPagination">
    <div class="paginationClass">
      <a-button
        type="link"
        style="margin-right: 10px; cursor: default; color: #606266"
        >共 {{ total }} 条</a-button
      >
      <a-button
        style="margin-right: 10px"
        @click="inquireOne"
        :disabled="disabledPrevBtn"
        >第一页</a-button
      >
      <a-button
        icon="left"
        @click="handlePageNo('prev')"
        :disabled="disabledPrevBtn"
        >上一页</a-button
      >
      <div class="currentPage-class">{{ pageNo }}</div>
      <a-button @click="handlePageNo('next')" :disabled="disabledNextBtn"
        >下一页
        <a-icon type="right" />
      </a-button>
      <a-dropdown style="margin-left: 10px">
        <a-button class="ant-dropdown-link">
          {{ pageSize }}条/页<a-icon type="down" />
        </a-button>
        <a-menu slot="overlay">
          <a-menu-item @click="handlePageSize(10)">10条/页</a-menu-item>
          <a-menu-item @click="handlePageSize(20)">20条/页</a-menu-item>
          <a-menu-item @click="handlePageSize(50)">50条/页</a-menu-item>
          <a-menu-item @click="handlePageSize(100)">100条/页</a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "myPagination",
  props: {
    tableList: {
      // 表格数据
      type: Array,
      default: () => {
        return [];
      },
    },
    summaryShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    pageNo: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    // 禁用上一页
    disabledPrevBtn() {
      if (this.pageNo === 1) {
        return true;
      }
      return false;
    },
    // 禁用下一页
    disabledNextBtn() {
      let total = this.tableList.length;
      if (this.summaryShow) {
        total -= 1;
      }
      if (total < this.pageSize) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 查询第一页
    inquireOne() {
      this.$emit("changePage", 1);
    },
    // 翻页
    handlePageNo(key) {
      let pageNo = this.pageNo;
      if (key === "prev") {
        pageNo--;
      }
      if (key === "next") {
        pageNo++;
      }
      this.$emit("changePage", pageNo);
    },
    // 条数
    handlePageSize(pageSize) {
      this.$emit("changePage", 1, pageSize);
    },
  },
};
</script>
<style scoped lang="scss">
.myPagination {
  float: right;
  text-align: right;

  .paginationClass {
    position: relative;
    display: flex;
    .currentPage-class {
      height: 28px;
      line-height: 28px;
      font-size: 13px;
      color: #606266;
      padding: 0 8px;
    }
  }
}
</style>
