<template>
  <div class="platform">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="平台账号管理">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="组合账号管理">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="平台管理">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="状态管理">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="平台空号列表">
        <router-view name="three" v-if="active == '5'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "platform",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/platform/platformManagement") {
          this.active = "1";
        } else if (newVal == "/platform/groupManagement") {
          this.active = "2";
        } else if (newVal == "/platform/platList") {
          this.active = "3";
        } else if (newVal == "/platform/smsStatusInfo") {
          this.active = "4";
        } else if (newVal == "/platform/platformBlackNumber") {
          this.active = "5";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/platform/platformManagement") return;
          this.$router.replace("/platform/platformManagement");
        }
        if (newVal === "2") {
          if (this.$route.path == "/platform/groupManagement") return;
          this.$router.replace("/platform/groupManagement");
        }
        if (newVal === "3") {
          if (this.$route.path == "/platform/platList") return;
          this.$router.replace("/platform/platList");
        }
        if (newVal === "4") {
          if (this.$route.path == "/platform/smsStatusInfo") return;
          this.$router.replace("/platform/smsStatusInfo");
        }
        if (newVal === "5") {
          if (this.$route.path == "/platform/platformBlackNumber") return;
          this.$router.replace("/platform/platformBlackNumber");
        }
      },
    },
  },
};
</script>
