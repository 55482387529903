<template>
  <div class="sendMonitoring">
    <!-- 卡片数据 -->
    <a-row :gutter="16">
      <a-col :span="8">
        <div class="card-text">
          <div class="card-text-title">
            <img src="../../public/fs.png" />
            <span>今日群发</span>
          </div>
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                发送成功：{{ batchMessageSuccessCount }}
              </template>
              <span class="label_success">{{ batchMessageSuccessCount }}</span>
            </a-tooltip>
          </span>
          <a-divider type="vertical" />
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                发送失败：{{ batchMessageFailureCount }}
              </template>
              <span class="label_error">{{ batchMessageFailureCount }}</span>
            </a-tooltip>
          </span>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-text">
          <div class="card-text-title">
            <img src="../../public/gj.png" />
            <span>今日接口</span>
          </div>
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                发送成功：{{ hangupMessageSuccessCount }}
              </template>
              <span class="label_success">{{ hangupMessageSuccessCount }}</span>
            </a-tooltip>
          </span>
          <a-divider type="vertical" />
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                发送失败：{{ hangupMessageFailureCount }}
              </template>
              <span class="label_error">{{ hangupMessageFailureCount }}</span>
            </a-tooltip>
          </span>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-text">
          <div class="card-text-title">
            <img src="../../public/hz.png" />
            <span>当前回执</span>
          </div>
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                回执速度(10s)：{{ callback10Tps }}
              </template>
              <span class="label_back_10">{{ callback10Tps }}</span>
            </a-tooltip>
          </span>
          <a-divider type="vertical" />
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                回执速度(60s)：{{ callback60Tps }}
              </template>
              <span class="label_success">{{ callback60Tps }}</span>
            </a-tooltip>
          </span>
          <a-divider type="vertical" />
          <span class="card-text-money">
            <a-tooltip>
              <template slot="title">
                积压数：{{ callbackQueueCount }}
              </template>
              <span class="label_back">{{ callbackQueueCount }}</span>
            </a-tooltip>
          </span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: "sendMonitoring",
  data() {
    return {
      batchMessageFailureCount: 0,
      batchMessageSuccessCount: 0,
      callbackQueueCount: 0,
      callback10Tps: 0,
      callback60Tps: 0,
      hangupMessageFailureCount: 0,
      hangupMessageSuccessCount: 0,
      chart: null,
      chartData: [],
      timer: null,
    };
  },
  mounted() {
    this.getDashboard();
  },
  methods: {
    // 查询数据
    getDashboard() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.$http.get("/report/dashboard").then((res) => {
        if (res.result === 200) {
          this.batchMessageFailureCount = res.data.batchMessageFailureCount;
          this.batchMessageSuccessCount = res.data.batchMessageSuccessCount;
          this.callbackQueueCount = res.data.backlog;
          this.callback10Tps = res.data.averageTp10;
          this.callback60Tps = res.data.averageTp60;
          this.hangupMessageFailureCount = res.data.hangupMessageFailureCount;
          this.hangupMessageSuccessCount = res.data.hangupMessageSuccessCount;
          this.timer = setInterval(() => {
            this.getDashboard();
          }, 3000);
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.sendMonitoring {
  margin-bottom: 20px;
}
.card-text {
  .card-text-title {
    font-size: 16px;
    display: inline-block;
    margin-right: 20px;
    line-height: 27px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .card-text-money {
    font-size: 20px;
    .label_success {
      color: #67c23a;
    }
    .label_error {
      color: #f56c6c;
    }
    .label_back {
      color: #e6a23c;
    }
    .label_back_10 {
      color: #409eff;
    }
  }
  &::deep().ant-divider-vertical {
    height: 18px;
  }
}
</style>
