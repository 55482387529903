<template>
  <div class="statusCode">
    <!-- 状态码列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(codeColumns)"
      :columns="codeColumns"
      :dataSource="codeTableData"
      :pagination="false"
      :loading="loadingTable"
      size="small"
    >
    </a-table>
  </div>
</template>
<script>
export default {
  name: "statusCode",
  data() {
    return {
      codeColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "keyIndex",
          width: 60,
        },
        {
          title: "错误编码",
          ellipsis: true,
          dataIndex: "key",
          width: 400,
        },
        {
          title: "错误说明",
          ellipsis: true,
          dataIndex: "value",
          width: 400,
        },
      ],
      codeTableData: [],
      codePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
      };
      this.getCodeList(data);
    },
    // 查询状态码列表
    getCodeList(data) {
      this.loadingTable = true;
      this.$http
        .get("/systemConfig/errorCodeList")
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item, index) => {
              item.keyIndex = index + 1;
            });
            this.codeTableData = list;
            // this.codePagination = {
            //   showQuickJumper: true,
            //   showTotal: () => `共${res.data.total}条`,
            //   pageSize: res.data.pageSize,
            //   current: res.data.pageNo,
            //   total: res.data.total,
            //   onChange: (current) => this.codeChangePage(current),
            // };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    codeChangePage(index) {
      this.inquire(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.statusCode {
  .statusCode-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .statusCode-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .statusCode-search-button {
    margin-bottom: 20px;
  }
}
</style>
