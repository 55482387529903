<template>
  <div class="system">
    <a-tabs v-model="active">
      <template v-for="(tab, index) in tabList">
        <a-tab-pane :key="index" :tab="tab">
          <template v-for="item in dataList">
            <div class="content" v-show="item.type == tab">
              <div class="desc">
                {{ item.desc }}
                <a-tooltip v-if="item.tip">
                  <template slot="title">
                    {{ item.tip }}
                  </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </div>
              <div class="value">
                <a-switch
                  checked-children="开"
                  un-checked-children="关"
                  v-model="item.switchValue"
                  v-if="item.componentType == 'switch'"
                  @change="switchOnChange(item)"
                />
                <a-textarea
                  v-else-if="item.componentType == 'textarea'"
                  v-model="item.value"
                  placeholder="请输入内容"
                  :rows="4"
                ></a-textarea>
                <a-select
                  v-else-if="item.componentType == 'select'"
                  v-model="item.value"
                  placeholder="请选择类型"
                  class="select-tag"
                  mode="multiple"
                >
                  <a-select-option
                    v-for="(i, index) in typeList"
                    :key="index"
                    :value="i.code"
                    >{{ i.desc }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-else
                  v-model="item.value"
                  placeholder="请输入内容"
                  allowClear
                />
              </div>
              <div v-if="item.componentType != 'switch'">
                <a-button type="primary" @click="switchOnChange(item)">
                  确定
                </a-button>
              </div>
            </div>
          </template>
        </a-tab-pane>
      </template>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "system",
  data() {
    return {
      dataList: [],
      typeList: [],
      tabList: [],
      active: 0,
    };
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      this.$http.get("/blacklist/typeList").then((res) => {
        if (res.result === 200) {
          this.typeList = res.data;
          this.getConfigList();
        }
      });
    },
    getConfigList() {
      this.$http.get("/systemConfig/getConfigList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          let set = new Set();
          list.forEach((item) => {
            set.add(item.type);
            if (item.value == "false") {
              item.switchValue = false;
              item.componentType = "switch";
            } else if (item.value == "true") {
              item.switchValue = true;
              item.componentType = "switch";
            } else if (item.code == "COMPLAINT_MATCH_LIST") {
              item.componentType = "textarea";
            } else if (item.code == "DEFAULT_BLACK_LIST_SOURCE") {
              item.componentType = "select";
              if (item.value) {
                item.value = item.value.split(",");
              } else {
                item.value = [];
              }
            } else {
              item.componentType = "input";
            }
          });
          this.tabList = [...set];
          this.dataList = list;
        }
      });
    },
    switchOnChange(record) {
      let data = {
        code: record.code,
        value: record.value,
      };
      if (record.componentType == "switch") {
        if (record.switchValue == true) {
          data.value = "true";
        } else if (record.switchValue == false) {
          data.value = "false";
        }
      } else if (record.componentType == "select") {
        data.value = record.value.join(",");
      }
      this.$http.json_post("/systemConfig/updateConfig", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.getConfigList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.system {
  padding: 20px;
  font-size: 16px;
  background-color: #fff;
  .content {
    display: flex;
    margin-bottom: 20px;
    .desc {
      width: 200px;
    }
    .value {
      width: 200px;
      margin-right: 50px;
    }
  }
}
</style>
