<template>
  <div class="signatureBlack">
    <div class="searchCondition">
      <div class="searchConditionItem">
        签名：
        <a-select
          v-model="sign"
          placeholder="请选择签名"
          allowClear
          style="width: 240px"
          showSearch
          :filterOption="false"
          @search="getSignList"
          @change="inquire(1)"
          @blur="getSignList()"
        >
          <a-select-option v-for="i in signList" :key="i.id" :value="i.sign">{{
            i.sign
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        customerCode：
        <a-input
          v-model="customerCode"
          placeholder="请输入customerCode"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查询</a-button>
        <a-button type="primary" @click="upload()">上传</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :loading="loadingTable"
      size="small"
    >
    </a-table>
    <a-modal
      title="上传"
      v-model="uploadShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="签名:">
          <a-select
            v-model="signAdd"
            placeholder="请选择签名"
            allowClear
            style="width: 240px"
            showSearch
            :filterOption="false"
            @search="getSignList"
            @blur="getSignList()"
          >
            <a-select-option
              v-for="i in signList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
        <a-form-item label="文件:">
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="upload_submit"
            :loading="loading"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "signatureBlack",
  data() {
    return {
      columns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "sign",
          ellipsis: true,
          dataIndex: "sign",
          width: 300,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 130,
        },
        {
          title: "customerCode",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 130,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      tableData: [],
      pagination: {},
      loadingTable: false,
      currentPage: 1,
      sign: undefined,
      customerCode: "",
      signAdd: undefined,
      remark: "",
      uploadShow: false,
      fileList: [],
      signList: [],
      loading: false,
    };
  },
  mounted() {
    this.inquire(1);
    this.getSignList();
  },
  methods: {
    getSignList(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        fuzzyName,
      };
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        this.loadingTable = false;
        if (res.result === 200) {
          this.signList = res.data.records;
        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    upload() {
      this.signAdd = undefined;
      this.remark = "";
      this.fileList.splice(0);
      this.uploadShow = true;
    },
    upload_submit() {
      this.loading = true;
      let data = {
        sign: this.signAdd,
        remark: this.remark,
        file: this.fileList[0],
      };
      this.$http.files("/sms/codeSignBlackList/upload", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("上传成功");
          this.loading = false;
          this.uploadShow = false;
          this.inquire(1);
        }
      });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        sign: this.sign,
        customerCode: this.customerCode,
      };
      this.getBlackList(data);
    },
    getBlackList(data) {
      this.loadingTable = true;
      this.$http
        .get("/sms/codeSignBlackList/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.tableData = list;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.signatureBlack {
  .signatureBlack-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .signatureBlack-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .signatureBlack-search-button {
    margin-bottom: 20px;
  }
}
</style>
