<template>
  <div class="sendAnalysis">
    <sendMonitoring></sendMonitoring>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="长链发送分析">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="批次发送分析">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="省份发送分析">
        <router-view v-if="active == '3'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="通道发送分析">
        <router-view v-if="active == '4'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="用户通道发送分析">
        <router-view v-if="active == '5'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="近七日发送分析">
        <router-view v-if="active == '6'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import sendMonitoring from "./sendMonitoring";
export default {
  name: "sendAnalysis",
  components: {
    sendMonitoring,
  },
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/sendAnalysis/urlReportList") {
          this.active = "1";
        } else if (newVal == "/sendAnalysis/batchNumberReport") {
          this.active = "2";
        } else if (newVal == "/sendAnalysis/provinceAnalysis") {
          this.active = "3";
        } else if (newVal == "/sendAnalysis/passagewayAnalysis") {
          this.active = "4";
        } else if (newVal == "/sendAnalysis/userAnalysis") {
          this.active = "5";
        } else if (newVal == "/sendAnalysis/lastSevenDaysData") {
          this.active = "6";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/sendAnalysis/urlReportList") return;
          this.$router.replace("/sendAnalysis/urlReportList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/sendAnalysis/batchNumberReport") return;
          this.$router.replace("/sendAnalysis/batchNumberReport");
        }
        if (newVal === "3") {
          if (this.$route.path == "/sendAnalysis/provinceAnalysis") return;
          this.$router.replace("/sendAnalysis/provinceAnalysis");
        }
        if (newVal === "4") {
          if (this.$route.path == "/sendAnalysis/passagewayAnalysis") return;
          this.$router.replace("/sendAnalysis/passagewayAnalysis");
        }
        if (newVal === "5") {
          if (this.$route.path == "/sendAnalysis/userAnalysis") return;
          this.$router.replace("/sendAnalysis/userAnalysis");
        }
        if (newVal === "6") {
          if (this.$route.path == "/sendAnalysis/lastSevenDaysData") return;
          this.$router.replace("/sendAnalysis/lastSevenDaysData");
        }
      },
    },
  },
};
</script>
