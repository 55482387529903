<template>
  <div style="height: 100%">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider
        :trigger="null"
        collapsible
        v-model="collapsed"
        :collapsedWidth="60"
        :style="{
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          left: 0,
          zIndex: '1000',
        }"
      >
        <a-menu
          theme="dark"
          mode="inline"
          :selectedKeys="getUrl()"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          @click="selectClick"
          style="width: 100%"
        >
          <a-sub-menu v-for="item in list" :key="item.url">
            <span slot="title">
              <a-icon :type="item.iconType" />
              <span>{{ item.name }}</span>
            </span>
            <a-menu-item v-for="menu in item.children" :key="menu.url">
              {{ menu.name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-header
        :class="{ isheader: !collapsed, onheader: collapsed }"
        style="
          background: #fff;
          padding: 0 30px 0 0;
          position: fixed;
          width: 100%;
          z-index: 1;
        "
      >
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <span class="title-theme">短信系统</span>
        <div class="lockStatus" v-if="showLockButton">
          <a-tag :color="statusColor">{{ lockStatus }}</a-tag>
          <a-button type="link" @click="modifySmsLockStatus">
            {{ lockStatus == "未锁定" ? "锁定" : "解除锁定" }}
          </a-button>
        </div>
        <a-dropdown
          style="
            display: inline-block;
            height: 100%;
            vertical-align: initial;
            float: right;
            min-width: 100px;
          "
        >
          <span
            style="
              cursor: pointer;
              border-left: 1px solid #d3d3d3;
              padding-left: 10px;
              font-size: 16px;
            "
          >
            <a-avatar
              class="avatar"
              shape="circle"
              style="background-color: transparent"
            >
              <template slot="icon">
                <icon-font type="icon-Avatar"></icon-font>
              </template>
            </a-avatar>
            <span>{{ loginNickName }}</span>
          </span>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                href="javascript:void(0)"
                @click="updata()"
                style="
                  display: inline-block;
                  text-align: left;
                  width: 100%;
                  padding: 0;
                  margin: 0;
                "
              >
                <a-icon type="edit" />
                <span>修改密码</span>
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:void(0)"
                @click="logOut()"
                style="
                  display: inline-block;
                  text-align: left;
                  width: 100%;
                  padding: 0;
                  margin: 0;
                "
              >
                <a-icon type="poweroff" />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <a-layout
        :class="{ isContent: !collapsed, onContent: collapsed }"
        :style="{ padding: '24px 16px' }"
      >
        <router-view
          name="second"
          class="content_view"
          v-if="isComponent"
          :roleCode="roleCode"
          :userLoginName="userLoginName"
          :auditable="auditable"
          :confusion="confusion"
          :mockConfig="mockConfig"
        />
      </a-layout>
    </a-layout>
    <!-- 修改密码 -->
    <a-modal
      title="修改密码"
      v-model="visibleUpdate"
      :maskClosable="false"
      :afterClose="closeUpdate"
    >
      <a-form>
        <a-form-item
          label="原密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="orgPassword" placeholder="请输入原密码" />
        </a-form-item>
        <a-form-item
          label="新密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="newPassword" placeholder="请输入新密码" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleUpdate = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="updateOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      isComponent: false,
      collapsed: false,
      openKeys: [],
      rootSubmenuKeys: ["1", "2", "3", "4"],
      menuList: [],
      list: [],
      loginAvatarUrl: "",
      loginNickName: "",
      orgPassword: "",
      newPassword: "",
      visibleUpdate: false,
      loading: false,
      url: "",
      roleCode: "",
      userLoginName: "",
      confusion: false,
      short_url: false,
      mockConfig: false,
      auditable: false,
      lockStatus: "未锁定",
      statusColor: "green",
      showLockButton: true,
    };
  },
  mounted() {
    this.getMenuCode();
    this.unMatchRemind();
    this.unConfirmRemind();
    this.getSmsLockStatus();
  },
  methods: {
    getSmsLockStatus() {
      this.$http.get("/sms/smsLockStatus").then((res) => {
        if (res.result == 200) {
          switch (res.data) {
            case "UNLOCK":
              this.statusColor = "green";
              this.lockStatus = "未锁定";
              break;
            case "LOCKING":
              this.statusColor = "orange";
              this.lockStatus = "锁定中";
              break;
            case "LOCKED":
              this.statusColor = "red";
              this.lockStatus = "已锁定";
              break;
          }
        }
      });
    },
    modifySmsLockStatus() {
      this.$http.post("/sms/modifySmsLockStatus").then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getSmsLockStatus();
        }
      });
    },
    jump(key) {
      if (key == "unMatchRemind") {
        this.$router.push({
          path: "/platform/smsStatusInfo",
          query: { sendStatus: "UN_MATCH" },
        });
      } else {
        this.$router.push({
          path: "/channelQualityAnalysis/blankNumberPage",
          query: { needConfirm: true },
        });
      }
      this.$notification.close(key);
    },
    unConfirmRemind() {
      this.$http.get("/blankNumber/unConfirmRemind").then((res) => {
        if (res.result == 200) {
          if (res.data.show) {
            this.$notification.warning({
              message: "通知",
              key: "unConfirmRemind",
              duration: 10,
              description: () => (
                <div>
                  存在 {res.data.count} 个空号检测未确认，点击
                  <a
                    href="javascript:;"
                    style="margin:0 2px"
                    onClick={() => this.jump("unConfirmRemind")}
                  >
                    这里
                  </a>
                  前往确认
                </div>
              ),
            });
          }
        }
      });
    },
    unMatchRemind() {
      this.$http.get("/sms/smsStatusInfo/unMatchRemind").then((res) => {
        if (res.result == 200) {
          if (res.data.show) {
            this.$notification.warning({
              message: "通知",
              key: "unMatchRemind",
              duration: 10,
              description: () => (
                <div>
                  存在{res.data.count}个通道状态未匹配，点击
                  <a
                    href="javascript:;"
                    style="margin:0 2px"
                    onClick={() => this.jump("unMatchRemind")}
                  >
                    这里
                  </a>
                  前往修改
                </div>
              ),
            });
          }
        }
      });
    },
    getMenuCode() {
      this.$http.get("/menu/menuCode").then((res) => {
        if (res.result === 200) {
          if (res.data.indexOf("SHORT_URL") > -1) {
            this.short_url = true;
          } else {
            this.short_url = false;
          }
          if (res.data.indexOf("CONFUSION") > -1) {
            this.confusion = true;
          } else {
            this.confusion = false;
          }
          if (res.data.indexOf("MOCK_CONFIG") > -1) {
            this.mockConfig = true;
          } else {
            this.mockConfig = false;
          }
          this.login();
          this.checkAuthPriviledge();
        }
      });
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    login() {
      //用户登录
      this.$http.get("/user/info").then((res) => {
        if (res.result == 200) {
          this.isComponent = true;
          this.loginNickName = res.data.userName;
          this.roleCode = res.data.roleCode;
          this.userLoginName = res.data.userLoginName;
          this.auditable = res.data.auditable;
          if (res.data.roleCode === "SUPER_MANAGER") {
            this.list = [
              {
                name: "短信",
                url: "1",
                iconType: "mail",
                children: [
                  {
                    name: "短信营销",
                    url: "/smsmarketing",
                    iconType: "laptop",
                  },
                  {
                    name: "短链",
                    url: "/shortLinks",
                    iconType: "laptop",
                  },
                  {
                    name: "短信记录",
                    url: "/smmSmarketingRecords",
                    iconType: "laptop",
                  },
                ],
              },
              {
                name: "资源管理",
                url: "2",
                iconType: "shopping",
                children: [
                  {
                    name: "用户列表",
                    url: "/userNameList",
                    iconType: "laptop",
                  },
                  {
                    name: "签名管理",
                    url: "/signature",
                    iconType: "laptop",
                  },
                  {
                    name: "平台管理",
                    url: "/platform",
                    iconType: "laptop",
                  },
                  {
                    name: "发送管理",
                    url: "/sendAdmin",
                    iconType: "laptop",
                  },
                  {
                    name: "发送限制",
                    url: "/sendLimit",
                    iconType: "laptop",
                  },
                  {
                    name: "帮助",
                    url: "/help",
                    iconType: "laptop",
                  },
                ],
              },
              {
                name: "报表分析",
                url: "3",
                iconType: "bar-chart",
                children: [
                  {
                    name: "发送分析",
                    url: "/sendAnalysis",
                    iconType: "laptop",
                  },
                  {
                    name: "通道质量分析",
                    url: "/channelQualityAnalysis",
                    iconType: "laptop",
                  },
                  {
                    name: "成本分析",
                    url: "/costAnalysis",
                    iconType: "laptop",
                  },
                ],
              },
            ];
          } else {
            this.list = [
              {
                name: "短信",
                url: "1",
                iconType: "mail",
                children: [
                  {
                    name: "短信营销",
                    url: "/smsmarketing",
                    iconType: "laptop",
                  },
                  {
                    name: "短链",
                    url: "/shortLinks",
                    iconType: "laptop",
                  },
                  {
                    name: "短信记录",
                    url: "/smmSmarketingRecords",
                    iconType: "laptop",
                  },
                  {
                    name: "上行记录",
                    url: "/userReportList",
                    iconType: "laptop",
                  },
                ],
              },
            ];
          }
          if (!this.short_url) {
            this.list[0].children.splice(1, 1);
          }

          // 打开当前导航栏
          let url = window.location.href;
          url = url.split("/");
          this.list.forEach((item) => {
            item.children.forEach((menu) => {
              if (menu.url === "/" + url[3]) {
                this.openKeys.push(item.url);
              }
            });
          });
        }
      });
    },
    checkAuthPriviledge() {
      this.$http.get("/checkAuthPriviledge").then((res) => {
        this.showLockButton = res.showLockButton;
      });
    },
    // 跳转页面
    gotoPage() {
      let href = window.location.href;
      let url = "";
      href = href.split("/");
      let path = sessionStorage.getItem("pathHref");
      if (path) {
        this.url = path;
        this.menuList[0] = path;
        this.$router.push(path);
      } else {
        this.menuList[0] = this.list[0].children[0].en;
        this.$router.push(this.menuList[0]);
      }
      if (href[3]) {
        if (href[3] === "homeManage") {
          url = "/memberList";
          sessionStorage.setItem("pathHref", url);
        } else {
          url = "/" + href[3];
          sessionStorage.setItem("pathHref", url);
        }
      }
    },
    // 获取导航地址
    getUrl() {
      let url = window.location.href;
      url = url.split("/");
      let selectedKeys = ["/" + url[3]];
      return selectedKeys;
    },
    //导航选择
    selectClick({ item, key, keyPath }) {
      let url = window.location.href;
      url = url.split("/");
      if (key === "/" + url[3]) {
        // 禁止重复跳转相同路由
        return;
      }
      this.$router.push(key);
    },
    //点击退出登录
    logOut() {
      this.$http.post("/user/logout").then((res) => {
        if (res.result == 200) {
          this.$router.push("/");
        }
      });
    },
    // //点击修改密码
    updata() {
      this.visibleUpdate = true;
    },
    // //确认修改密码
    updateOk() {
      this.loading = true;
      let data = {
        oldPassword: this.orgPassword,
        newPassword: this.newPassword,
      };
      this.$http
        .json_post("/user/resetPassword", data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.visibleUpdate = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // //关闭修改密码
    closeUpdate() {
      this.orgPassword = "";
      this.newPassword = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-menu-inline-collapsed .ant-menu-submenu ::v-deep .ant-menu-submenu-title {
  padding: 0 20px !important;
}
</style>

<style>
.ant-popover-buttons {
  width: 115px;
  height: 24px;
  margin: 0 auto;
}
.ant-popover-buttons button {
  float: right;
}
.isheader {
  padding-left: 200px !important;
}
.onheader {
  padding-left: 80px !important;
  transition: all 0.3s;
}
.isContent {
  margin-left: 200px;
  margin-top: 64px;
}
.onContent {
  margin-left: 80px;
  margin-top: 64px;
  transition: all 0.3s;
}
.ant-table-body {
  overflow: auto;
}
.ant-table th {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
  position: relative;
}
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo .logo-image {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo span {
  color: #ffffff;
}
.title-theme {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}
.avatar {
  margin-right: 10px !important;
}
.logo .logo-title {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px;
}

.content_view {
  background-color: #fff;
  padding: 15px 20px;
}
.lockStatus {
  position: absolute;
  right: 150px;
  top: 0;
}
</style>
