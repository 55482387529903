<template>
  <div class="sendAdmin">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="用户通道列表">
        <router-view
          v-if="active == '1'"
          name="three"
          :mockConfig="mockConfig"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="待审核列表">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="预提交列表">
        <router-view v-if="active == '3'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="失败重提分组">
        <router-view v-if="active == '4'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="短链动态替换列表">
        <router-view v-if="active == '5'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="推送记录">
        <router-view v-if="active == '6'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="7" tab="上行记录">
        <router-view v-if="active == '7'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="8" tab="模拟状态" v-if="mockConfig">
        <router-view v-if="active == '8'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="9" tab="内容模板策略" v-if="mockConfig">
        <router-view v-if="active == '9'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "sendAdmin",
  props: ["mockConfig"],
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/sendAdmin/sendUser") {
          this.active = "1";
        } else if (newVal == "/sendAdmin/toAudit") {
          this.active = "2";
        } else if (newVal == "/sendAdmin/preSubmitAudit") {
          this.active = "3";
        } else if (
          newVal.indexOf("/sendAdmin/failureChannelRetryGroup") !== -1
        ) {
          this.active = "4";
        } else if (newVal == "/sendAdmin/shortReplace") {
          this.active = "5";
        } else if (newVal == "/sendAdmin/messagePush") {
          this.active = "6";
        } else if (newVal == "/sendAdmin/aisleUp") {
          this.active = "7";
        } else if (newVal.indexOf("/sendAdmin/simulatedState") !== -1) {
          this.active = "8";
        } else if (newVal == "/sendAdmin/contentStrategy") {
          this.active = "9";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/sendAdmin/sendUser") return;
          this.$router.replace("/sendAdmin/sendUser");
        }
        if (newVal === "2") {
          if (this.$route.path == "/sendAdmin/toAudit") return;
          this.$router.replace("/sendAdmin/toAudit");
        }
        if (newVal === "3") {
          if (this.$route.path == "/sendAdmin/preSubmitAudit") return;
          this.$router.replace("/sendAdmin/preSubmitAudit");
        }
        if (newVal === "4") {
          if (
            this.$route.path.indexOf("/sendAdmin/failureChannelRetryGroup") !==
            -1
          )
            return;
          this.$router.replace("/sendAdmin/failureChannelRetryGroup");
        }
        if (newVal === "5") {
          if (this.$route.path == "/sendAdmin/shortReplace") return;
          this.$router.replace("/sendAdmin/shortReplace");
        }
        if (newVal === "6") {
          if (this.$route.path == "/sendAdmin/messagePush") return;
          this.$router.replace("/sendAdmin/messagePush");
        }
        if (newVal === "7") {
          if (this.$route.path == "/sendAdmin/aisleUp") return;
          this.$router.replace("/sendAdmin/aisleUp");
        }
        if (newVal === "8") {
          if (this.$route.path.indexOf("/sendAdmin/simulatedState") !== -1)
            return;
          this.$router.replace("/sendAdmin/simulatedState");
        }
        if (newVal === "9") {
          if (this.$route.path == "/sendAdmin/contentStrategy") return;
          this.$router.replace("/sendAdmin/contentStrategy");
        }
      },
    },
  },
};
</script>
