<template>
  <div class="channelSmsAccountPriceChangeLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        通道名称：
        <a-input
          v-model="channelName"
          @keyup.enter="query(1)"
          placeholder="请输入通道名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        开始时间：
        <a-date-picker
          v-model="startDate"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
        ></a-date-picker>
      </div>
      <div class="searchConditionItem">
        结束时间：
        <a-date-picker
          v-model="endDate"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
        ></a-date-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)" size="small">修改</a-button>
      </span>
    </a-table>

    <a-modal
      title="修改成本"
      v-model="editShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="价格:" required>
          <a-input
            v-model="priceData.cmccCost"
            placeholder="请输入价格"
            allowClear
          />
        </a-form-item>
        <a-form-item label="时间范围:" required>
          <a-range-picker
            v-model="priceData.time"
            showTime
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editShow = false">关闭</a-button>
          <a-button key="submit" type="primary" @click="editSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "channelSmsAccountPriceChangeLog",
  data() {
    return {
      channelName: "",
      startDate: "",
      endDate: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "id" },
        },
        {
          title: "通道名称",
          dataIndex: "channelName",
          ellipsis: true,
          width: 200,
        },
        {
          title: "移动成本",
          dataIndex: "cmccCost",
          ellipsis: true,
          width: 80,
        },
        {
          title: "开始时间",
          dataIndex: "startDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "结束时间",
          dataIndex: "endDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "修改时间",
          dataIndex: "changeDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      editShow: false,
      priceData: {},
      priceId: "",
      channelSmsAccountId: "",
      model: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        channelName: this.channelName,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.$http
        .get("/channelSmsAccountCostLog/pageHistoryPrice", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    edit(record) {
      this.priceId = record.id;
      this.priceData = {
        cmccCost: record.cmccCost,
        time: [record.startDate, record.endDate],
      };
      this.channelSmsAccountId = record.channelSmsAccountId;
      this.editShow = true;
    },
    editSubmit() {
      let data = { ...this.priceData };
      data.id = this.priceId;
      data.channelSmsAccountId = this.channelSmsAccountId;
      data.startDate = data.time[0];
      data.endDate = data.time[1];
      delete data.time;
      this.$http
        .json_post("/channelSmsAccountCostLog/updateHistoryPrice", data)
        .then((res) => {
          if (res.result === 200) {
            this.editShow = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
    },
  },
};
</script>
