<template>
  <div class="analyse">
    <!-- 折线图分析 -->
    <a-row class="pie-card">
      <a-col :span="24">
        <a-card hoverable>
          <div class="card-title">
            <span class="title">访问统计</span>
            <div class="card-picker">
              <a-range-picker
                :disabledDate="disabledDate"
                :defaultValue="[
                  moment().startOf('month'),
                  moment().endOf('day'),
                ]"
                :ranges="{
                  今天: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('day')],
                }"
                format="YYYY-MM-DD"
                @change="onChangeTime"
              />
            </div>
          </div>
          <div id="mountNode"></div>
        </a-card>
      </a-col>
    </a-row>
    <!-- 饼图分析 -->
    <a-row class="pie-card">
      <a-col :span="24">
        <a-card hoverable>
          <div class="card-title">
            <span class="title">访问设备统计</span>
            <div class="card-picker">
              <a-range-picker
                :disabledDate="disabledDate"
                :defaultValue="[
                  moment().startOf('month'),
                  moment().endOf('day'),
                ]"
                :ranges="{
                  今天: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('day')],
                }"
                format="YYYY-MM-DD"
                @change="onChangeTime"
              />
            </div>
          </div>
          <div id="pieChart"></div>
        </a-card>
      </a-col>
    </a-row>
    <!-- 地图分析 -->
    <!-- <a-row class="pie-card">
      <a-col :span="24">
        <a-card hoverable>
          <div id="mapChart"></div>
        </a-card>
      </a-col>
    </a-row>-->
  </div>
</template>
<script>
import moment from "moment";
import G2 from "@antv/g2";
import { DataSet } from "@antv/data-set";
export default {
  name: "analyse",
  data() {
    return {
      moment,
      data: [
        { day: "2018/8/1", 独立IP: 55, 访问总量PV: 56, 独立UV: 45 },
        { day: "2018/8/2", 独立IP: 43, 访问总量PV: 35, 独立UV: 46 },
        { day: "2018/8/3", 独立IP: 47, 访问总量PV: 65, 独立UV: 52 },
        { day: "2018/8/4", 独立IP: 49, 访问总量PV: 25, 独立UV: 53 },
        { day: "2018/8/5", 独立IP: 41, 访问总量PV: 45, 独立UV: 42 },
        { day: "2018/8/6", 独立IP: 37, 访问总量PV: 55, 独立UV: 31 },
        { day: "2018/8/7", 独立IP: 39, 访问总量PV: 16, 独立UV: 12 },
        { day: "2018/8/8", 独立IP: 31, 访问总量PV: 30, 独立UV: 3 },
        { day: "2018/8/9", 独立IP: 29, 访问总量PV: 10, 独立UV: 47 },
        { day: "2018/8/10", 独立IP: 25, 访问总量PV: 13, 独立UV: 9 },
        { day: "2018/8/11", 独立IP: 12, 访问总量PV: 17, 独立UV: 23 },
        { day: "2018/8/12", 独立IP: 0, 访问总量PV: 1, 独立UV: 2 },
      ],
      chartData: [],
      chart: null,
    };
  },
  mounted() {
    this.drawingChart();
    this.drawingPieChart();
  },
  methods: {
    // 绘制折线图表
    drawingChart() {
      // Step 1: 创建 Chart 对象
      this.chart = new G2.Chart({
        container: "mountNode", // 指定图表容器 ID
        // width : 600, // 指定图表宽度
        forceFit: true,
        height: 450, // 指定图表高度
        padding: [60, 60, 90, 60],
      });
      let ds = new DataSet();
      let dv = ds.createView().source(this.data);
      dv.transform({
        type: "fold",
        fields: ["访问总量PV", "独立UV", "独立IP"], // 展开字段集
        key: "city", // key字段
        value: "temperature", // value字段
      });
      // Step 2: 载入数据源
      this.chart.source(dv, {
        day: {
          tickCount: 30,
        },
      });
      // Step 3：创建图形语法，绘制折线图，由 day 和 value 两个属性决定图形位置，day 映射至 x 轴，value 映射至 y 轴
      this.chart.tooltip({
        crosshairs: {
          type: "line",
        },
      });
      this.chart.axis("temperature", {
        label: {
          formatter: function formatter(val) {
            return val;
          },
        },
      });
      this.chart
        .line()
        .position("day*temperature")
        .color("city")
        .shape("smooth");
      this.chart
        .point()
        .position("day*temperature")
        .color("city")
        .size(4)
        .shape("circle")
        .style({
          stroke: "#fff",
          lineWidth: 1,
        });
      // Step 4: 渲染图表
      this.chart.render();
    },
    // 绘制饼图
    drawingPieChart() {
      const data = [
        { item: "华为手机", count: 40, percent: 0.4 },
        { item: "小米手机", count: 21, percent: 0.21 },
        { item: "vivo手机", count: 17, percent: 0.17 },
        { item: "Android", count: 13, percent: 0.13 },
        { item: "Windows7", count: 9, percent: 0.09 },
      ];
      const chart = new G2.Chart({
        container: "pieChart",
        forceFit: true,
        height: 450,
      });
      chart.source(data, {
        percent: {
          formatter: (val) => {
            val = val * 100 + "%";
            return val;
          },
        },
      });
      chart.coord("theta", {
        radius: 0.75,
      });
      chart.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      chart
        .intervalStack()
        .position("percent")
        .color("item")
        .label("percent", {
          formatter: (val, item) => {
            return item.point.item + ": " + val;
          },
        })
        .tooltip("item*percent", (item, percent) => {
          percent = percent * 100 + "%";
          return {
            name: item,
            value: percent,
          };
        })
        .style({
          lineWidth: 1,
          stroke: "#fff",
        });
      chart.render();
    },
    // 地图绘制
    drawingMapChart() {},
    // 选择时间
    onChangeTime(dates, dateStrings) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    },
    // 不可选日期
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
  },
};
</script>
<style scoped lang="scss">
.analyse {
  width: 100%;
  height: 100%;
  .pie-card {
    margin-bottom: 20px;
    .card-title {
      position: relative;
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: bolder;
        line-height: 32px;
        display: block;
        color: #848a9f;
      }
      .card-picker {
        position: absolute;
        width: 300px;
        right: 0px;
        top: 0px;
      }
    }
  }
}
</style>
