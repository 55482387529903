<template>
  <div class="clickData">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="userLoginName"
          placeholder="请选择用户"
          allowClear
          style="width: 240px"
          showSearch
          :filterOption="false"
          @search="getUserList"
          @change="getActiveCodeList()"
        >
          <a-select-option
            v-for="i in userList"
            :key="i.id"
            :value="i.userLoginName"
            >{{ i.userName }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        批次编号：
        <a-select
          v-model="activityCodes"
          placeholder="请选择批次编号"
          allowClear
          style="width: 240px"
          showSearch
          :filterOption="false"
          @search="getActiveCodeList"
          @change="inquire(1)"
          :disabled="!userLoginName"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in activityCodeList"
            :key="index"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        创建时间：
        <a-date-picker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          v-model="startTime"
          placeholder="开始日期"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="结束日期"
          v-model="endTime"
        />
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input v-model="longUrl" placeholder="请输入长链" />
      </div>
      <div class="searchButton">
        <a-button
          type="primary"
          @click="inquire(1)"
          :disabled="activityCodes.length == 0"
          >查 询</a-button
        >
        <a-button
          type="primary"
          @click="Export()"
          :disabled="activityCodes.length == 0"
          >导出</a-button
        >
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :loading="loadingTable"
    >
    </a-table>
  </div>
</template>
<script>
export default {
  name: "clickData",
  data() {
    return {
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "activityCode",
          width: 180,
        },
        {
          title: "手机号",
          ellipsis: true,
          dataIndex: "userCode",
          width: 100,
        },
        {
          title: "设备信息",
          ellipsis: true,
          dataIndex: "deviceInfo",
          width: 80,
        },
        {
          title: "访问ip",
          ellipsis: true,
          dataIndex: "ip",
          width: 120,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          width: 250,
        },
        {
          title: "shortCode",
          ellipsis: true,
          dataIndex: "shortCode",
          width: 100,
        },
        {
          title: "业务类型",
          ellipsis: true,
          dataIndex: "category",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      longUrl: "",
      userLoginName: undefined,
      activityCodes: [],
      startTime: null,
      endTime: null,
      userList: [],
      activityCodeList: [],
      pageNo: 1,
    };
  },
  computed: {
    gmtCreatedStart() {
      // 结束时间
      if (this.startTime) {
        return this.$common.transformTime(this.startTime);
      }
      return null;
    },
    gmtCreatedEnd() {
      // 开始时间
      if (this.endTime) {
        return this.$common.transformTime(this.endTime);
      }
      return null;
    },
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    Export() {
      let href =
        this.$path +
        `/shortUrl/exportShortUrlClickDetail?activityCodes=${this.activityCodes.join(
          ","
        )}&longUrl=${this.longUrl}&gmtCreatedStart=${
          this.gmtCreatedStart ? this.gmtCreatedStart : ""
        }&gmtCreatedEnd=${this.gmtCreatedEnd ? this.gmtCreatedEnd : ""}`;
      location.href = href;
    },
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      if (this.activityCodes.length == 0) {
        return;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        activityCodes: this.activityCodes.join(","),
        longUrl: this.longUrl,
        gmtCreatedStart: this.gmtCreatedStart,
        gmtCreatedEnd: this.gmtCreatedEnd,
      };
      this.$http.get("/shortUrl/shortUrlClickDetailList", data).then((res) => {
        if (res.result === 200) {
          if (res.data.data) {
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage(current),
            };
            this.tableData = res.data.data;
          }
        }
      });
    },
    currentPage(index) {
      this.inquire(index);
    },
    getUserList(userName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userName,
      };
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          this.userList = res.data.records;
          this.activityCodes = [];
        }
      });
    },
    getActiveCodeList(activeCode) {
      let data = {
        userLoginName: this.userLoginName,
        activeCode,
      };
      this.$http.get("/sms/activeCodeList", data).then((res) => {
        if (res.result === 200) {
          this.activityCodeList = res.data.activeCodeList;
        }
      });
    },
  },
};
</script>
