<template>
  <div class="longUrlDistributeInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>产品名称：</span>
        <a-input
          placeholder="请输入产品名称"
          v-model.trim="searchCondition.productName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>长链：</span>
        <a-input
          placeholder="请输入长链"
          v-model.trim="searchCondition.longUrl"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
        <a-dropdown :disabled="selectedRows.length == 0">
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="batchEnabledChange(true)">
                批量启用
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchEnabledChange(false)">
                批量禁用
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.longUrl }}</template>
          <a :href="record.longUrl" target="_blank" class="content">{{
            text
          }}</a>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.longUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.isCopyShow"
          >复制</a
        >
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="enabledChange(record, !text)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="产品名称:">
          <a-input
            v-model="updateData.productName"
            placeholder="请输入产品名称"
          />
        </a-form-item>
        <a-form-item label="长链:">
          <a-input v-model="updateData.longUrl" placeholder="请输入长链" />
        </a-form-item>
        <a-form-item label="追加后缀:">
          <a-input
            v-model="updateData.appendSuffix"
            placeholder="请输入追加后缀"
          />
        </a-form-item>
        <a-form-item label="上次使用日期:">
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="updateData.lastUsedDate"
            placeholder="请选择时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            v-model="updateData.enabled"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "longUrlDistributeInfo",
  data() {
    return {
      title: "",
      searchCondition: {},
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          ellipsis: true,
          width: 100,
        },
        {
          title: "长链",
          dataIndex: "longUrl",
          ellipsis: true,
          scopedSlots: { customRender: "longUrl" },
          customCell: this.mouseoverLongUrl,
          width: 400,
        },
        {
          title: "追加后缀",
          dataIndex: "appendSuffix",
          ellipsis: true,
          width: 100,
        },
        {
          title: "上次使用分组id",
          dataIndex: "lastShortUrlGroupId",
          ellipsis: true,
          width: 120,
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          ellipsis: true,
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "上次使用日期",
          dataIndex: "lastUsedDate",
          ellipsis: true,
          width: 170,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.searchCondition,
      };
      this.loadingTable = true;
      this.$http
        .get("/longUrlDistributeInfo/longUrlDistributeInfoPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.isCopyShow = false;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              pageSizeOptions: ["10", "20", "50", "100"],
              total: res.data.total,
              showSizeChanger: true,
              onChange: (current) => this.query(current),
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.query(1);
              },
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 鼠标移入长链列
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.tableDataSource.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.tableDataSource[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.tableDataSource.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.tableDataSource[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.addKeyVisible = true;
      this.updateData = {
        productName: val.productName,
        longUrl: val.longUrl,
        appendSuffix: val.appendSuffix,
        lastUsedDate: val.lastUsedDate,
        enabled: val.enabled,
      };
    },
    batchEnabledChange(enabled) {
      this.$axios
        .all(
          this.selectedRows.map((item) => {
            let data = {
              id: item.id,
              productName: item.productName,
              longUrl: item.longUrl,
              appendSuffix: item.appendSuffix,
              lastUsedDate: item.lastUsedDate,
              enabled,
            };
            return this.$http.json_post(
              "/longUrlDistributeInfo/editLongUrlDistributeInfo",
              data
            );
          })
        )
        .then((resArr) => {
          this.$message.success(enabled ? "启用" : "禁用" + "成功");
          this.selectedRowKeys.splice(0);
          this.selectedRows.splice(0);
          this.query();
        });
    },
    enabledChange(val, enabled) {
      let data = {
        id: val.id,
        productName: val.productName,
        longUrl: val.longUrl,
        appendSuffix: val.appendSuffix,
        lastUsedDate: val.lastUsedDate,
        enabled,
      };
      this.$http
        .json_post("/longUrlDistributeInfo/editLongUrlDistributeInfo", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success(data.enabled ? "启用" : "禁用" + "成功");
            this.query();
          }
        });
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.updateData = {
        productName: "",
        longUrl: "",
        appendSuffix: "",
        lastUsedDate: undefined,
        enabled: true,
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      if (this.title === "新增") {
        this.$http
          .json_post("/longUrlDistributeInfo/addLongUrlDistributeInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.groupId;
        this.$http
          .json_post("/longUrlDistributeInfo/editLongUrlDistributeInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("修改成功");
              this.query();
            }
          });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/longUrlDistributeInfo/deleteLongUrlDistributeInfo", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
  },
};
</script>
