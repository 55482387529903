<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        用户名称:
        <a-input v-model="userName" placeholder="请输入用户名称" allowClear />
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="addUser">新 增</a-button>
    </div>
    <!-- 用户列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(userColumns)"
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="enabled" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 已启用 </a-tag>
        <a-tag v-else color="red"> 已禁用 </a-tag>
      </span>
      <span slot="balance" slot-scope="text, record">
        <a href="javascript:;" @click="balanceClick(record.id, 1)">{{
          text
        }}</a>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="redirectToUser(record)">进入</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="editClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="bindClick(record)">绑定ip</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 添加用户 -->
    <a-modal
      title="添加用户"
      v-model="addUserShow"
      :maskClosable="false"
      :afterClose="addUserClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="用户登陆名称"
          required
        >
          <a-input
            v-model="userLoginName"
            placeholder="请输入用户登陆名称"
            allowClear
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="用户名称"
          required
        >
          <a-input
            v-model="userNameAdd"
            placeholder="请输入用户名称"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看用户 -->
    <a-modal
      :title="userTitle"
      v-model="editUserShow"
      :maskClosable="false"
      :afterClose="editUserClose"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="用户登陆名称" required>
          <a-input
            v-model="userLoginName"
            placeholder="请输入用户登陆名称"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item label="透支条数" required>
          <a-input
            v-model="editData.overdraft"
            placeholder="请输入透支条数"
            disabled
          />
        </a-form-item>
        <a-form-item label="最大透支条数" required>
          <a-input
            v-model="editData.maxOverdraft"
            placeholder="请输入最大透支条数"
          />
        </a-form-item>
        <a-form-item label="回调地址">
          <a-input
            v-model="editData.callbackUrl"
            placeholder="请输入回调地址"
            allowClear
          />
        </a-form-item>
        <a-form-item label="是否启用" required>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="editData.enabled"
          />
        </a-form-item>
        <a-form-item label="是否可审核" required>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="editData.auditable"
          />
        </a-form-item>
        <a-form-item label="自动充值">
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model="editData.autoRecharge"
          />
          <a-input
            style="width: 200px; margin-left: 20px"
            v-if="editData.autoRecharge"
            v-model="editData.autoRechargeCount"
            placeholder="请输入自动充值条数"
          />
        </a-form-item>
        <a-form-item label="手机号明文" required>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="editData.showPhone"
          />
        </a-form-item>
        <a-form-item v-if="!editData.showPhone" label="上行明文" required>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="editData.showPhoneForReply"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editUserShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="editUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 绑定ip -->
    <a-modal
      title="绑定ip"
      v-model="bindIpShow"
      :maskClosable="false"
      :afterClose="bindIpClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="用户登陆名称"
          required
        >
          <a-input
            v-model="userLoginName"
            placeholder="请输入用户登陆名称"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="ip"
          required
        >
          <a-textarea placeholder="一行一个ip" v-model="ip" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindIpShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="bindIp_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 短信余额 -->
    <a-modal
      title="短信余额"
      v-model="balanceShow"
      :maskClosable="false"
      :afterClose="balanceClose"
      class="actionSub-class"
      width="1000px"
    >
      <div class="smsStatusInfo-search-button" style="margin-bottom: 20px">
        <a-button type="primary" @click="editBalanceClick">短信充值</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(balanceColumns)"
        :columns="balanceColumns"
        :dataSource="balanceTableData"
        :pagination="balancePagination"
        :loading="loadingTable"
        size="small"
      >
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="redirectToUser(record)">进入</a>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="balanceShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 修改余额 -->
    <a-modal
      title="短信充值"
      v-model="editShow"
      :maskClosable="false"
      :afterClose="editClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="条数"
          required
        >
          <a-input v-model="editBalance" placeholder="请输入条数" allowClear />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="备注"
        >
          <a-input v-model="editRemark" placeholder="请输入备注" allowClear />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="edit_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "userNameList",
  data() {
    return {
      userColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userName",
          width: 200,
        },
        {
          title: "登录名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 120,
        },
        {
          title: "回调地址",
          ellipsis: true,
          dataIndex: "callbackUrl",
          width: 300,
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 70,
        },
        {
          title: "短信余额",
          ellipsis: true,
          dataIndex: "balance",
          scopedSlots: { customRender: "balance" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 220,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      loadingTable: false,
      currentPage: 1,
      currentPage1: 1,
      userName: "",
      addUserShow: false,
      userLoginName: "",
      userNameAdd: "",
      editData: {},
      userTitle: "",
      editUserShow: false,
      enabled: true,
      autoRecharge: false,
      balance: "",
      callbackUrl: "",
      overdraft: "",
      maxOverdraft: "",
      autoRechargeCount: "",
      editId: "",
      bindIpShow: false,
      ip: "",
      balanceShow: false,
      balanceColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短信条数",
          ellipsis: true,
          dataIndex: "changeNum",
          width: 120,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 300,
        },
        {
          title: "操作者",
          ellipsis: true,
          dataIndex: "creatorName",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      balanceTableData: [],
      balancePagination: {},
      balanceId: "",
      editShow: false,
      editBalance: "",
      editRemark: "",
      userLoginName1: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        userName: this.userName,
      };
      this.getUserList(data);
    },
    // 查询用户列表
    getUserList(data) {
      this.loadingTable = true;
      this.$http
        .json_post("/user/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 点击短信余额
    balanceClick(val, index) {
      this.balanceId = val;
      if (index) {
        this.currentPage1 = index;
      }
      this.balanceShow = true;
      let data = {
        pageNo: this.currentPage1,
        pageSize: 10,
        userId: val,
      };
      this.$http.get("/userBalanceChangeRecord/page", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.balanceTableData = list;
          this.balancePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_batch1(current),
          };
        }
      });
    },
    // 翻页
    currentPage_batch1(index) {
      this.balanceClick(this.balanceId, index);
    },
    balanceClose() {},
    balance_submit() {},
    // 修改余额
    editBalanceClick() {
      this.editShow = true;
    },
    editClose() {
      this.editBalance = "";
      this.editRemark = "";
    },
    edit_submit() {
      let data = {
        id: this.balanceId,
        balance: this.editBalance,
        remark: this.editRemark,
      };
      this.$http.json_post("/user/updateBalance", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.balanceClick(this.balanceId, 1);
          this.editShow = false;
          this.inquire(1);
        }
      });
    },
    // 点击进入
    redirectToUser(val) {
      let data = {
        userLoginName: val.userLoginName,
      };
      this.$http.get("/user/redirectToUser", data).then((res) => {
        if (res.result === 200) {
          this.$router.push({
            path: "/smsmarketing",
          });
          this.$router.go(0);
        }
      });
    },
    addUser() {
      this.addUserShow = true;
    },
    addUserClose() {
      this.userLoginName = "";
    },
    addUser_submit() {
      let data = {
        userLoginName: this.userLoginName,
        userName: this.userNameAdd,
      };
      this.$http.json_post("/user/add", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.inquire();
          this.addUserShow = false;
        }
      });
    },
    editClick(val) {
      this.userLoginName = val.userLoginName;
      this.userLoginName1 = val.userLoginName;
      this.editId = val.id;
      let data = {
        id: val.id,
      };
      this.$http.get("/user/getInfo", data).then((res) => {
        if (res.result === 200) {
          this.editData = res.data;
          this.editUserShow = true;
          this.userTitle = "修改用户";
        }
      });
    },
    editUser_submit() {
      let data = {
        id: this.editId,
        ...this.editData,
        userLoginName: this.userLoginName1,
      };
      this.$http.json_post("/user/editUser", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.inquire();
          this.editUserShow = false;
        }
      });
    },
    editUserClose() {
      this.userLoginName = "";
    },
    // 绑定ip
    bindClick(val) {
      this.userLoginName = val.userLoginName;
      this.bindIpShow = true;
      let data = {
        userLoginName: val.userLoginName,
      };
      this.$http.get("/userIpInfo/detail", data).then((res) => {
        if (res.result === 200) {
          this.ip = res.data.ip.split(",").join("\n");
        }
      });
    },
    bindIpClose() {
      this.userLoginName = "";
      this.ip = "";
    },
    bindIp_submit() {
      let data = {
        userLoginName: this.userLoginName,
        ip: this.ip,
      };
      this.$http.json_post("/user/bindIp", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("绑定成功");
          this.inquire();
          this.bindIpShow = false;
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.userName + "】吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.json_post("/user/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire();
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
