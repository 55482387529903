<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        客户编号:
        <a-input
          v-model="customerCode"
          placeholder="请输入客户编号"
          allowClear
        />
      </div>
      <div class="smsStatusInfo-search-1">
        表名:
        <a-select
          v-model="tableNum"
          placeholder="请选择表名"
          class="select-tag"
        >
          <a-select-option
            v-for="i in tableNumList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
    </div>
    <!-- 平台列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
  </div>
</template>
<script>
export default {
  name: "platList",
  data() {
    return {
      platTitle: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 120,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "platformName",
          width: 200,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          width: 100,
        },
        {
          title: "添加时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      customerCode: "",
      tableNum: "0",
      tableNumList: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "a",
          value: "a",
        },
        {
          label: "b",
          value: "b",
        },
        {
          label: "c",
          value: "c",
        },
        {
          label: "d",
          value: "d",
        },
        {
          label: "e",
          value: "e",
        },
        {
          label: "f",
          value: "f",
        },
      ],
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        customerCode: this.customerCode.trim(),
        tableNum: this.tableNum,
      };
      this.getPlatList(data);
    },
    getPlatList(data) {
      this.loadingTable = true;
      this.$http
        .get("/msgPlatformBlackListDetail/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            if (res.data) {
              this.signTableData = res.data.records;
            } else {
              this.signTableData.splice(0);
            }
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除客户编号【" + val.customerCode + "】的黑名单吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
            tableNum: this.tableNum,
          };
          this.$http
            .json_post("/msgPlatformBlackListDetail/delete", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire(1);
              }
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
