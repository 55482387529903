<template>
  <div class="sendLimit">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="最近发送成功列表">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="短信白名单">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="短信黑名单">
        <router-view v-if="active == '3'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="通道黑名单">
        <router-view v-if="active == '4'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="签名关联集合">
        <router-view v-if="active == '5'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="签名黑名单管理">
        <router-view v-if="active == '6'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="7" tab="发送检查配置">
        <router-view v-if="active == '7'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "sendLimit",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/sendLimit/recentSuccessSendInfo") {
          this.active = "1";
        } else if (newVal == "/sendLimit/whiteList") {
          this.active = "2";
        } else if (newVal == "/sendLimit/blackSms") {
          this.active = "3";
        } else if (newVal == "/sendLimit/blackList") {
          this.active = "4";
        } else if (newVal == "/sendLimit/signCorrelationSet") {
          this.active = "5";
        } else if (newVal == "/sendLimit/signatureBlack") {
          this.active = "6";
        } else if (newVal == "/sendLimit/sendSignCheck") {
          this.active = "7";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/sendLimit/recentSuccessSendInfo") return;
          this.$router.replace("/sendLimit/recentSuccessSendInfo");
        }
        if (newVal === "2") {
          if (this.$route.path == "/sendLimit/whiteList") return;
          this.$router.replace("/sendLimit/whiteList");
        }
        if (newVal === "3") {
          if (this.$route.path == "/sendLimit/blackSms") return;
          this.$router.replace("/sendLimit/blackSms");
        }
        if (newVal === "4") {
          if (this.$route.path == "/sendLimit/blackList") return;
          this.$router.replace("/sendLimit/blackList");
        }
        if (newVal === "5") {
          if (this.$route.path == "/sendLimit/signCorrelationSet") return;
          this.$router.replace("/sendLimit/signCorrelationSet");
        }
        if (newVal === "6") {
          if (this.$route.path == "/sendLimit/signatureBlack") return;
          this.$router.replace("/sendLimit/signatureBlack");
        }
        if (newVal === "7") {
          if (this.$route.path == "/sendLimit/sendSignCheck") return;
          this.$router.replace("/sendLimit/sendSignCheck");
        }
      },
    },
  },
};
</script>
