<template>
  <div class="passagewayAnalysis">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="userId"
          placeholder="请选择用户"
          allowClear
          showSearch
          :filterOption="filterOption"
          option-filter-prop="children"
        >
          <a-select-option v-for="i in userIdList" :key="i.id" :value="i.id">{{
            i.userName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        用户账号：
        <a-select
          v-model="userSmsAccountId"
          placeholder="请选择用户账号"
          allowClear
          showSearch
          :filterOption="false"
          option-filter-prop="children"
          @search="userSmsAccountIdSearch"
        >
          <a-select-option
            v-for="i in userSmsAccountIdList"
            :key="i.id"
            :value="i.id"
            >{{ i.accessKey }} | {{ i.alias }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        类型：
        <a-select
          v-model="sendStatusSelect"
          placeholder="请选择类型"
          allowClear
        >
          <a-select-option
            v-for="i in sendStatusList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button
          type="primary"
          @click="statisticsData()"
          :disabled="statisticsDataDisabled"
          >{{ statisticsTitle }}</a-button
        >
      </div>
    </div>
    <!-- 长链发送列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(urlReportColumns)"
      :columns="urlReportColumns"
      :dataSource="urlReportTableData"
      :pagination="false"
      :loading="loading"
      size="small"
    >
      <span slot="footer">
        <div class="pagination">
          <my-pagination
            :tableList="urlReportTableData"
            :total="total"
            :pageNo="urlReportTablePageNo"
            :pageSize="urlReportTablePageSize"
            summaryShow
            @changePage="urlReportChangePage"
          ></my-pagination>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from "moment";
import myPagination from "../tool/myPagination.vue";
export default {
  components: {
    myPagination,
  },
  name: "passagewayAnalysis",
  data() {
    return {
      gmtEndOpen: false,
      userId: undefined,
      userIdList: [],
      userSmsAccountId: undefined,
      userSmsAccountIdList: [],
      statisticsDataDisabled: true,
      statisticsTitle: "统计数据",
      sendStatusSelect: undefined,
      sendStatusList: [
        {
          value: "BATCH_SEND",
          label: "群发",
        },
        {
          value: "API_MESSAGE",
          label: "挂短",
        },
      ],
      loading: false,
      urlReportColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>合计</span>,
                attrs: {
                  colSpan: 2,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "dateSign",
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 100,
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 200,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeLabel",
          width: 100,
        },
        {
          title: "发送条数",
          ellipsis: true,
          dataIndex: "msgNums",
          width: 100,
        },
        {
          title: "成功条数",
          ellipsis: true,
          dataIndex: "successSendCount",
          width: 100,
        },
        {
          title: "失败条数",
          ellipsis: true,
          dataIndex: "failureSendCount",
          width: 100,
        },
        {
          title: "等待回执条数",
          ellipsis: true,
          dataIndex: "waitCallbackCount",
          width: 100,
        },
      ],
      urlReportTableData: [],
      total: 0,
      urlReportTablePageNo: 1,
      urlReportTablePageSize: 10,
      time: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      syncLoading: null,
      timerStatistics: null,
    };
  },
  mounted() {
    this.getPlatList();
    this.getChannelSmsAccount();
    this.inquire(1);
    this.getStatisticsDateUrlReportStatus();
    this.setIntervalStatistics();
  },
  methods: {
    userSmsAccountIdSearch(val) {
      this.getChannelSmsAccount(val);
    },
    // 搜索查询
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 查询用户账号别表
    getChannelSmsAccount(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        accessKeyOrName,
      };
      this.userSmsAccountIdList = [];
      this.$http.get("/userPlatformRelation/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.userSmsAccountIdList = list;
        }
      });
    },
    // 查询用户列表
    getPlatList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.userIdList = [];
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.userIdList = list;
        }
      });
    },
    // 刷新统计数据状态
    setIntervalStatistics() {
      if (this.timerStatistics) {
        clearInterval(this.timerStatistics);
        this.timerStatistics = null;
      }
      this.timerStatistics = setInterval(() => {
        this.getStatisticsDateUrlReportStatus();
      }, 5000);
    },
    // 查询统计数据状态
    getStatisticsDateUrlReportStatus() {
      this.$http
        .get("/msgSendCount/statisticsPlatformSendCountInfoStatus")
        .then((res) => {
          if (res.result === 200) {
            this.statisticsDataDisabled = res.data.statisticsIng;
            if (this.statisticsDataDisabled) {
              this.statisticsTitle = "数据统计中";
            } else {
              this.statisticsTitle = "统计数据";
            }
          }
        });
    },
    // 点击导出
    exportClick() {
      let data = {
        start: this.time[0],
        end: this.time[1],
        type: this.sendStatusSelect,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$path + "/report/export?" + url;
      window.location.href = href;
    },
    // 点击统计数据
    statisticsData() {
      if (!this.time[0] || !this.time[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      this.syncLoading = this.$syncLoading({
        message: "数据统计中...",
      });
      this.getStatisticsDateUrlReportStatus();
      this.setIntervalStatistics();
      this.$http
        .json_post("/msgSendCount/sync", {
          start: this.time[0],
          end: this.time[1],
          type: "user",
        })
        .then((res) => {
          this.syncLoading.close();
          if (res.result === 200) {
            this.inquire(1);
            this.$message.success("统计成功");
            this.getStatisticsDateUrlReportStatus();
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 点击查询
    inquire(pageNo, pageSize) {
      if (pageNo) {
        this.urlReportTablePageNo = pageNo;
      }
      if (pageSize) {
        this.urlReportTablePageSize = pageSize;
      }
      if (!this.time[0] || !this.time[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        msgType: this.sendStatusSelect,
        userId: this.userId,
        userSmsAccountId: this.userSmsAccountId,
        start: this.time[0],
        end: this.time[1],
        type: "user",
      };
      this.urlReportTableData = [];
      this.getUrlReportList(data);
    },
    async getUrlReportList(data) {
      this.loading = true;
      let summary = await this.getTotalCountInfo(data);
      this.$http
        .get("/msgSendCount/list", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.typeLabel = "";
              switch (item.msgType) {
                case "BATCH_SEND":
                  item.typeLabel = "群发";
                  break;
                case "API_MESSAGE":
                  item.typeLabel = "挂短";
                  break;
              }
            });
            if (summary) {
              summary.key = list.length + 1;
              list.push(summary);
            }
            this.urlReportTableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 翻页
    urlReportChangePage(pageNo, pageSize) {
      this.inquire(pageNo, pageSize);
    },
    // 查询分析合计
    getTotalCountInfo(data) {
      return new Promise((resolve, reject) => {
        this.$http.get("/msgSendCount/totalCountInfo", data).then((res) => {
          if (res.result === 200) {
            resolve(res.data);
          }
        });
      });
    },
  },
  beforeDestroy() {
    if (this.timerStatistics) {
      clearInterval(this.timerStatistics);
      this.timerStatistics = null;
    }
  },
};
</script>
