<template>
  <div class="smsStatusInfo">
    <a-tabs v-model="active" @change="callback">
      <a-tab-pane key="1" tab="平台状态">
        <div class="searchCondition">
          <div class="searchConditionItem">
            平台：
            <a-select
              v-model="platformSelect"
              placeholder="请选择平台"
              class="select-tag"
              allowClear
              showSearch
              :filterOption="filterOption"
            >
              <a-select-option
                v-for="i in platformList"
                :key="i.code"
                :value="i.code"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            系统状态：
            <a-select
              v-model="sendStatusSelect"
              placeholder="请选择系统状态"
              class="select-tag"
              allowClear
            >
              <a-select-option
                v-for="i in sendStatusList"
                :key="i.status"
                :value="i.status"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            真实状态：
            <a-input
              v-model="realStatusInput"
              placeholder="请输入真实状态"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            发送子状态：
            <a-input
              v-model="sendSubStatusInput"
              placeholder="请输入发送子状态"
              allowClear
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="inquire(1)">查 询</a-button>
            <a-button type="primary" @click="addSmsStatus">新增</a-button>
            <a-button type="primary" @click="download()">下载匹配模板</a-button>
            <a-button type="primary" @click="importUpdate()">导入更新</a-button>
          </div>
        </div>
        <!-- 签名列表 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(signColumns)"
          :columns="signColumns"
          :dataSource="signTableData"
          :pagination="signPagination"
          :loading="loadingTable"
          size="small"
        >
          <span slot="sendStatusLabel" slot-scope="text, record">
            {{ text }}
            <a
              href="javascript:;"
              v-if="text == '状态未匹配'"
              @click="modifyClick(record, 'editRealStatus')"
              >更改</a
            >
          </span>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyClick(record, 'default')"
              >修改</a
            >
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="系统状态">
        <div class="searchCondition">
          <div class="searchConditionItem">
            系统状态：
            <a-select
              v-model="systemStatusQuery"
              placeholder="请选择系统状态"
              class="select-tag"
              allowClear
            >
              <a-select-option
                v-for="i in sendStatusList"
                :key="i.status"
                :value="i.status"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            真实状态：
            <a-input
              v-model="realStatusInput"
              placeholder="请输入真实状态"
              allowClear
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="commonInquire(1)">查 询</a-button>
            <a-button type="primary" @click="addSmsStatus">新增</a-button>
            <a-button type="primary" @click="importUpdate()">导入更新</a-button>
          </div>
        </div>
        <!-- 签名列表 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(commonSignColumns)"
          :columns="commonSignColumns"
          :dataSource="commonSignTableData"
          :pagination="commonSignPagination"
          size="small"
        >
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyClick(record, 'default')"
              >修改</a
            >
            <a-divider type="vertical" />
            <a-popconfirm
              placement="right"
              okText="确认"
              cancelText="取消"
              @confirm="deleteClick(record)"
            >
              <template slot="title">是否确认删除{{ record.name }}</template>
              <a href="javascript:;" style="color: #ff4d4f">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>

    <!-- 新增 -->
    <a-modal
      :title="modalTitle"
      v-model="addSignShow"
      :maskClosable="false"
      :afterClose="addSignClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="平台"
          required
          v-if="editRealStatusType == 'default' && this.active == '1'"
        >
          <a-select
            v-model="accessKey"
            placeholder="请选择平台"
            :disabled="platformDis"
            class="select-tag"
          >
            <a-select-option
              v-for="i in platformList"
              :key="i.code"
              :value="i.code"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="真实状态"
          required
          v-if="editRealStatusType == 'default'"
        >
          <a-textarea
            v-if="modalTitle == '新增状态'"
            v-model="realStatus"
            placeholder="请输入真实状态"
            :rows="4"
            :disabled="realStatusDis"
          />
          <a-input
            v-else
            v-model="realStatus"
            placeholder="请输入真实状态"
            :disabled="realStatusDis"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="状态解释"
          v-if="this.active == '2'"
        >
          <a-input v-model="statusExplain" placeholder="请输入状态解释" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="系统状态"
          required
        >
          <a-select
            v-model="sendStatus"
            placeholder="请选择系统状态"
            class="select-tag"
          >
            <a-select-option
              v-for="i in sendStatusList"
              :key="i.status"
              :value="i.status"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="发送子状态"
          v-if="editRealStatusType == 'default' && this.active == '1'"
        >
          <a-input
            v-model="sendSubStatus"
            placeholder="请输入发送子状态"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addSignShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="modify_submit()"
            >确定</a-button
          >
          <a-button
            v-if="modalTitle == '新增状态'"
            type="primary"
            @click="modify_submit('clean')"
            >快速添加</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="导入更新"
      v-model="importUpdateShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件:">
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="importUpdateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="importUpdate_submit"
            :loading="loading"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "smsStatusInfo",
  data() {
    return {
      modalTitle: "新增状态",
      platformDis: false,
      realStatusDis: false,
      smsId: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "platformName",
          width: 150,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "realStatus",
          width: 120,
        },
        {
          title: "系统状态",
          ellipsis: true,
          dataIndex: "sendStatusLabel",
          scopedSlots: { customRender: "sendStatusLabel" },
          width: 120,
        },
        {
          title: "发送子状态",
          ellipsis: true,
          dataIndex: "sendSubStatus",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      commonSignColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "realStatus",
          width: 120,
        },
        {
          title: "系统状态",
          ellipsis: true,
          dataIndex: "systemStatusLabel",
          width: 120,
        },
        {
          title: "状态解释",
          ellipsis: true,
          dataIndex: "statusExplain",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      commonSignTableData: [],
      commonSignPagination: {},
      loadingTable: false,
      addSignShow: false,
      realStatus: "",
      sendSubStatus: "",
      sendStatus: undefined,
      systemStatusQuery: undefined,
      statusExplain: "",
      accessKey: undefined,
      platformList: [],
      currentPage: 1,
      pageSize: 10,
      commonCurrentPage: 1,
      commonPageSize: 10,
      sendStatusList: [],
      platformSelect: undefined,
      realStatusInput: "",
      sendSubStatusInput: "",
      sendStatusSelect: undefined,
      editRealStatusType: "default",
      fileList: [],
      importUpdateShow: false,
      active: "1",
      loading: false,
    };
  },
  mounted() {
    let sendStatus = this.$route.query.sendStatus;
    if (sendStatus) {
      this.sendStatusSelect = sendStatus;
    }
    this.getPlatformList();
  },
  methods: {
    callback(value) {
      let sendStatus = this.$route.query.sendStatus;
      if (sendStatus) {
        this.sendStatusSelect = sendStatus;
      } else {
        this.sendStatusSelect = undefined;
      }
      this.realStatusInput = "";
      if (value == "1") {
        this.getPlatformList();
      } else {
        this.commonInquire(1);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 点击修改
    modifyClick(val, type) {
      if (type == "default") {
        this.modalTitle = "修改状态";
      } else {
        this.modalTitle = "修改系统状态";
      }
      this.editRealStatusType = type;
      this.addSignShow = true;
      this.platformDis = true;
      this.realStatusDis = true;
      this.accessKey = val.platform;
      this.realStatus = val.realStatus;
      this.sendStatus = val.sendStatus;
      if (this.active == "2") {
        this.statusExplain = val.statusExplain;
        this.sendStatus = val.systemStatus;
      }
      this.smsId = val.id;
    },
    // 确定修改
    modify_submit(clean) {
      let data = {
        realStatus: this.realStatus,
      };
      if (this.active == "1") {
        data.platform = this.accessKey;
        data.sendStatus = this.sendStatus;
        data.sendSubStatus = this.sendSubStatus;
        if (!data.platform && this.editRealStatusType == "default") {
          this.$message.warning("请选择平台");
          return;
        }
      } else {
        data.statusExplain = this.statusExplain;
        data.systemStatus = this.sendStatus;
      }
      if (!data.realStatus && this.editRealStatusType == "default") {
        this.$message.warning("请输入真实状态");
        return;
      }
      if (
        (!data.sendStatus && this.active == "1") ||
        (!data.systemStatus && this.active == "2")
      ) {
        this.$message.warning("请选择系统状态");
        return;
      }
      if (this.modalTitle == "新增状态") {
        let path = "/sms/smsStatusInfo/add";
        if (this.active == "2") {
          path = "/commonSmsStatusInfo/addCommonStatus";
        }
        this.$http.json_post(path, data).then((res) => {
          if (res.result === 200) {
            if (this.active == "1") {
              this.inquire(1);
            } else {
              this.commonInquire(1);
            }
            console.log(clean);
            if (clean) {
              this.realStatus = "";
              this.sendSubStatus = "";
            } else {
              this.addSignShow = false;
            }
            this.$message.success("新增成功");
          }
        });
      } else {
        let path = "/sms/smsStatusInfo/update";
        if (this.active == "2") {
          path = "/commonSmsStatusInfo/editCommonStatus";
        }
        data.id = this.smsId;
        this.$http.json_post(path, data).then((res) => {
          if (res.result === 200) {
            this.addSignShow = false;
            if (this.active == "1") {
              this.inquire();
            } else {
              this.commonInquire();
            }
            this.$message.success("修改成功");
          }
        });
      }
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/commonSmsStatusInfo/deleteCommonStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.commonInquire();
          }
        });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: index || this.currentPage,
        pageSize: this.pageSize,
        platform: this.platformSelect,
        realStatus: this.realStatusInput,
        sendStatus: this.sendStatusSelect,
        sendSubStatus: this.sendSubStatusInput,
      };
      this.loadingTable = true;
      this.$http
        .get("/sms/smsStatusInfo/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.sendStatusLabel = item.sendStatus;
              this.sendStatusList.forEach((menu) => {
                if (menu.status === item.sendStatus) {
                  item.sendStatusLabel = menu.name;
                }
              });
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.signChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    commonInquire(index) {
      if (index) {
        this.commonCurrentPage = index;
      }
      let data = {
        pageNo: this.commonCurrentPage,
        pageSize: this.commonPageSize,
        realStatus: this.realStatusInput,
        systemStatus: this.systemStatusQuery,
      };
      this.$http
        .get("/commonSmsStatusInfo/commonStatusPage", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.systemStatusLabel = item.systemStatus;
              this.sendStatusList.forEach((menu) => {
                if (menu.status === item.systemStatus) {
                  item.systemStatusLabel = menu.name;
                }
              });
            });
            this.commonSignTableData = list;
            this.commonSignPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.commonPageSize = pageSize;
                this.commonInquire(1);
              },
              onChange: (current) => this.commonSignChangePage(current),
            };
          }
        });
    },
    commonSignChangePage(index) {
      this.commonInquire(index);
    },
    // 查询系统状态
    statusMapper() {
      this.$http.get("/smsStatus/list").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.sendStatusList = list;
          this.inquire(1);
        }
      });
    },
    // 点击新增
    addSmsStatus() {
      this.modalTitle = "新增状态";
      this.addSignShow = true;
      this.platformDis = false;
      this.realStatusDis = false;
    },
    // 关闭新增
    addSignClose() {
      this.realStatus = "";
      this.sendStatus = undefined;
      this.accessKey = undefined;
      this.editRealStatusType = "default";
    },
    download() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        platform: this.platformSelect,
        realStatus: this.realStatusInput,
        sendStatus: this.sendStatusSelect,
        sendSubStatus: this.sendSubStatusInput,
      };
      const entries = Object.entries(data);
      let urlParams = "/sms/exportUnMatch";
      entries.forEach((item, index) => {
        if (item[1]) {
          if (index == 0) {
            urlParams += "?";
          } else {
            urlParams += "&";
          }
          urlParams += item[0] + "=" + item[1];
        }
      });
      location.href = this.$path + urlParams;
    },
    importUpdate() {
      this.fileList.splice(0);
      this.importUpdateShow = true;
    },
    importUpdate_submit() {
      this.loading = true;
      let data = {
        file: this.fileList[0],
      };
      let path = "";
      if (this.active == "1") {
        path = "/sms/importMatch";
      } else {
        path = "/commonSmsStatusInfo/importCommonStatus";
      }
      this.$http.files(path, data).then((res) => {
        if (res.result == 200) {
          this.$message.success("导入更新成功");
          this.importUpdateShow = false;
          this.loading = false;
          if (this.active == "1") {
            this.inquire(1);
          } else {
            this.commonInquire(1);
          }
        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 管理分页
    signChangePage(index) {
      this.inquire(index);
    },
    // 查询平台
    getPlatformList() {
      this.platformList = [];
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http.get("/plat/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.statusMapper();
          list.forEach((item) => {
            this.platformList.push({
              name: item.name,
              code: item.code,
            });
          });
        }
      });
    },
  },
};
</script>
