<template>
  <a-modal
    title="短信模版"
    :visible="visible"
    :maskClosable="false"
    :width="width"
    :afterClose="modalClose"
    @cancel="modalCancel"
    :footer="null"
    class="smsContentTemplate"
  >
    <div class="btn-c">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <a-button style="margin-left: 20px" @click="smsContentClick('add')"
        >新增</a-button
      >
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :data-source="tableData"
      size="small"
      :pagination="tablePagination"
      :loading="tableLoading"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="smsContentClick('modify', record)"
          >修改</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" v-copy="record.content">复制短信</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="clickDelete(record)"
          style="color: #f56c6c"
          >删除</a
        >
      </span>
    </a-table>
    <a-modal
      :title="contentTitle"
      :visible="contentShow"
      :maskClosable="false"
      :width="700"
      @cancel="contentCancel"
      :afterClose="contentClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="内容："
        >
          <a-textarea
            v-model="content"
            :rows="8"
            placeholder="请输入短信内容"
          ></a-textarea>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="备注："
        >
          <a-textarea
            v-model="remark"
            :rows="4"
            placeholder="请输入备注"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="contentShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="content_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </a-modal>
</template>
<script>
export default {
  name: "smsContentTemplate",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      // 宽度
      type: Number,
      default: 700,
    },
  },
  watch: {
    show(val) {
      this.visible = val;
      if (val) {
        this.inquire(1);
      }
    },
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      visible: false,
      pageNo: 1,
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "短信内容",
          ellipsis: true,
          dataIndex: "content",
          width: 300,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 150,
        },
        {
          title: "添加时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 170,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      tablePagination: {},
      contentTitle: "",
      contentShow: false,
      content: "",
      remark: "",
      contentId: "",
      contentType: "",
    };
  },
  mounted() {},
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.getContentTemplateList(data);
    },
    // 查询模版数据
    getContentTemplateList(data) {
      this.tableLoading = true;
      this.$http
        .get("/contentTemplate/list", data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.tableData = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页
    currentPage(index) {
      this.inquire(index);
    },
    // 点击新增or修改
    smsContentClick(key, val) {
      if (key === "add") {
        this.contentTitle = "新增短信模版";
        this.contentType = "add";
      }
      if (key === "modify") {
        this.contentTitle = "修改短信模版";
        this.contentId = val.id;
        this.content = val.content;
        this.remark = val.remark;
        this.contentType = "modify";
      }
      this.contentShow = true;
    },
    // 确定新增or修改
    content_submit() {
      this.loading = true;
      if (this.contentType === "add") {
        let data = {
          content: this.content,
          remark: this.remark,
        };
        this.$http
          .json_post("/contentTemplate/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.contentShow = false;
              this.inquire(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.contentType === "modify") {
        let data = {
          id: this.contentId,
          content: this.content,
          remark: this.remark,
          deleted: false,
        };
        this.$http
          .json_post("/contentTemplate/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.contentShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增or修改
    contentClose() {
      this.content = "";
      this.remark = "";
    },
    // 关闭新增or修改
    contentCancel() {
      this.contentShow = false;
    },
    // 点击删除
    clickDelete(val) {
      this.$confirm({
        title: "提示",
        content: "确定删除该短信模版接？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
            content: val.content,
            remark: val.remark,
            deleted: true,
          };
          this.$http.json_post("/contentTemplate/update", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire();
            }
          });
        },
        onCancel: () => {},
        class: "test",
      });
    },
    // 关闭
    modalCancel() {
      this.visible = false;
    },
    // 关闭
    modalClose() {
      this.$emit("close", false);
    },
  },
};
</script>
<style scoped lang="scss">
.smsContentTemplate {
  .btn-c {
    margin-bottom: 20px;
  }
}
</style>
