<template>
  <div class="costAnalysis">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="批次成本分析">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="通道历史成本">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "costAnalysis",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/costAnalysis/batchCostAnalysis") {
          this.active = "1";
        }
        if (newVal == "/costAnalysis/historyPrice") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/costAnalysis/batchCostAnalysis") return;
          this.$router.replace("/costAnalysis/batchCostAnalysis");
        }
        if (newVal === "2") {
          if (this.$route.path == "/costAnalysis/historyPrice") return;
          this.$router.replace("/costAnalysis/historyPrice");
        }
      },
    },
  },
};
</script>
