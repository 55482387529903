<template>
  <div class="simulatedState">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="模拟策略列表">
        <router-view v-if="active == '1'" name="three" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="模拟数据详情">
        <router-view v-if="active == '2'" name="three" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "simulatedState",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/sendAdmin/simulatedState/simulateConfig") {
          this.active = "1";
        } else if (newVal == "/sendAdmin/simulatedState/simulateData") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/sendAdmin/simulatedState/simulateConfig")
            return;
          this.$router.replace("/sendAdmin/simulatedState/simulateConfig");
        }
        if (newVal === "2") {
          if (this.$route.path == "/sendAdmin/simulatedState/simulateData")
            return;
          this.$router.replace("/sendAdmin/simulatedState/simulateData");
        }
      },
    },
  },
};
</script>
