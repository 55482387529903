<template>
  <div class="passagewayAnalysis">
    <div class="searchCondition">
      <div class="searchConditionItem">
        通道账户名称：
        <a-input
          v-model="channelSmsAccountName"
          @keyup.enter="inquire(1)"
          placeholder="通道账户名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        平台：
        <a-select
          v-model="platformId"
          placeholder="请选择平台"
          allowClear
          showSearch
          :filterOption="false"
          option-filter-prop="children"
          @search="searchPlat"
          @blur="searchPlat()"
        >
          <a-select-option
            v-for="i in platformList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        平台账号：
        <a-select
          v-model="channelSmsAccountId"
          placeholder="请选择平台账号"
          allowClear
          showSearch
          :filterOption="false"
          option-filter-prop="children"
          @search="searchPlatId"
        >
          <a-select-option
            v-for="i in channelSmsAccountList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }} | {{ i.accessKey }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="statisticsData()">统计数据</a-button>
        <a-button type="primary" @click="exportData()">导出</a-button>
      </div>
    </div>
    <!-- 长链发送列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(urlReportColumns)"
      :columns="urlReportColumns"
      :dataSource="urlReportTableData"
      :pagination="false"
      :loading="loading"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="footer">
        <div class="pagination">
          <my-pagination
            :tableList="urlReportTableData"
            :total="total"
            :pageNo="urlReportTablePageNo"
            :pageSize="urlReportTablePageSize"
            summaryShow
            @changePage="changePage"
          ></my-pagination>
        </div>
      </span>
    </a-table>

    <a-modal
      title="统计数据"
      v-model="statisticsDataShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="日期:">
          <a-range-picker
            v-model="statisticsDataTime"
            :disabledDate="disabledDate"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="statisticsDataShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="statisticsDataSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import myPagination from "../tool/myPagination.vue";
export default {
  components: {
    myPagination,
  },
  name: "passagewayAnalysis",
  data() {
    return {
      channelSmsAccountName: "",
      platformId: undefined,
      platformList: [],
      channelSmsAccountId: undefined,
      channelSmsAccountList: [],
      loading: false,
      urlReportColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>合计</span>,
                attrs: {
                  colSpan: 4,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "date",
          align: "center",
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 120,
        },
        {
          title: "平台名称",

          ellipsis: true,
          dataIndex: "platformName",
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 120,
        },
        {
          title: "通道账户名称",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 200,
        },
        {
          title: "发送条数",
          ellipsis: true,
          dataIndex: "submitCount",
          width: 80,
        },
        {
          title: "成功条数",
          ellipsis: true,
          dataIndex: "successCount",
          width: 80,
        },
        {
          title: "失败条数",
          ellipsis: true,
          dataIndex: "failureCount",
          width: 80,
        },
        {
          title: "等待回执条数",
          ellipsis: true,
          dataIndex: "waitCallbackCount",
          width: 80,
        },
      ],
      urlReportTableData: [],
      total: 0,
      urlReportTablePageNo: 1,
      urlReportTablePageSize: 10,
      time: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      syncLoading: null,
      statisticsDataTime: [],
      statisticsDataShow: false,
    };
  },
  mounted() {
    this.getPlatList();
    this.getChannelSmsAccount();
    this.inquire(1);
  },
  methods: {
    disabledDate(current) {
      const currentDate = new Date();

      // 设置开始日期为45天前
      const startDate = new Date();
      startDate.setDate(currentDate.getDate() - 45);

      // 设置结束日期为明天
      const endDate = new Date();
      endDate.setDate(currentDate.getDate());

      // 检查当前日期是否在允许的范围内
      return current < startDate || current > endDate;
    },
    searchPlat(val) {
      this.getPlatList(val);
    },
    searchPlatId(val) {
      this.getChannelSmsAccount(val);
    },
    // 查询平台账号别表
    getChannelSmsAccount(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        accessKeyOrName,
      };
      this.channelSmsAccountList.splice(0);
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.channelSmsAccountList = list;
        }
      });
    },
    // 查询平台列表
    getPlatList(name) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        name: name,
      };
      this.platformList.splice(0);
      this.$http.get("/plat/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.platformList = list;
        }
      });
    },
    // 点击统计数据
    statisticsData() {
      this.statisticsDataTime = [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      this.statisticsDataShow = true;
    },
    statisticsDataSubmit() {
      if (!this.statisticsDataTime[0] || !this.statisticsDataTime[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      this.syncLoading = this.$syncLoading({
        message: "数据统计中...",
      });
      this.$http
        .json_post("/channelSendReport/syncData", {
          startDate: this.statisticsDataTime[0],
          endDate: this.statisticsDataTime[1],
        })
        .then((res) => {
          this.syncLoading.close();
          this.statisticsDataShow = false;
          if (res.result === 200) {
            this.inquire(1);
            this.$message.success("统计成功");
          }
        })
        .catch((err) => {
          this.syncLoading.close();
          this.statisticsDataShow = false;
        });
    },
    exportData() {
      if (!this.time[0] || !this.time[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        channelSmsAccountName: this.channelSmsAccountName,
        platformId: this.platformId,
        channelSmsAccountId: this.channelSmsAccountId,
        startDate: this.time[0],
        endDate: this.time[1],
      };

      let params = "";
      for (let i in data) {
        if (data[i]) {
          params += i + "=" + data[i] + "&";
        }
      }
      let url = this.$path + "/channelSendReport/channelSendExport?" + params;
      url = url.slice(0, -1);
      location.href = url;

      this.$message.success("导出成功");
    },
    // 查询分析合计
    getTotalCountInfo(data) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/channelSendReport/channelSendReportSummary", data)
          .then((res) => {
            if (res.result === 200) {
              resolve(res.data);
            }
          });
      });
    },
    // 点击查询
    async inquire(pageNo, pageSize) {
      if (pageNo) {
        this.urlReportTablePageNo = pageNo;
      }
      if (pageSize) {
        this.urlReportTablePageSize = pageSize;
      }
      if (!this.time[0] || !this.time[1]) {
        this.$message.warning("请选择日期");
        return;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        channelSmsAccountName: this.channelSmsAccountName,
        platformId: this.platformId,
        channelSmsAccountId: this.channelSmsAccountId,
        startDate: this.time[0],
        endDate: this.time[1],
      };
      let summary = await this.getTotalCountInfo(data);
      this.loading = true;
      this.$http
        .get("/channelSendReport/channelSendReportPage", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            let list = res.data.records;
            if (summary) {
              summary.id = -1;
              list.push(summary);
            }
            this.urlReportTableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 翻页
    changePage(pageNo, pageSize) {
      this.inquire(pageNo, pageSize);
    },
  },
};
</script>
