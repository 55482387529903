<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        平台名称:
        <a-input
          v-model="platformNameSearch"
          placeholder="请输入平台名称"
          allowClear
        />
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="addClick">新 增</a-button>
    </div>
    <!-- 平台列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="enabled" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 已启用 </a-tag>
        <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="platTitle"
      v-model="platShow"
      :maskClosable="false"
      :afterClose="platClose"
      class="actionSub-class"
      width="700px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="平台名称"
          required
        >
          <a-input v-model="platformName" placeholder="请输入平台名称" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="平台编码"
          required
        >
          <a-input v-model="code" placeholder="请输入平台编码" />
        </a-form-item>
        <!-- <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="url_1"
        >
          <a-input v-model="urlSingle" placeholder="单条发送请求地址"/>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="url_2"
        >
          <a-input
            v-model="urlBatchSame"
            placeholder="批量群发请求地址（内容相同）"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="url_3"
        >
          <a-input
            v-model="urlBatchDifferent"
            placeholder="批量群发请求地址（内容不同）"
          />
        </a-form-item> -->
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="单次提交量"
          v-if="this.platTitle === '编辑'"
        >
          <a-input
            v-model="batchSubmitMaxCount"
            placeholder="请输入单次提交量"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="可用状态"
          required
        >
          <!-- <a-input
            v-model="enabled"
            placeholder="请输入单次提交量"
          /> -->
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
        <!-- <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="黑名单状态"
        >
          <a-input
            v-model="blackListStatus"
            placeholder="请输入黑名单状态"
          />
        </a-form-item> -->
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="备注"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="platShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addPlat_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "platList",
  data() {
    return {
      // 表单数据
      platformName: "",
      code: "",
      urlSingle: "",
      urlBatchSame: "",
      urlBatchDifferent: "",
      batchSubmitMaxCount: "500",
      enabled: true,
      remark: "",
      platId: "",
      platTitle: "",
      platShow: false,
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "name",
          width: 120,
        },
        {
          title: "平台编码",
          ellipsis: true,
          dataIndex: "code",
          width: 120,
        },
        {
          title: "单次提交数量",
          ellipsis: true,
          dataIndex: "batchSubmitMaxCount",
          width: 100,
        },
        {
          title: "可用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      platformNameSearch: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        name: this.platformNameSearch.trim(),
      };
      this.getPlatList(data);
    },
    // 查询平台列表
    getPlatList(data) {
      this.loadingTable = true;
      this.$http
        .get("/plat/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.platShow = true;
      this.platTitle = "新增";
    },
    // 点击修改
    modifyClick(val) {
      this.platShow = true;
      this.platTitle = "编辑";
      this.platformName = val.name;
      this.code = val.code;
      this.urlSingle = val.urlSingle;
      this.urlBatchSame = val.urlBatchSame;
      this.urlBatchDifferent = val.urlBatchDifferent;
      this.batchSubmitMaxCount = val.batchSubmitMaxCount;
      this.enabled = val.enabled;
      this.remark = val.remark;
      // this.blackListStatus = val.blackListStatus;
      this.platId = val.id;
    },
    // 新增编辑关闭
    platClose() {
      this.platformName = "";
      this.code = "";
      this.urlSingle = "";
      this.urlBatchSame = "";
      this.urlBatchDifferent = "";
      this.batchSubmitMaxCount = "500";
      this.enabled = "";
      this.remark = "";
      // this.blackListStatus = "";
      this.platId = "";
    },
    // 新增编辑确定
    addPlat_submit() {
      let data = {
        name: this.platformName,
        code: this.code,
        urlSingle: this.urlSingle,
        urlBatchSame: this.urlBatchSame,
        urlBatchDifferent: this.urlBatchDifferent,
        batchSubmitMaxCount: this.batchSubmitMaxCount,
        enabled: this.enabled,
        remark: this.remark,
        // blackListStatus: this.blackListStatus,
      };
      if (this.platTitle === "新增") {
        this.$http.json_post("/plat/insert", data).then((res) => {
          if (res.result === 200) {
            this.platShow = false;
            this.inquire(1);
            this.$message.success("新增成功");
          }
        });
      } else if (this.platTitle === "编辑") {
        data.id = this.platId;
        this.$http.json_post("/plat/update", data).then((res) => {
          if (res.result === 200) {
            this.platShow = false;
            this.inquire(1);
            this.$message.success("编辑成功");
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.name + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/plat/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
