<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        客户编号:
        <a-input
          v-model="customerCodeSearch"
          placeholder="请输入客户编号"
          allowClear
        />
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="whiteAdd">新 增</a-button>
    </div>
    <!-- 短信白名单列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
      rowKey="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <div class="projectList-bottom-button">
      <a-button @click="deleteBatchClick" :disabled="disabledClick"
        >删除</a-button
      >
    </div>
    <!-- 新增 -->
    <a-modal
      :title="platTitle"
      v-model="platShow"
      :maskClosable="false"
      :afterClose="platClose"
      class="actionSub-class"
      width="700px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="客户编号"
        >
          <a-input v-model="customerCode" placeholder="请输入客户编号" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="备注"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="platShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addPlat_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "platList",
  data() {
    return {
      customerCodeSearch: "",
      selectedRowKeys: [],
      platShow: false,
      platTitle: "",
      customerCode: "",
      remark: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 150,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 300,
        },
        {
          title: "添加时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    disabledClick() {
      if (this.selectedRowKeys.length > 0) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        customerCode: this.customerCodeSearch,
      };
      this.getPlatList(data);
    },
    // 查询平台列表
    getPlatList(data) {
      this.loadingTable = true;
      this.$http
        .get("/whitelist/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 点击新增
    whiteAdd() {
      this.platTitle = "新增";
      this.customerCode = "";
      this.remark = "";
      this.platShow = true;
    },
    // 新增确定
    addPlat_submit() {
      let data = {
        customerCode: this.customerCode,
        remark: this.remark,
      };
      this.$http.json_post("/whitelist/addWhitelist", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("添加成功");
          this.inquire(1);
          this.platShow = false;
        }
      });
    },
    platClose() {},
    // 点击删除-批量
    deleteBatchClick(val) {
      this.$confirm({
        title: "确定要删除所选择的白名单吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            idList: this.selectedRowKeys,
          };
          this.$http
            .json_post("/whitelist/deleteWhitelist", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire(1);
              }
            });
        },
        onCancel() {},
      });
    },

    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确定要删除当前白名单吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/whitelist/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
