<template>
  <div class="apiPort">
    <a-tabs>
      <a-tab-pane key="1" tab="单条发送">
        <div class="parameterUrl">
          <div class="head1">
            <h2 class="head">请求地址</h2>
          </div>
          <div class="headers">
            <h3>http(s)://doamin/api/sms/send</h3>
          </div>
        </div>
        <div class="parameter">
          <div class="head1">
            <h2 class="head">请求参数</h2>
          </div>
          <div class="headers">
            <h3>Headers：</h3>
            <a-table
              :columns="headColumns"
              :dataSource="headTableData"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
          <div class="body">
            <h3>Body：</h3>
            <a-table
              :columns="bodyColumns"
              :dataSource="bodyTableData"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
        </div>
        <div class="returnData">
          <div class="head1">
            <h2 class="head">返回数据</h2>
          </div>
          <a-table
            :columns="returnColumns"
            :dataSource="returnTableData"
            :pagination="false"
            size="small"
            bordered
          >
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="批量发送（内容相同）">
        <div class="parameterUrl">
          <div class="head1">
            <h2 class="head">请求地址</h2>
          </div>
          <div class="headers">
            <h3>http(s)://doamin/api/sms/send</h3>
          </div>
        </div>
        <div class="parameter">
          <div class="head1">
            <h2 class="head">请求参数</h2>
          </div>
          <div class="headers">
            <h3>Headers：</h3>
            <a-table
              :columns="headColumns"
              :dataSource="headTableData"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
          <div class="body">
            <h3>Body：</h3>
            <a-table
              :columns="bodyColumns"
              :dataSource="bodyTableData1"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
        </div>
        <div class="returnData">
          <div class="head1">
            <h2 class="head">返回数据</h2>
          </div>
          <a-table
            :columns="returnColumns"
            :dataSource="returnTableData"
            :pagination="false"
            size="small"
            bordered
          >
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="批量发送（内容不同）">
        <div class="parameterUrl">
          <div class="head1">
            <h2 class="head">请求地址</h2>
          </div>
          <div class="headers">
            <h3>http(s)://doamin/api/sms/send</h3>
          </div>
        </div>
        <div class="parameter">
          <div class="head1">
            <h2 class="head">请求参数</h2>
          </div>
          <div class="headers">
            <h3>Headers：</h3>
            <a-table
              :columns="headColumns"
              :dataSource="headTableData"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
          <div class="body">
            <h3>Body：</h3>
            <a-table
              :columns="bodyColumns"
              :dataSource="bodyTableData2"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
        </div>
        <div class="returnData">
          <div class="head1">
            <h2 class="head">返回数据</h2>
          </div>
          <a-table
            :columns="returnColumns"
            :dataSource="returnTableData"
            :pagination="false"
            size="small"
            bordered
          >
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="发送状态查询">
        <div class="parameterUrl">
          <div class="head1">
            <h2 class="head">请求地址</h2>
          </div>
          <div class="headers">
            <h3>http(s)://doamin/api/sms/fetchSendStatus</h3>
          </div>
        </div>
        <div class="parameter">
          <div class="head1">
            <h2 class="head">请求参数</h2>
          </div>
          <div class="headers">
            <h3>Headers：</h3>
            <a-table
              :columns="headColumns"
              :dataSource="headTableData"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
          <div class="body">
            <h3>Body：</h3>
            <a-table
              :columns="bodyColumns"
              :dataSource="bodyTableData4"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
        </div>
        <div class="returnData">
          <div class="head1">
            <h2 class="head">返回数据</h2>
          </div>
          <a-table
            :columns="returnColumns"
            :dataSource="returnTableData4"
            :pagination="false"
            size="small"
            bordered
          >
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="查询回复内容">
        <div class="parameterUrl">
          <div class="head1">
            <h2 class="head">请求地址</h2>
          </div>
          <div class="headers">
            <h3>http(s)://doamin/api/sms/fetchReplyContent</h3>
          </div>
        </div>
        <div class="parameter">
          <div class="head1">
            <h2 class="head">请求参数</h2>
          </div>
          <div class="body">
            <h3>Query：</h3>
            <a-table
              :columns="bodyColumns5"
              :dataSource="bodyTableData5"
              :pagination="false"
              size="small"
              bordered
            >
            </a-table>
          </div>
        </div>
        <div class="returnData">
          <div class="head1">
            <h2 class="head">返回数据</h2>
          </div>
          <a-table
            :columns="returnColumns"
            :dataSource="returnTableData5"
            :pagination="false"
            size="small"
            bordered
          >
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "apiPort",
  data() {
    return {
      headColumns: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "参数值",
          ellipsis: true,
          dataIndex: "value",
          width: 300,
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "must",
          width: 100,
        },
        {
          title: "示例",
          ellipsis: true,
          dataIndex: "example",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
      ],
      headTableData: [
        {
          name: "Content-Type",
          value: "application/json",
          must: "是",
          example: "",
          remark: "",
        },
      ],
      bodyColumns: [
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          width: 100,
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "must",
          width: 100,
        },
        {
          title: "默认值",
          ellipsis: true,
          dataIndex: "default",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",

          width: 400,
        },
        {
          title: "其它信息",
          ellipsis: true,
          dataIndex: "info",
        },
      ],
      bodyTableData: [
        {
          key: 2,
          name: "accessKey",
          type: "string",
          must: "必须",
          default: "",
          remark: "通道accessKey",
          info: "",
        },
        {
          key: 3,
          name: "type",
          type: "string",
          must: "必须",
          default: "",
          remark: "SINGLE",
          info: "给固定值",
        },
        {
          key: 4,
          name: "phone",
          type: "string",
          must: "必须",
          default: "",
          remark: "手机号",
          info: "",
        },
        // {
        //   key: 5,
        //   name: "phoneList",
        //   type: "string []",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号集合（群发-相同内容)",
        //   info: "item 类型: string",
        //   children: [
        //     {
        //       key: 51,
        //       name: "",
        //       type: "",
        //       must: "非必须",
        //       default: "",
        //       remark: "",
        //       info: "",
        //     },
        //   ],
        // },
        {
          key: 6,
          name: "content",
          type: "string",
          must: "必须",
          default: "",
          remark: "短信内容",
          info: "",
        },
        // {
        //   key: 7,
        //   name: "batchDifferentList",
        //   type: "object []",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号内容对象集合（群发-不同内容）",
        //   info: "item 类型: object",
        //   children: [
        //     {
        //       key: 71,
        //       name: "phone",
        //       type: "string",
        //       must: "非必须",
        //       default: "",
        //       remark: "手机号",
        //       info: "",
        //     },
        //     {
        //       key: 72,
        //       name: "content",
        //       type: "string",
        //       must: "非必须",
        //       default: "",
        //       remark: "短信内容",
        //       info: "",
        //     },
        //   ],
        // },
        {
          key: 8,
          name: "timestamp",
          type: "number",
          must: "必须",
          default: "",
          remark: "时间戳",
          info: "",
        },
        {
          key: 9,
          name: "sign",
          type: "string",
          must: "必须",
          default: "",
          remark: "MD5(accessKey + accessSecret + timestamp)",
          info: "",
        },
        {
          key: 1,
          name: "requestId",
          type: "string",
          must: "非必须",
          default: "",
          remark: "请求id",
          info: "可不传, 传递后， 会根据requestId做接口排重(6小时内，相同的requestId会被过滤掉)",
        },
      ],
      bodyTableData1: [
        {
          key: 2,
          name: "accessKey",
          type: "string",
          must: "必须",
          default: "",
          remark: "通道accessKey",
          info: "",
        },
        {
          key: 3,
          name: "type",
          type: "string",
          must: "必须",
          default: "",
          remark: "BATCH_SAME",
          info: "给固定值",
        },
        // {
        //   key: 4,
        //   name: "phone",
        //   type: "string",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号",
        //   info: "",
        // },
        {
          key: 5,
          name: "phoneList",
          type: "string []",
          must: "必须",
          default: "",
          remark: "手机号集合",
          info: "item 类型: string",
          children: [
            {
              key: 51,
              name: "",
              type: "",
              must: "必须",
              default: "",
              remark: "",
              info: "",
            },
          ],
        },
        {
          key: 6,
          name: "content",
          type: "string",
          must: "必须",
          default: "",
          remark: "短信内容",
          info: "",
        },
        // {
        //   key: 7,
        //   name: "batchDifferentList",
        //   type: "object []",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号内容对象集合（群发-不同内容）",
        //   info: "item 类型: object",
        //   children: [
        //     {
        //       key: 71,
        //       name: "phone",
        //       type: "string",
        //       must: "非必须",
        //       default: "",
        //       remark: "手机号",
        //       info: "",
        //     },
        //     {
        //       key: 72,
        //       name: "content",
        //       type: "string",
        //       must: "非必须",
        //       default: "",
        //       remark: "短信内容",
        //       info: "",
        //     },
        //   ],
        // },
        {
          key: 8,
          name: "timestamp",
          type: "number",
          must: "必须",
          default: "",
          remark: "时间戳",
          info: "",
        },
        {
          key: 9,
          name: "sign",
          type: "string",
          must: "必须",
          default: "",
          remark: "MD5(accessKey + accessSecret + timestamp)",
          info: "",
        },
        {
          key: 1,
          name: "requestId",
          type: "string",
          must: "非必须",
          default: "",
          remark: "请求id",
          info: "可不传, 传递后， 会根据requestId做接口排重(6小时内，相同的requestId会被过滤掉)",
        },
      ],
      bodyTableData2: [
        {
          key: 2,
          name: "accessKey",
          type: "string",
          must: "必须",
          default: "",
          remark: "通道accessKey",
          info: "",
        },
        {
          key: 3,
          name: "type",
          type: "string",
          must: "必须",
          default: "",
          remark: "BATCH_DIFFERENT",
          info: "给固定值",
        },
        // {
        //   key: 4,
        //   name: "phone",
        //   type: "string",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号",
        //   info: "",
        // },
        // {
        //   key: 5,
        //   name: "phoneList",
        //   type: "string []",
        //   must: "非必须",
        //   default: "",
        //   remark: "手机号集合（群发-相同内容)",
        //   info: "item 类型: string",
        //   children: [
        //     {
        //       key: 51,
        //       name: "",
        //       type: "",
        //       must: "非必须",
        //       default: "",
        //       remark: "",
        //       info: "",
        //     },
        //   ],
        // },
        // {
        //   key: 6,
        //   name: "content",
        //   type: "string",
        //   must: "非必须",
        //   default: "",
        //   remark: "短信内容",
        //   info: "",
        // },
        {
          key: 7,
          name: "batchDifferentList",
          type: "object []",
          must: "必须",
          default: "",
          remark: "手机号内容对象集合",
          info: "item 类型: object",
          children: [
            {
              key: 71,
              name: "phone",
              type: "string",
              must: "必须",
              default: "",
              remark: "手机号",
              info: "",
            },
            {
              key: 72,
              name: "content",
              type: "string",
              must: "必须",
              default: "",
              remark: "短信内容",
              info: "",
            },
          ],
        },
        {
          key: 8,
          name: "timestamp",
          type: "number",
          must: "必须",
          default: "",
          remark: "时间戳",
          info: "",
        },
        {
          key: 9,
          name: "sign",
          type: "string",
          must: "必须",
          default: "",
          remark: "MD5(accessKey + accessSecret + timestamp)",
          info: "",
        },
        {
          key: 1,
          name: "requestId",
          type: "string",
          must: "非必须",
          default: "",
          remark: "请求id",
          info: "可不传, 传递后， 会根据requestId做接口排重(6小时内，相同的requestId会被过滤掉)",
        },
      ],
      returnColumns: [
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          width: 100,
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "must",
          width: 100,
        },
        {
          title: "默认值",
          ellipsis: true,
          dataIndex: "default",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "其它信息",
          ellipsis: true,
          dataIndex: "info",
        },
      ],
      returnTableData: [
        {
          name: "code",
          type: "string",
          must: "必须",
          default: "",
          remark: "状态码",
          info: "200-正常 400-参数异常 500-接口异常",
        },
        {
          name: "errorMessage",
          type: "string",
          must: "必须",
          default: "",
          remark: "错误信息",
          info: "",
        },
        {
          name: "msgId",
          type: "string",
          must: "必须",
          default: "",
          remark: "消息id",
          info: "",
        },
      ],
      //
      bodyTableData4: [
        {
          key: 1,
          name: "userName",
          type: "string",
          must: "必须",
          default: "",
          remark: "用户名",
          info: "",
        },
        {
          key: 2,
          name: "accessKey",
          type: "string",
          must: "必须",
          default: "",
          remark: "通道accessKey",
          info: "",
        },
        {
          key: 3,
          name: "batchNumber",
          type: "string",
          must: "必须",
          default: "",
          remark: "批次号",
          info: "",
        },
        {
          key: 5,
          name: "phoneList",
          type: "string []",
          must: "必须",
          default: "",
          remark: "手机号集合",
          info: "item 类型: string",
          children: [
            {
              key: 51,
              name: "",
              type: "",
              must: "非必须",
              default: "",
              remark: "",
              info: "",
            },
          ],
        },
        {
          key: 8,
          name: "timestamp",
          type: "number",
          must: "必须",
          default: "",
          remark: "时间戳",
          info: "",
        },
        {
          key: 9,
          name: "sign",
          type: "string",
          must: "必须",
          default: "",
          remark: "MD5(accessKey + accessSecret + timestamp)",
          info: "",
        },
        {
          key: 10,
          name: "msgId",
          type: "string",
          must: "非必须",
          default: "",
          remark: "消息id",
          info: "",
        },
      ],
      returnTableData4: [
        {
          key: 1,
          name: "code",
          type: "number",
          must: "必须",
          default: "",
          remark: "状态码",
          info: "200-正常 400-参数异常 500-接口异常",
        },
        {
          key: 2,
          name: "errorMessage",
          type: "string",
          must: "必须",
          default: "",
          remark: "错误信息",
          info: "",
        },
        {
          key: 3,
          name: "data",
          type: "object []",
          must: "必须",
          default: "",
          remark: "",
          info: "item 类型: object",
          children: [
            {
              key: 31,
              name: "phone",
              type: "string",
              must: "必须",
              default: "",
              remark: "手机号",
              info: "",
            },
            {
              key: 32,
              name: "status",
              type: "string",
              must: "必须",
              default: "",
              remark: "状态",
              info: "",
            },
          ],
        },
      ],
      bodyColumns5: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "must",
          width: 100,
        },
        {
          title: "示例",
          ellipsis: true,
          dataIndex: "default",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",

          width: 400,
        },
      ],
      bodyTableData5: [
        {
          key: 1,
          name: "userName",
          must: "是",
          default: "",
          remark: "用户名",
        },
        {
          key: 2,
          name: "accessKey",
          must: "是",
          default: "",
          remark: "accessKey",
        },
        {
          key: 3,
          name: "batchNumber",
          must: "是",
          default: "",
          remark: "批次号",
        },
        {
          key: 4,
          name: "timestamp",
          must: "是",
          default: "",
          remark: "毫秒级时间戳",
        },
        {
          key: 5,
          name: "sign",
          must: "是",
          default: "",
          remark: "小写MD5(accessKey+accessSecret + timestamp)",
        },
        {
          key: 6,
          name: "nextStartId",
          must: "是",
          default: "",
          remark: "分批获取数据，第一次传0，往后传上一次接口返回的nextStartId",
        },
        {
          key: 7,
          name: "pageSize",
          must: "是",
          default: "",
          remark: "获取的数据量",
        },
        {
          key: 8,
          name: "dateTimeStart",
          must: "否",
          default: "",
          remark: "时间范围过滤（开始时间）",
        },
        {
          key: 9,
          name: "dateTimeEnd",
          must: "否",
          default: "",
          remark: "时间范围过滤（结束时间）",
        },
      ],
      returnTableData5: [
        {
          key: 1,
          name: "code",
          type: "number",
          must: "必须",
          default: "",
          remark: "状态码",
          info: "",
        },
        {
          key: 2,
          name: "errorMessage",
          type: "string",
          must: "必须",
          default: "",
          remark: "错误信息",
          info: "",
        },
        {
          key: 4,
          name: "nextStartId",
          type: "number",
          must: "必须",
          default: "",
          remark: "数据起始位下标",
          info: "",
        },
        {
          key: 3,
          name: "list",
          type: "object []",
          must: "必须",
          default: "",
          remark: "",
          info: "item 类型: object",
          children: [
            {
              key: 31,
              name: "phone",
              type: "string",
              must: "必须",
              default: "",
              remark: "手机号",
              info: "",
            },
            {
              key: 32,
              name: "msgContent",
              type: "string",
              must: "必须",
              default: "",
              remark: "回复内容",
              info: "",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.apiPort {
  .card-container {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
  }
  .card-container {
    .ant-tabs-card {
      .ant-tabs-content {
        height: 120px;
        margin-top: -16px;
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          background: #fff;
          padding: 16px;
        }
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar {
        border-color: #fff;
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
      }
    }
  }
  // 请求地址
  .parameterUrl {
    .head1 {
      .head {
        border-left: 3px solid #2395f1;
        padding-left: 8px;
        font-weight: 400;
      }
    }
    .headers {
      padding: 16px;
      // height: 180px;
    }
  }
  // 请求参数
  .parameter {
    .head1 {
      margin-bottom: 10px;
      .head {
        border-left: 3px solid #2395f1;
        padding-left: 8px;
        font-weight: 400;
      }
    }
    .headers {
      padding: 16px;
      height: 180px;
    }
    .body {
      padding: 16px;
    }
  }
  // 返回数据
  .returnData {
    margin-top: 50px;
    .head1 {
      margin-bottom: 30px;
      .head {
        border-left: 3px solid #2395f1;
        padding-left: 8px;
        font-weight: 400;
      }
    }
  }
}
::deep() .ant-table-bordered .ant-table-body > table {
  border-bottom: 1px solid #e8e8e8;
}
</style>
