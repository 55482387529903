<template>
  <div class="shortReplace">
    <div class="shortReplace-search">
      <div class="shortReplace-search-1">
        被替换短链:
        <a-input v-model="shortUrl" placeholder="请输入被替换短链" allowClear />
      </div>
      <div class="shortReplace-search-1">
        长链:
        <a-input v-model="searchLongUrl" placeholder="请输入长链" allowClear />
      </div>
    </div>
    <div class="shortReplace-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="display: inline-block; margin-right: 20px"></i>
      <a-button type="primary" @click="addShort">新增</a-button>
    </div>
    <!-- 短链动态替换列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(codeColumns)"
      :columns="codeColumns"
      :dataSource="codeTableData"
      :pagination="codePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="fuzzyMatching" slot-scope="text">
        <span v-if="text">是</span>
        <span v-else>否</span>
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          title="动态替换将会被禁用，是否继续?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="switchOnChange(record)"
          v-if="text"
        >
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="text"
          />
        </a-popconfirm>
        <a-popconfirm
          title="短信内容将会被自动替换，是否继续？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="switchOnChange(record)"
          v-if="!text"
        >
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="text"
          />
        </a-popconfirm>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 添加短链接 -->
    <a-modal
      :title="shortModalTitle"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="被替换短链:"
          required
        >
          <a-input v-model.trim="shortUrlAdd" placeholder="请输入被替换短链" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="长链:"
          required
        >
          <a-input
            v-model.trim="longUrl"
            placeholder="请输入长链"
            @blur="compareToLongUrl(longUrl)"
          />
          <span v-if="urlFormat" class="dropbox-ts" style="color: red"
            >长链格式不正确</span
          >
          <span class="dropbox-ts">示例：http://www.xxxx.com</span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="选择域名:"
          required
        >
          <a-select v-model="selectDomain" placeholder="请选择域名">
            <a-select-option
              v-for="i in selectDomainList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="点击回调地址:"
        >
          <a-input
            v-model.trim="clickCallbackUrl"
            placeholder="请输入点击回调地址"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="备注:"
        >
          <a-input v-model.trim="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="模糊匹配:"
        >
          <a-checkbox v-model="fuzzyMatching"> </a-checkbox>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="shortLink_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "shortReplace",
  data() {
    return {
      codeColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "keyIndex",
          width: 60,
        },
        {
          title: "被替换短链",
          ellipsis: true,
          dataIndex: "shortUrl",
          width: 150,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          customRender: (text) => (
            <a-tooltip placement="topLeft" title={text}>
              {{ text }}
            </a-tooltip>
          ),
          width: 300,
        },
        {
          title: "点击回调地址",
          ellipsis: true,
          dataIndex: "clickCallbackUrl",
          width: 150,
        },
        {
          title: "域名",
          ellipsis: true,
          dataIndex: "domain",
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "模糊匹配",
          ellipsis: true,
          dataIndex: "fuzzyMatching",
          scopedSlots: { customRender: "fuzzyMatching" },
          width: 80,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 170,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      codeTableData: [],
      codePagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      selectDomainList: [],
      shortUrl: "",
      urlFormat: false,
      shortModalTitle: "",
      shortLinkShow: false,
      shortUrlAdd: "",
      remarkAdd: "",
      longUrl: "",
      searchLongUrl: "",
      selectDomain: undefined,
      clickCallbackUrl: "",
      updataId: "",
      fuzzyMatching: false,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        shortUrl: this.shortUrl,
        longUrl: this.searchLongUrl,
      };
      this.getCodeList(data);
    },
    // 查询短链列表
    getCodeList(data) {
      this.loadingTable = true;
      this.$http
        .get("/dynamicUrlReplaceConfig/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.keyIndex = index + 1;
            });
            this.codeTableData = list;
            this.codePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.codeChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    codeChangePage(index) {
      this.inquire(index);
    },
    // 查询短链域名
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 提示长链接格式是否正确
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormat = false;
          return;
        }
        this.urlFormat = true;
      }
    },
    addShort() {
      this.shortLinkShow = true;
      this.shortModalTitle = "新增";
      this.getDomainList();
      this.longUrl = "https://www.baidu.com/";
    },
    // 点击修改
    modifyClick(val) {
      this.shortLinkShow = true;
      this.shortModalTitle = "修改";
      this.getDomainList();
      this.longUrl = val.longUrl;
      this.shortUrlAdd = val.shortUrl;
      this.selectDomain = val.domain;
      this.clickCallbackUrl = val.clickCallbackUrl;
      this.remarkAdd = val.remark;
      this.fuzzyMatching = val.fuzzyMatching;
      this.updataId = val.id;
    },
    shortLinkClose() {
      this.longUrl = "";
      this.shortUrlAdd = "";
      this.selectDomain = undefined;
      this.clickCallbackUrl = "";
      this.remarkAdd = "";
      this.fuzzyMatching = false;
    },
    shortLink_submit() {
      let data = {
        shortUrl: this.shortUrlAdd,
        longUrl: this.longUrl,
        domain: this.selectDomain,
        clickCallbackUrl: this.clickCallbackUrl,
        remark: this.remarkAdd,
        fuzzyMatching: this.fuzzyMatching,
      };
      if (this.shortModalTitle === "新增") {
        this.$http
          .json_post("/dynamicUrlReplaceConfig/add ", data)
          .then((res) => {
            if (res.result === 200) {
              this.shortLinkShow = false;
              this.$message.success("新增成功！");
              this.inquire(1);
            }
          });
      } else if (this.shortModalTitle === "修改") {
        data.id = this.updataId;
        this.$http
          .json_post("/dynamicUrlReplaceConfig/update", data)
          .then((res) => {
            if (res.result === 200) {
              this.shortLinkShow = false;
              this.$message.success("修改成功！");
              this.inquire(1);
            }
          });
      }
    },
    switchOnChange(record) {
      let data = {
        id: record.id,
        enabled: !record.enabled,
      };
      this.$http
        .json_post("/dynamicUrlReplaceConfig/changeStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.inquire();
          }
        });
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除这条数据吗吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .get("/dynamicUrlReplaceConfig/delete", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shortReplace {
  .shortReplace-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .shortReplace-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .shortReplace-search-button {
    margin-bottom: 20px;
  }
}
</style>
