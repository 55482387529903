<template>
  <div class="signCorrelationSet">
    <div class="reminder">
      * 签名最近发送成功过滤,共享最近发送成功号码数据
      <br />
      * 添加后，还需要在签名列表对指定签名进行开启
    </div>
    <div class="management-search">
      <div class="management-search-1">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model="name"
          @keyup.enter="query(1)"
          allowClear
        />
      </div>
      <div class="management-search-1">
        签名：
        <a-input
          placeholder="请输入签名"
          v-model="sign"
          @keyup.enter="query(1)"
          allowClear
        />
      </div>
    </div>
    <div class="projectList-search-button">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          v-model="text"
          @change="enabledChange($event, record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:" required>
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="签名:" required>
          <a-select
            v-model="signAdd"
            placeholder="请选择签名"
            class="select-tag"
            mode="multiple"
            showSearch
            :filterOption="false"
            @search="getSignSelectList"
          >
            <a-select-option
              v-for="i in signSelectList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="启用状态:" v-if="this.title == '新增'">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="enabledAdd"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "signCorrelationSet",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      sign: "",
      signAdd: [],
      enabledAdd: true,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 150,
        },
        {
          title: "签名",
          ellipsis: true,
          dataIndex: "signs",
          width: 300,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      signSelectList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getSignSelectList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        sign: this.sign,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      this.$http
        .get("/signRecentSuccess/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getSignSelectList(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        fuzzyName,
      };
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        if (res.result == 200) {
          this.signSelectList = res.data.records;
        }
      });
    },
    enabledChange(enabled, record) {
      this.$http
        .json_post("/signRecentSuccess/enable", {
          id: record.id,
          enabled,
        })
        .then((res) => {
          if (res.result == 200) {
            if (enabled) {
              this.$message.success("启用成功");
            } else {
              this.$message.success("禁用成功");
            }
            this.query();
          }
        });
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.nameAdd = val.name;
      if (val.signs) {
        this.signAdd = val.signs.split(",");
      }
      this.enabledAdd = val.enabled;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.signAdd = [];
      this.enabledAdd = true;
    },
    // 确定新增
    add_submit() {
      if (!this.nameAdd || this.signAdd.length == 0) {
        this.$message.warning("请填写必填项");
        return;
      }
      this.loading = true;
      let data = {
        name: this.nameAdd,
        signs: this.signAdd.join(","),
        enabled: this.enabledAdd,
      };
      if (this.title === "新增") {
        this.$http.json_post("/signRecentSuccess/add", data).then((res) => {
          if (res.result === 200) {
            this.loading = false;
            this.$message.success("添加成功");
            this.addKeyVisible = false;
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        this.$http.json_post("/signRecentSuccess/edit", data).then((res) => {
          if (res.result === 200) {
            this.loading = false;
            this.$message.success("修改成功");
            this.addKeyVisible = false;
            this.query();
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/signRecentSuccess/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.signCorrelationSet {
  .management-search {
    position: relative;
    overflow: hidden;
    .management-search-1 {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
    }
    .backup {
      padding: 5px 0;
    }
    span {
      width: 240px;
    }
    input {
      width: 240px;
    }
    .ant-select {
      width: 240px;
    }
  }
  .projectList-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
    .request_nav_item {
      display: inline-block;
      margin-right: 20px;
      width: 350px;
      input {
        width: 250px;
      }
      .ant-select {
        width: 250px;
      }
    }
  }
}
</style>
